export const getProvider = () => {
    if ('phantom' in window) {
        const provider = window.phantom?.solana

        if (provider && provider?.isPhantom) {
            return provider
        }
    }

    // window.open('https://phantom.app/', '_blank')
}

export const getPhantomAddress = async () => {
    const provider = getProvider()
    try {
        const resp = await provider.connect()
        return {
            publicKey: resp || null,
            walletAddress: resp?.publicKey?.toString() || '',
        }
    } catch (err) {
        console.error(err)
    }
}
