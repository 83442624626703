import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useAccount } from 'wagmi'
import { useWallet } from '@solana/wallet-adapter-react'
import AllocationBg from '../../../../assets/blocks/allocations-block.svg'
import LoyaltyTabBg from '../../../../assets/blocks/loyalty-tab.svg'
import PerksTabBg from '../../../../assets/nft/perks-tab.svg'
import LoyaltyTabMobileBg from '../../../../assets/mobile/blocks/loyalty-tab-bg.svg'
import AllocationMobileBg from '../../../../assets/mobile/blocks/allocation-block-bg.svg'
import { useBuyContract } from '../../../../hooks/useBuyContract'
import { useToken } from '../../../../hooks/useToken'
import { USDT_ADDRESS, ZERO_ADDRESS } from '../../../../constants'
import { useNotificationsContext } from '../../../../context/Notificationscontext'
import { useRefetchStore } from '../../../../store/useRefetchStore'
import { toFixedAmount } from '../../../../utils/formatToFixes'
import { NETWORKS, useNetworkStore } from '../../../../store/usseNetworkStore'
import { useSolana } from '../../../../hooks/useSolana'
import { useSolanaUsdtBalanceStore } from '../../../../store/useSolanaUsdtBalanceStore'
import {
    SOLANA_TOKENS,
    useSolanaDropdownStore,
} from '../../../../store/useSolanaDropdownStore'
import { useSolanaStore } from '../../../../store/useSolanaStore'
import { solanaService } from '../../../../api/service'
import { InnerBlockComponent } from './InnerBlock'
import { AllocationTitleComponent } from './AllocationTitleComponent'
import { EnterCodeSection } from './EnterCodeSection'
import { BuyBlockComponent } from './BuyBlockComponent'
import { SolanaTicketsComponent } from './SolanaTicketsComponent'
import { RewardsInfoBNB } from './RewardsInfoBNB'

const Wrapper = styled.div`
    position: relative;
    padding: 0px 48px 0;
    margin-top: 84px;
    width: 100%;
    max-width: 738px;
    height: 615px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    backdrop-filter: blur(10px);

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled.div`
    display: none;
    position: relative;
    padding-inline: 20px;
    margin-top: 10px;
    width: 100%;
    max-width: 321px;
    height: 565px;
    background-image: ${(props) => `url(${props.imgUrl})`};

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const TabText = styled.p`
    position: absolute;
    top: 30px;
    left: 82px;
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
`

const TabTextMobile = styled(TabText)`
    top: 30px;
    left: 64px;
    font-size: 13px;
    line-height: 13px;
`

const TabContainer = styled.div`
    position: absolute;
    top: 0;
    left: 212px;
    width: 190px;
    height: 49px;
    overflow: hidden;
`

const PerksTabContainer = styled.div`
    position: absolute;
    top: 0;
    left: 340px;
    width: 190px;
    height: 49px;
    overflow: hidden;
`

const TabContainerMobile = styled(TabContainer)`
    left: 167px;
    width: 122px;
`
const LoyaltyTab = styled.div`
    margin-left: -53px;
    width: 190px;
    height: 49px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

const PerksTab = styled.div`
    margin-left: -53px;
    width: 274px;
    height: 49px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

const LoyaltyTabMobile = styled(LoyaltyTab)`
    margin-left: -56px;
    width: 192px;
`

const TabTextGray = styled(TabText)`
    top: 30px;
    left: 28px;
    color: ${({ theme }) => theme.textGray};
`
const TabTextGrayPerks = styled(TabTextGray)`
    left: 28px;
`

const TabTextGrayMobile = styled(TabTextMobile)`
    top: 30px;
    left: 21px;
    color: ${({ theme }) => theme.textGray};
`

export const AllocationComponent = ({
    openLoyalty,
    disabledLoyalty,
    ticketsInfo,
    openPerks,
}) => {
    const { showErrorNotification, showSuccessNotification } =
        useNotificationsContext()
    const {
        getBalance,
        buySolanaTickets,
        isTxLoading: isSolanaTxLoading,
        getUserTicketsSolana,
        buyTicketsWithSolana,
        getContractStateInfo,
    } = useSolana()
    const { refetch, refetchTrigger } = useRefetchStore()
    const { setSolanaUsdtBalance } = useSolanaUsdtBalanceStore()
    const {
        prices,
        totalTickets: solanaTotalTickets,
        totalTicketsThreshHold: solanaTotalTicketsThreshHold,
        maxTicketsPerUser: solanaMaxTicketsPerUser,
    } = useSolanaStore()
    const { network } = useNetworkStore()
    const { selectedToken } = useSolanaDropdownStore()
    const { account } = useWeb3React()
    const { address: phantomAddress } = useAccount()
    const { publicKey: adapterWalletAddress } = useWallet()
    const {
        buyTickets,
        isTxLoading,
        referralToReferrer,
        addressToRefCode,
        refCodeToAddress,
    } = useBuyContract()
    const [amount, setAmount] = useState('')
    const [refCode, setRefCode] = useState('')
    // const [isRefCodeValid, setIsRefCodeValid] = useState(false)
    const [isApplied, setIsApplied] = useState(false)
    const [usdcBalance, setUsdcBalance] = useState('0')
    const [shouldRefetch, setShouldRefetch] = useState(false)
    const [userTicketsSolana, setUserTicketsSolana] = useState(0)
    const [bnbRewards, setBNBRewards] = useState(null)

    const [hasReferral, setHasReferral] = useState(false)

    const { balance: USDTBalance } = useToken(USDT_ADDRESS, refetchTrigger)

    const isSolana = useMemo(() => network === NETWORKS.solana, [network])

    const solanaTicketsAmount = useMemo(() => {
        if (
            !prices?.solana ||
            !prices?.usdt ||
            !amount ||
            !parseFloat(amount)
        ) {
            return 0
        }
        const solanaPrice = prices.solana
        const usdtPrice = prices.usdt

        if (selectedToken === SOLANA_TOKENS.solana) {
            return parseFloat(amount) * solanaPrice
        } else if (selectedToken === SOLANA_TOKENS.usdt) {
            return parseFloat(amount) * usdtPrice
        }
        return 0
    }, [prices, amount, selectedToken])

    const refetchSolanaBalance = useCallback(() => {
        setShouldRefetch((prev) => !prev)
    }, [])

    const maximumTicketsValue = useMemo(() => {
        if (!ticketsInfo) return '-'
        if (!USDTBalance) return '-'
        const {
            userTicketsRaw,
            totalTicketsThresholdRaw,
            totalTicketsRaw,
            maxTicketsPerUserRaw,
        } = ticketsInfo
        const formattedBalance = BigNumber.from(
            ethers.utils.parseUnits(USDTBalance.toString(), 16),
        )

        const tresholdMinusTotalTickets =
            totalTicketsThresholdRaw.sub(totalTicketsRaw)
        const maxTicketsMinusUsersTickets = maxTicketsPerUserRaw.sub(
            userTicketsRaw?.totalRaw,
        )

        let result

        const isBalanceSmaller = formattedBalance.lt(tresholdMinusTotalTickets)
        result = isBalanceSmaller ? formattedBalance : tresholdMinusTotalTickets

        const isTicketsSmaller = maxTicketsMinusUsersTickets.lt(result)
        result = isTicketsSmaller ? maxTicketsMinusUsersTickets : result

        result = result.gte(ethers.utils.parseUnits('10000', 16))
            ? ethers.utils.parseUnits('10000', 16)
            : result

        return toFixedAmount(ethers.utils.formatUnits(result, 16), 2)
    }, [ticketsInfo, USDTBalance])

    const maximumTicketsValueSolana = useMemo(() => {
        if (!usdcBalance) return '-'
        if (!prices.solana) return '-'
        if (!solanaTotalTicketsThreshHold || !solanaMaxTicketsPerUser) {
            return '-'
        }

        // if (userTicketsSolana === 0) {
        //     if (selectedToken === SOLANA_TOKENS.usdt) {
        //         return solanaMaxTicketsPerUser
        //     } else if (selectedToken === SOLANA_TOKENS.solana) {
        //         return solanaMaxTicketsPerUser * prices.solana
        //     }
        // }

        const formattedBalance = parseFloat(usdcBalance)
        const tresholdMinusTotalTickets =
            solanaTotalTicketsThreshHold - solanaTotalTickets
        const maxTicketsMinusUsersTickets = solanaMaxTicketsPerUser

        if (maxTicketsMinusUsersTickets < 0) {
            return '-'
        }

        let result

        if (selectedToken === SOLANA_TOKENS.usdt) {
            const isBalanceSmaller =
                formattedBalance < tresholdMinusTotalTickets
            result = isBalanceSmaller
                ? formattedBalance
                : tresholdMinusTotalTickets

            const isTicketsSmaller = maxTicketsMinusUsersTickets < result
            result = isTicketsSmaller ? maxTicketsMinusUsersTickets : result

            // result = result > 10000 ? 10000 : result

            return result
        } else if (selectedToken === SOLANA_TOKENS.solana) {
            const isBalanceSmaller =
                formattedBalance < tresholdMinusTotalTickets * prices.solana

            result = isBalanceSmaller
                ? formattedBalance
                : tresholdMinusTotalTickets * prices.solana

            const isTicketsSmaller =
                maxTicketsMinusUsersTickets * prices.solana < result

            result = isTicketsSmaller ? maxTicketsMinusUsersTickets : result

            if (
                maxTicketsMinusUsersTickets * prices.solana >
                formattedBalance
            ) {
                return formattedBalance
            }

            // result = result > 10000 ? 10000 : result

            return result * prices.solana
        }
    }, [
        solanaTotalTicketsThreshHold,
        solanaMaxTicketsPerUser,
        solanaTotalTickets,
        usdcBalance,
        userTicketsSolana,
        selectedToken,
        prices,
    ])

    const handleMaxValue = useCallback(() => {
        if (isSolana) {
            setAmount(maximumTicketsValueSolana)
        } else {
            if (!maximumTicketsValue) return
            setAmount(String(maximumTicketsValue))
        }
    }, [maximumTicketsValue, isSolana, maximumTicketsValueSolana])

    const handleBonuceClick = (amount) => {
        setAmount(amount.toString())
    }

    const handlePurchase = useCallback(async () => {
        if (isTxLoading) {
            return
        }
        if (!amount) {
            showErrorNotification('Please enter amount')
            return
        }

        const exceededMaximum =
            maximumTicketsValue !== '-' &&
            parseFloat(amount) > maximumTicketsValue

        if (exceededMaximum) {
            showErrorNotification(`Maximum ${maximumTicketsValue} tickets`)
            return
        }

        if (parseFloat(amount) > USDTBalance) {
        }
        await buyTickets(amount, refCode, refetch, setAmount)
    }, [amount, refCode, USDTBalance, maximumTicketsValue])

    const handleSolanaPurchase = useCallback(async () => {
        if (isSolanaTxLoading) {
            return
        }

        if (selectedToken === SOLANA_TOKENS.solana) {
            if (!amount || !parseFloat(amount)) {
                showErrorNotification('Please enter amount')
                return
            }
        }
        if (selectedToken === SOLANA_TOKENS.usdt) {
            if (!amount || !parseFloat(amount)) {
                showErrorNotification('Please enter amount')
                return
            }
        }

        if (maximumTicketsValueSolana === '-') {
            showErrorNotification('Exceeded amount')
            return
        }
        //
        // const formattedTicketsAmount =
        //     solanaTicketsAmount / ALLOCATION_PER_ONE_TICKET

        // if (selectedToken === SOLANA_TOKENS.solana) {
        //     if (
        //         formattedTicketsAmount * prices.solana >
        //         maximumTicketsValueSolana
        //     ) {
        //         showErrorNotification(
        //             `Maximum ${maximumTicketsValueSolana} ${selectedToken}`,
        //         )
        //         return
        //     }
        // }
        //
        // if (selectedToken === SOLANA_TOKENS.usdt) {
        //     if (formattedTicketsAmount > maximumTicketsValueSolana) {
        //         showErrorNotification(
        //             `Maximum ${maximumTicketsValueSolana} ${selectedToken}`,
        //         )
        //         return
        //     }
        // }

        if (selectedToken === SOLANA_TOKENS.solana) {
            await buyTicketsWithSolana(amount, setAmount, refetchSolanaBalance)
        } else if (selectedToken === SOLANA_TOKENS.usdt) {
            await buySolanaTickets(amount, setAmount, refetchSolanaBalance)
        }
    }, [
        amount,
        maximumTicketsValueSolana,
        selectedToken,
        solanaTicketsAmount,
        prices,
    ])

    const getSolanaBalance = useCallback(
        async (walletAddress) => {
            const balances = await getBalance(walletAddress)
            if (balances?.solBalance) {
                if (selectedToken === SOLANA_TOKENS.solana) {
                    setUsdcBalance(balances?.solBalance?.toFixed(4))
                    setSolanaUsdtBalance(balances?.solBalance?.toFixed(4))
                } else if (selectedToken === SOLANA_TOKENS.usdt) {
                    setUsdcBalance(balances?.usdcBalance?.toFixed(2))
                    setSolanaUsdtBalance(balances?.usdcBalance?.toFixed(2))
                }
            }
        },
        [selectedToken],
    )

    const setReferralCode = (value) => {
        setIsApplied(false)
        setRefCode(value)
    }

    const checkReferral = async (address) => {
        const referralAddress = await referralToReferrer(address)
        if (referralAddress === ZERO_ADDRESS) {
            return
        } else {
            const myReferralCode = await addressToRefCode(referralAddress)
            if (myReferralCode && myReferralCode !== ZERO_ADDRESS) {
                setRefCode(myReferralCode)
                setHasReferral(true)
                setIsRefCodeValid(true)
                setIsApplied(true)
            }
        }
    }

    const checkIfRefCodeValid = useCallback(async () => {
        const address = await refCodeToAddress(refCode)
        if (address && address !== ZERO_ADDRESS) {
            setIsRefCodeValid(true)
            setIsApplied(true)
            showSuccessNotification('Referral code saved')
        } else {
            showErrorNotification('Wrong referral code')
        }
    }, [refCode])

    const navigateToLoyalty = useCallback(() => {
        if (!disabledLoyalty) {
            openLoyalty()
        }
    }, [disabledLoyalty])

    const fetchUserTicketsSolana = useCallback(async (walletAddress) => {
        setTimeout(async () => {
            const userTickets = await getUserTicketsSolana(walletAddress)
            if (userTickets) {
                setUserTicketsSolana(userTickets)
            }
        }, 1000)
    }, [])

    const fetchSolanaInfo = useCallback(async (walletAddress) => {
        setTimeout(async () => {
            await getContractStateInfo(walletAddress)
        }, 500)
    }, [])

    const fetchRewards = useCallback(async () => {
        if (network === NETWORKS.bnb) {
            if (account) {
                const data = await solanaService.getRewards(account, 'bnb')
                setBNBRewards(data?.investor)
            }
        }
    }, [phantomAddress, network, account])

    useEffect(() => {
        if (account) {
            checkReferral(account)
        }
    }, [account])

    useEffect(() => {
        if (!phantomAddress && !adapterWalletAddress) {
            return
        }
        fetchUserTicketsSolana(phantomAddress || adapterWalletAddress)
    }, [phantomAddress, shouldRefetch, adapterWalletAddress])

    useEffect(() => {
        if (!phantomAddress && !adapterWalletAddress) {
            return
        }
        getSolanaBalance(phantomAddress || adapterWalletAddress)
    }, [phantomAddress, shouldRefetch, selectedToken, adapterWalletAddress])

    useEffect(() => {
        if (!phantomAddress && !adapterWalletAddress) {
            return
        }
        fetchSolanaInfo(phantomAddress || adapterWalletAddress)
    }, [phantomAddress, selectedToken, adapterWalletAddress])

    useEffect(() => {
        fetchRewards()
    }, [fetchRewards])

    return (
        <>
            <Wrapper imgUrl={AllocationBg}>
                <TabContainer>
                    <LoyaltyTab
                        imgUrl={LoyaltyTabBg}
                        onClick={navigateToLoyalty}
                        style={{
                            opacity: disabledLoyalty || isSolana ? 0.4 : 1,
                            pointerEvents:
                                disabledLoyalty || isSolana ? 'none' : 'unset',
                        }}
                    >
                        <TabTextGray>
                            <span className="astro">Loyalty</span>
                        </TabTextGray>
                    </LoyaltyTab>
                </TabContainer>

                <PerksTabContainer issolana={String(isSolana)}>
                    <PerksTab imgUrl={PerksTabBg} onClick={openPerks}>
                        <TabTextGrayPerks>
                            <span className="astro">Laika Perks</span>
                        </TabTextGrayPerks>
                    </PerksTab>
                </PerksTabContainer>

                <TabText>
                    <span className="astro">Allocation</span>
                </TabText>
                <AllocationTitleComponent
                    isSolana={isSolana}
                    tickets={
                        isSolana
                            ? userTicketsSolana
                            : ticketsInfo?.userTickets?.total
                    }
                />
                <InnerBlockComponent
                    value={amount}
                    setValue={setAmount}
                    ticketsInfo={ticketsInfo}
                    refetch={refetchTrigger}
                    balance={USDTBalance}
                    maxValue={
                        isSolana
                            ? maximumTicketsValueSolana
                            : maximumTicketsValue
                    }
                    handleMaxValue={handleMaxValue}
                    handleBonuceClick={handleBonuceClick}
                    solanaBalance={usdcBalance}
                    isSolana={isSolana}
                    selectedToken={selectedToken}
                />
                {!isSolana && (
                    <EnterCodeSection
                        code={refCode}
                        setCode={setReferralCode}
                        isApplied={isApplied}
                        setIsApplied={setIsApplied}
                        onApply={checkIfRefCodeValid}
                        disabled={hasReferral}
                    />
                )}
                {isSolana && (
                    <SolanaTicketsComponent
                        value={solanaTicketsAmount.toFixed(2)}
                    />
                )}
                <BuyBlockComponent
                    onClick={isSolana ? handleSolanaPurchase : handlePurchase}
                    isTxLoading={isTxLoading || isSolanaTxLoading}
                    disabled={true}
                />
            </Wrapper>

            <WrapperMobile imgUrl={AllocationMobileBg}>
                <TabContainerMobile>
                    <LoyaltyTabMobile
                        imgUrl={LoyaltyTabMobileBg}
                        onClick={navigateToLoyalty}
                        style={{
                            opacity: disabledLoyalty || isSolana ? 0.4 : 1,
                            pointerEvents:
                                disabledLoyalty || isSolana ? 'none' : 'unset',
                        }}
                    >
                        <TabTextGrayMobile>
                            <span className="astro">Loyalty</span>
                        </TabTextGrayMobile>
                    </LoyaltyTabMobile>
                </TabContainerMobile>
                <TabTextMobile>
                    <span className="astro">Allocation</span>
                </TabTextMobile>
                <AllocationTitleComponent
                    isSolana={isSolana}
                    tickets={
                        isSolana
                            ? userTicketsSolana
                            : ticketsInfo?.userTickets?.total
                    }
                />
                {!isSolana && <RewardsInfoBNB rewardsInfo={bnbRewards} />}
                <InnerBlockComponent
                    value={amount}
                    setValue={setAmount}
                    ticketsInfo={ticketsInfo}
                    refetch={refetchTrigger}
                    balance={USDTBalance}
                    maxValue={
                        isSolana
                            ? maximumTicketsValueSolana
                            : maximumTicketsValue
                    }
                    handleMaxValue={handleMaxValue}
                    handleBonuceClick={handleBonuceClick}
                    solanaBalance={usdcBalance}
                    isSolana={isSolana}
                    selectedToken={selectedToken}
                />
                {!isSolana && (
                    <EnterCodeSection
                        code={refCode}
                        setCode={setReferralCode}
                        isApplied={isApplied}
                        setIsApplied={setIsApplied}
                        onApply={checkIfRefCodeValid}
                        disabled={hasReferral}
                    />
                )}
                {isSolana && (
                    <SolanaTicketsComponent
                        value={solanaTicketsAmount.toFixed(2)}
                    />
                )}
                <BuyBlockComponent
                    onClick={isSolana ? handleSolanaPurchase : handlePurchase}
                    isTxLoading={isTxLoading || isSolanaTxLoading}
                    disabled={true}
                />
            </WrapperMobile>
        </>
    )
}
