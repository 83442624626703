import React from 'react'
import styled from 'styled-components'
import RewardsBg from '../../../../assets/nft/reward-bg.svg'
import { FlexColumn, RowBetween } from '../../../../components/styled'

const Wrapper = styled(FlexColumn)`
    justify-content: space-between;
    width: 650px;
    height: 139px;
    margin-top: 24px;
    padding: 20px 32px 26px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const RewardsTitle = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const RewardsTextSmall = styled.p`
    color: ${({ theme }) => theme.textGray};
    font-weight: 400;
    font-size: 16px;
    line-height: 17.6px;
`

export const PerksRewards = ({ rewards }) => {
    return (
        <Wrapper imgUrl={RewardsBg}>
            <RowBetween>
                <RewardsTitle>
                    <span className="astro">Your Rewards</span>
                </RewardsTitle>
                <RewardsTextSmall>
                    <span className="mono">Get 1-20% $Laika Bonus</span>
                </RewardsTextSmall>
            </RowBetween>
            <RewardsTitle>
                <span className="astro">
                    {rewards.value} / {rewards.total} $Laika
                </span>
            </RewardsTitle>
        </Wrapper>
    )
}
