import { create } from 'zustand'

export const SOLANA_TOKENS = {
    solana: 'SOLANA',
    usdt: 'USDC',
}

export const useSolanaDropdownStore = create((set) => ({
    selectedToken: SOLANA_TOKENS.solana,
    setSelectedToken: (token) => {
        set({ selectedToken: token })
    },
}))
