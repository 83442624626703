import React, { useMemo } from 'react'
import styled from 'styled-components'
import { AutoColumn, Flex, RowBetween } from '../../../../components/styled'
import InfoBg from '../../../../assets/blocks/loyalty-bottom-info-block-bg.svg'
import InfoMobileBg from '../../../../assets/mobile/blocks/loyalty-info-item-bg.svg'
import { toFixedAmount } from '../../../../utils/formatToFixes'

const Wrapper = styled(RowBetween)`
    margin-top: 24px;
    margin-left: 8px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(AutoColumn)`
    display: none;
    margin-top: 54px;
    margin-left: 8px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: grid;
  `};
`

const InfoBlock = styled(Flex)`
    flex-direction: column;
    justify-content: space-between;
    width: 205px;
    height: 100px;
    padding: 16px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const InfoBlockMobile = styled(Flex)`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 270px;
    height: 77px;
    padding: 12px 16px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const InfoTextSmall = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
`
const InfoTextSmallMobile = styled(InfoTextSmall)`
    font-size: 13px;
    line-height: 16.25px;
`

const InfoTextBig = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
`
const InfoTextBigMobile = styled(InfoTextBig)`
    font-size: 16px;
    line-height: 16px;
`

export const LoyaltyBottomInfo = ({ rewards, ticketsInfo }) => {
    const converted = useMemo(() => {
        if (
            !ticketsInfo ||
            !ticketsInfo.userTickets ||
            !ticketsInfo.userTickets.total ||
            !ticketsInfo.userTickets.direct
        ) {
            return 0
        }
        const totalTickets = ticketsInfo.userTickets.total
        const directTickets = ticketsInfo.userTickets.direct
        return totalTickets - directTickets
    }, [ticketsInfo])

    const total = useMemo(() => {
        if (!Number(rewards.claimed || !Number(converted))) {
            return 0
        }
        return rewards.claimed + converted
    }, [converted, rewards])
    return (
        <>
            <Wrapper>
                <InfoComponent
                    title="Already claimed"
                    info={`${toFixedAmount(rewards?.claimed, 2)} USDT`}
                />
                <InfoComponent
                    title="Already converted"
                    info={`${toFixedAmount(converted, 2)} USDT`}
                />
                <InfoComponent
                    title="Total"
                    info={`${toFixedAmount(total, 2)} USDT`}
                />
            </Wrapper>
            <WrapperMobile gap="8px">
                <InfoComponent
                    title="Already claimed"
                    info={`${toFixedAmount(rewards?.claimed, 2)} USDT`}
                />
                <InfoComponent
                    title="Already converted"
                    info={`${toFixedAmount(converted, 2)} USDT`}
                />
                <InfoComponent
                    title="Total"
                    info={`${toFixedAmount(total, 2)} USDT`}
                />
            </WrapperMobile>
        </>
    )
}

const InfoComponent = ({ title, info }) => {
    return (
        <>
            <InfoBlock imgUrl={InfoBg}>
                <InfoTextSmall>
                    <span className="mono">{title}</span>
                </InfoTextSmall>
                <InfoTextBig>
                    <span className="astro">{info}</span>
                </InfoTextBig>
            </InfoBlock>
            <InfoBlockMobile imgUrl={InfoMobileBg}>
                <InfoTextSmallMobile>
                    <span className="mono">{title}</span>
                </InfoTextSmallMobile>
                <InfoTextBigMobile>
                    <span className="astro">{info}</span>
                </InfoTextBigMobile>
            </InfoBlockMobile>
        </>
    )
}
