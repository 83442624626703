import React from 'react'
import styled from 'styled-components'
import { AutoColumn, Flex } from '../../../components/styled'

const Wrapper = styled(Flex)`
    margin-top: 8px;
    gap: 40px;
    margin-left: 6px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(Flex)`
    display: none;
    margin-top: 8px;
    gap: 40px;
    margin-left: 6px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const InfoText = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: ${({ theme }) => theme.primary};
`

const InfoTextMobile = styled(InfoText)`
    font-size: 13px;
    line-height: 13px;
`

const InfoTextBold = styled(InfoText)`
    font-size: 20px;
    line-height: 20px;
`

export const InfoSection = () => {
    return (
        <>
            <Wrapper>
                {/* <AutoColumn gap="16px">*/}
                {/*    <InfoText>*/}
                {/*        <span className="mono">Amount to receive</span>*/}
                {/*    </InfoText>*/}
                {/*    <InfoTextBold>*/}
                {/*        <span className="astro">0</span>*/}
                {/*    </InfoTextBold>*/}
                {/* </AutoColumn>*/}
                <AutoColumn gap="16px">
                    <InfoText>
                        <span className="mono">Slippage</span>
                    </InfoText>
                    <InfoTextBold>
                        <span className="astro">0.5%</span>
                    </InfoTextBold>
                </AutoColumn>
            </Wrapper>
            <WrapperMobile>
                {/* <AutoColumn gap="20px">*/}
                {/*    <InfoTextMobile>*/}
                {/*        <span className="mono">Amount to receive</span>*/}
                {/*    </InfoTextMobile>*/}
                {/*    <InfoText>*/}
                {/*        <span className="astro">0</span>*/}
                {/*    </InfoText>*/}
                {/* </AutoColumn>*/}
                <AutoColumn gap="16px">
                    <InfoTextMobile>
                        <span className="mono">Slippage</span>
                    </InfoTextMobile>
                    <InfoText>
                        <span className="astro">0.5%</span>
                    </InfoText>
                </AutoColumn>
            </WrapperMobile>
        </>
    )
}
