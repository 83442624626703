import React from 'react'
import ReactDOM from 'react-dom/client'
import { Web3ReactProvider } from '@web3-react/core'
import AOS from 'aos'
import { WagmiConfig } from 'wagmi'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { App } from './App'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import { metaMask, metamaskHooks } from './connectors/metamask'
import { walletConnect, walletConnectHooks } from './connectors/walletConnect'
import './index.css'
import 'aos/dist/aos.css'
import { chains, wagmiConfig } from './connectors/rainbows'

window.Buffer = window.Buffer || require('buffer').Buffer

AOS.init()

const connectors = [
    [metaMask, metamaskHooks],
    [walletConnect, walletConnectHooks],
]

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <Web3ReactProvider connectors={connectors}>
            <WagmiConfig config={wagmiConfig}>
                <RainbowKitProvider chains={chains}>
                    <FixedGlobalStyle />
                    <ThemeProvider>
                        <ThemedGlobalStyle />
                        <div id="portal" />
                        <App />
                    </ThemeProvider>
                </RainbowKitProvider>
            </WagmiConfig>
        </Web3ReactProvider>
    </React.StrictMode>,
)
