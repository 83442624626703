import React from 'react'
import styled from 'styled-components'
import AllocationBg from '../../../../assets/blocks/allocations-block.svg'
import AllocationMobileBg from '../../../../assets/mobile/blocks/allocation-block-bg.svg'
import LoyaltyBg from '../../../../assets/blocks/loyalty-block.svg'
import LoyaltyMobileBg from '../../../../assets/mobile/blocks/loyalty-bg.svg'
import PerksTabBg from '../../../../assets/nft/perks-tab.svg'
import { LoyaltyTitleComponent } from './LoyaltyTitleComponent'
import { LoyaltyInputRow } from './LoyaltyInputRow'
import { LoyaltyInnerComponent } from './LoyaltyInnerBlock'
import { LoyaltyBottomInfo } from './LoyaltyBottomInfo'

const LoyaltyWrapper = styled.div`
    position: relative;
    padding: 0px 48px 0;
    margin-top: 84px;
    width: 100%;
    max-width: 738px;
    height: 606px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    background-size: cover;
    background-repeat: no-repeat;
    backdrop-filter: blur(10px);

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const LoyaltyWrapperMobile = styled.div`
    display: none;
    position: relative;
    margin-top: 16px;
    padding-inline: 20px;
    width: 100%;
    max-width: 320px;
    height: 699px;
    background-image: ${(props) => `url(${props.imgUrl})`};

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const TabText = styled.p`
    position: absolute;
    top: 30px;
    left: 240px;
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
`
const TabTextMobile = styled(TabText)`
    top: 30px;
    left: 188px;
    font-size: 13px;
    line-height: 13px;
`

const TabTextGray = styled(TabText)`
    top: 30px;
    left: 82px;
    color: ${({ theme }) => theme.textGray};
`

const TabTextGrayPerks = styled(TabTextGray)`
    left: 28px;
`

const TabTextGrayMobile = styled(TabTextMobile)`
    top: 30px;
    left: 64px;
    color: ${({ theme }) => theme.textGray};
`

const AllocationTab = styled.div`
    position: absolute;
    top: 0;
    left: 0px;
    width: 212px;
    height: 49px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

const PerksTabContainer = styled.div`
    position: absolute;
    top: 0;
    left: ${({ issolana }) => (issolana === 'true' ? '212px' : '346px')};
    width: 190px;
    height: 49px;
    overflow: hidden;
`

const PerksTab = styled.div`
    margin-left: -48px;
    width: 274px;
    height: 49px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

const AllocationTabMobile = styled.div`
    position: absolute;
    top: 0;
    left: 0px;
    width: 166px;
    height: 49px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

export const LoyaltyComponent = ({
    openAllocation,
    myRefCode,
    rewards,
    ticketsInfo,
    openPerks,
    disabledLoyalty,
}) => {
    return (
        <>
            <LoyaltyWrapper imgUrl={LoyaltyBg}>
                <AllocationTab imgUrl={AllocationBg} onClick={openAllocation}>
                    <TabTextGray>
                        <span className="astro">Allocation</span>
                    </TabTextGray>
                </AllocationTab>
                <TabText>
                    <span className="astro">Loyalty</span>
                </TabText>
                <PerksTabContainer issolana={String(false)}>
                    <PerksTab
                        imgUrl={PerksTabBg}
                        onClick={openPerks}
                        style={{
                            opacity: disabledLoyalty ? 0.6 : 1,
                            pointerEvents: disabledLoyalty ? 'none' : 'unset',
                        }}
                    >
                        <TabTextGrayPerks>
                            <span className="astro">Laika Perks</span>
                        </TabTextGrayPerks>
                    </PerksTab>
                </PerksTabContainer>
                <LoyaltyTitleComponent />
                <LoyaltyInputRow refCode={myRefCode} />
                <LoyaltyInnerComponent
                    rewards={rewards?.total - rewards?.claimed}
                />
                <LoyaltyBottomInfo
                    rewards={rewards}
                    ticketsInfo={ticketsInfo}
                />
            </LoyaltyWrapper>

            <LoyaltyWrapperMobile imgUrl={LoyaltyMobileBg}>
                <AllocationTabMobile
                    imgUrl={AllocationMobileBg}
                    onClick={openAllocation}
                >
                    <TabTextGrayMobile>
                        <span className="astro">Allocation</span>
                    </TabTextGrayMobile>
                </AllocationTabMobile>
                <TabTextMobile>
                    <span className="astro">Loyalty</span>
                </TabTextMobile>
                <LoyaltyTitleComponent />
                <LoyaltyInputRow refCode={myRefCode} />
                <LoyaltyInnerComponent
                    rewards={rewards?.total - rewards?.claimed}
                />
                <LoyaltyBottomInfo
                    rewards={rewards}
                    ticketsInfo={ticketsInfo}
                />
            </LoyaltyWrapperMobile>
        </>
    )
}
