import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Centered, Flex, FlexAlign } from '../styled'
import NetworkSwitchBg from '../../assets/blocks/network-switch-bg.svg'
import ActiveNetworkBg from '../../assets/blocks/active-network-bg.svg'
import BNBIcon from '../../assets/svg/bnb.svg'
import SolanaIcon from '../../assets/svg/sol.svg'
import { NETWORKS, useNetworkStore } from '../../store/usseNetworkStore'
import { getProvider } from '../../utils/phantom'
import { useNotificationsContext } from '../../context/Notificationscontext'

const Wrapper = styled(Flex)`
    width: 305px;
    height: 83px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    z-index: 2;
    padding-inline: 12px;
`

const NetworkButton = styled(Centered)`
    width: 152px;
    height: 70px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

const NetworkContent = styled(FlexAlign)`
    margin-top: -6px;
    gap: 8px;
    width: fit-content;
    height: fit-content;
`

const NetworkText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const NetworkIcon = styled.img`
    width: 22px;
    height: 22px;
`

export const NetworkSwitch = () => {
    const { network, setNetwork } = useNetworkStore()
    const phantom = getProvider()
    const { showErrorNotification } = useNotificationsContext()

    const setSolanaNetwork = useCallback(() => {
        if (!phantom) {
            showErrorNotification('Please install phantom')
            window.open('https://phantom.app/', '_blank')
            return
        }
        setNetwork(NETWORKS.solana)
    }, [phantom])

    return (
        <Wrapper imgUrl={NetworkSwitchBg}>
            <NetworkButton
                imgUrl={network === NETWORKS.bnb ? ActiveNetworkBg : ''}
                onClick={() => setNetwork(NETWORKS.bnb)}
            >
                <NetworkContent>
                    <NetworkIcon src={BNBIcon} />
                    <NetworkText>
                        <span className="rubic">BNB</span>
                    </NetworkText>
                </NetworkContent>
            </NetworkButton>

            <NetworkButton
                imgUrl={network === NETWORKS.solana ? ActiveNetworkBg : ''}
                onClick={setSolanaNetwork}
                style={{ marginLeft: '-24px' }}
            >
                <NetworkContent>
                    <NetworkIcon src={SolanaIcon} />
                    <NetworkText>
                        <span className="rubic">Solana</span>
                    </NetworkText>
                </NetworkContent>
            </NetworkButton>
        </Wrapper>
    )
}
