import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AutoColumn, RowBetween } from '../../../../components/styled'
import { getNFTImage } from '../../../../utils/getNFTImage'
import { perksNFTsList } from '../../../../constants/perks'

const Wrapper = styled(RowBetween)`
    margin-top: 64px;
    padding-left: 8px;
`

const TextSection = styled(AutoColumn)`
    max-width: 396px;
`

const PerksTitle = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
`

const PerksTextSmall = styled.p`
    color: ${({ theme }) => theme.primary};
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
`

const NFTImage = styled.div`
    width: 190px;
    height: 190px;

    img {
        width: 100%;
        height: 100%;
    }
`

const NFTUncommonImage = styled(NFTImage)`
    img {
        filter: blur(8px);
    }
`

export const PerksTitleComponent = ({ status }) => {
    const timer = useRef()
    const [index, setIndex] = useState(0)
    const statusImage = getNFTImage(status)

    const changeIndex = useCallback(() => {
        const length = perksNFTsList.length - 1
        setIndex((prev) => {
            if (prev + 1 <= length) {
                return prev + 1
            } else {
                return 0
            }
        })
    }, [])

    useEffect(() => {
        if (statusImage) {
            return
        }
        timer.current = setInterval(() => {
            changeIndex()
        }, 1000)

        return () => clearInterval(timer.current)
    }, [statusImage])

    return (
        <Wrapper>
            <TextSection gap="16px">
                <PerksTitle>
                    <span className="astro">Laika Perks</span>
                </PerksTitle>
                <AutoColumn gap="16px">
                    <PerksTextSmall>
                        <span className="mono">
                            Rewards - we award a 1-20% bonus $Laika on the
                            purchase amount, which is credited evenly over 14
                            days.
                        </span>
                    </PerksTextSmall>
                    <PerksTextSmall>
                        <span className="mono">
                            Tickets - for every $10 you get 1 ticket to join the
                            lucky draw and chance to win 3 iPhones and 2
                            MacBooks!
                        </span>
                    </PerksTextSmall>
                </AutoColumn>
            </TextSection>
            {!statusImage ? (
                <NFTUncommonImage>
                    <img src={perksNFTsList[index]} alt="" />
                </NFTUncommonImage>
            ) : (
                <NFTImage>
                    <img src={statusImage} alt="" />
                </NFTImage>
            )}
        </Wrapper>
    )
}
