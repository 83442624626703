import React from 'react'
import styled from 'styled-components'
import DexErrorBg from '../../../assets/dex/dex-blocks/dex-error.svg'
import DexErrorMobileBg from '../../../assets/mobile/dex/dex-error-bg.svg'
import ErrorIcon from '../../../assets/notifications/error-icon.svg'
import { FlexAlign, Icon } from '../../../components/styled'

const Wrapper = styled.div`
    background-image: ${(props) => `url(${props.imgUrl})`};
    width: 120%;
    max-width: 662px;
    height: 123px;
    margin-left: -14px;
    padding: 24px 36px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled.div`
    display: none;
    background-image: ${(props) => `url(${props.imgUrl})`};
    width: 120%;
    max-width: 298px;
    height: 114px;
    margin-left: -14px;
    padding: 24px 36px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const ErrorTitle = styled.p`
    color: ${({ theme }) => theme.error};
    font-size: 16px;
    font-weight: 400;
    line-height: 22.24px;
`

const ErrorTitleMobile = styled(ErrorTitle)`
    font-size: 12px;
    line-height: 12px;
`

const ErrorText = styled.p`
    color: ${({ theme }) => theme.textGray};
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    margin-left: 36px;
`

const ErrorTextMobile = styled(ErrorText)`
    margin-top: 6px;
    font-size: 13px;
    line-height: 16.9px;
    margin-left: 26px;
`

const TitleWrapper = styled(FlexAlign)`
    gap: 8px;
`

export const ErrorSection = () => {
    return (
        <>
            <Wrapper imgUrl={DexErrorBg}>
                <TitleWrapper>
                    <Icon
                        width="26px"
                        height="26px"
                        style={{ cursor: 'default' }}
                    >
                        <img src={ErrorIcon} alt="" />
                    </Icon>
                    <ErrorTitle>
                        <span className="astro">Something went wrong</span>
                    </ErrorTitle>
                </TitleWrapper>
                <ErrorText>
                    <span className="mono">
                        Additional text to help the user.
                    </span>
                </ErrorText>
            </Wrapper>

            <WrapperMobile imgUrl={DexErrorMobileBg}>
                <TitleWrapper>
                    <Icon
                        width="18px"
                        height="18px"
                        style={{ cursor: 'default' }}
                    >
                        <img src={ErrorIcon} alt="" />
                    </Icon>
                    <ErrorTitleMobile>
                        <span className="astro">Something went wrong</span>
                    </ErrorTitleMobile>
                </TitleWrapper>
                <ErrorTextMobile>
                    <span className="mono">
                        Additional text to help the user.
                    </span>
                </ErrorTextMobile>
            </WrapperMobile>
        </>
    )
}
