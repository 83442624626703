import React from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import InputBg from '../../../../assets/blocks/loyalty-input-block.svg'
import InputMobileBg from '../../../../assets/mobile/blocks/loyalty-ref-bg.svg'
import CopyImg from '../../../../assets/svg/copy.svg'
import { FlexAlign, RowBetween } from '../../../../components/styled'
import { useNotificationsContext } from '../../../../context/Notificationscontext'

const InputBlock = styled(RowBetween)`
    margin-left: 8px;
    position: relative;
    margin-top: 16px;
    width: 100%;
    max-width: 634px;
    height: 56px;
    padding-inline: 22px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const InputBlockMobile = styled(RowBetween)`
    display: none;
    margin-left: 8px;
    position: relative;
    margin-top: 12px;
    width: 100%;
    max-width: 269px;
    height: 49px;
    padding-inline: 22px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const CopyBlock = styled(FlexAlign)`
    width: fit-content;
    cursor: pointer;
`

const CopyIcon = styled.div`
    margin-right: 8px;
    width: 18px;
    height: 18px;
    img {
        width: 100%;
        height: 100%;
    }
`

const CopyText = styled.p`
    color: ${({ theme }) => theme.textGold};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
`

const CopyTextMobile = styled(CopyText)`
    font-size: 13px;
    line-height: 13px;
`

const RefTextMobile = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
`

export const LoyaltyInputRow = ({ refCode }) => {
    const { showSuccessNotification } = useNotificationsContext()
    const onCopy = () => {
        copy(refCode)
        showSuccessNotification('Copied')
    }
    return (
        <>
            <InputBlock imgUrl={InputBg}>
                <input
                    className="enter-bonus-input loyalty-input"
                    type="text"
                    placeholder={
                        refCode ? refCode : 'NEED $LAIKA TO GET THE CODE'
                    }
                    disabled
                />
                <CopyBlock onClick={refCode ? onCopy : () => {}}>
                    <CopyIcon>
                        <img src={CopyImg} alt="" />
                    </CopyIcon>
                    <CopyText>
                        <span className="astro">Copy</span>
                    </CopyText>
                </CopyBlock>
            </InputBlock>
            <InputBlockMobile imgUrl={InputMobileBg}>
                <RefTextMobile>
                    <span className="mono">
                        {refCode ? refCode : 'NEED $LAIKA TO GET THE CODE'}
                    </span>
                </RefTextMobile>
                <CopyBlock onClick={refCode ? onCopy : () => {}}>
                    <CopyIcon>
                        <img src={CopyImg} alt="" />
                    </CopyIcon>
                    <CopyTextMobile>
                        <span className="astro">Copy</span>
                    </CopyTextMobile>
                </CopyBlock>
            </InputBlockMobile>
        </>
    )
}
