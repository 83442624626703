import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import {
    AutoColumn,
    Centered,
    Flex,
    RowBetween,
} from '../../../../components/styled'
import LoyaltyInnerBg from '../../../../assets/blocks/loyalty-inner-block.svg'
import LoyaltyClaimBg from '../../../../assets/blocks/loyalty-claim-button.svg'
import LoyaltyConvertBg from '../../../../assets/blocks/loyalty-convert-button.svg'
import { inputRegexp } from '../../../../constants/regExp'
import { escapeRegExp } from '../../../../utils/escapeRegExp'
import InnerBlockMobileBg from '../../../../assets/mobile/blocks/inner-block.svg'
import ClaimBgMobile from '../../../../assets/mobile/blocks/loyalty-claim-bg.svg'
import ConvertBgMobile from '../../../../assets/mobile/blocks/loyalty-convert-bg.svg'
import { useBuyContract } from '../../../../hooks/useBuyContract'
import { useNotificationsContext } from '../../../../context/Notificationscontext'
import { useRefetchStore } from '../../../../store/useRefetchStore'
import { toFixedAmount } from '../../../../utils/formatToFixes'

const BonusesText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const BonusesTextMobile = styled(BonusesText)`
    font-size: 13px;
    line-height: 14.3px;
`

const AvailableTextMobile = styled(BonusesTextMobile)`
    color: ${({ theme }) => theme.textGray};
    text-align: right;
`

const AllocationsTextSmall = styled.p`
    color: ${({ theme }) => theme.secondary};
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
`

const AvailableToClaimText = styled(AllocationsTextSmall)`
    color: ${({ theme }) => theme.textGray};
    line-height: 17.6px;
    cursor: pointer;
`

const Wrapper = styled.div`
    position: relative;
    margin-top: 16px;
    padding: 16px 30px 0;
    width: 100%;
    max-width: 642px;
    height: 154px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const InnerBlockWrapper = styled(AutoColumn)`
    display: none;
    margin-top: 12px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: grid;
  `};
`
const InnerBlockMobile = styled.div`
    position: relative;
    width: 100%;
    max-width: 285px;
    height: 114px;
    padding-inline: 20px;
    padding-top: 14px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const InputContainer = styled.div`
    margin-top: 39px;
    width: 100%;
    max-width: 232px;
`

const InputContainerMobile = styled.div`
    margin-top: 27px;
    width: 100%;
    max-width: 200px;
`

const MinBlock = styled(Centered)`
    position: absolute;
    top: 82px;
    right: 15px;
    width: 205px;
    height: 68px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    color: ${({ theme }) => theme.secondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 15.68px;
    cursor: pointer;
`

const MaxBlock = styled(MinBlock)`
    right: 211px;
    width: 149px;
    cursor: pointer;
`

const StyledSpan = styled.span`
    color: ${({ theme }) => theme.textGold};
`

const RowBetweenMobile = styled(Flex)`
    position: relative;
    margin-top: -8px;
`

const MinBlockMobile = styled(Centered)`
    position: absolute;
    right: -4px;
    width: 175px;
    height: 64px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    color: ${({ theme }) => theme.secondary};
    font-weight: 400;
    font-size: 13px;
    line-height: 14.56px;
    cursor: pointer;
`

const MaxBlockMobile = styled(MinBlockMobile)`
    left: -8px;
    width: 136px;
    cursor: auto;
`

export const LoyaltyInnerComponent = ({ rewards }) => {
    const { refetch } = useRefetchStore()
    const { claimReward } = useBuyContract()
    const { showErrorNotification } = useNotificationsContext()
    const [claim, setClaim] = useState('')
    const enforcer = (newValue) => {
        if (newValue === '' || inputRegexp.test(escapeRegExp(newValue))) {
            setClaim(newValue)
        }
    }

    const validateClaim = useCallback(() => {
        if (!claim) {
            showErrorNotification('Please enter amount')
            return false
        }
        if (parseFloat(claim) > rewards || !parseFloat(claim)) {
            showErrorNotification('Enter correct amount')
            return false
        }
        return true
    }, [rewards, claim])

    const handleClaim = useCallback(async () => {
        const isValid = validateClaim()
        if (!isValid) {
            return
        }
        await claimReward(claim, false, refetch, setClaim)
    }, [rewards, claim])

    const handleConvert = useCallback(async () => {
        const isValid = validateClaim()
        if (!isValid) {
            return
        }
        await claimReward(claim, true, refetch, setClaim)
    }, [rewards, claim])

    const handleMax = useCallback(() => {
        if (parseInt(rewards) || parseInt(rewards) === 0) {
            setClaim(rewards)
        }
    }, [rewards])

    return (
        <>
            <Wrapper imgUrl={LoyaltyInnerBg}>
                <RowBetween>
                    <BonusesText>
                        <span className="astro">Your bonuses</span>
                    </BonusesText>
                    <AvailableToClaimText onClick={handleMax}>
                        <span className="mono">
                            Available to claim: {toFixedAmount(rewards, 2)} usdt
                        </span>
                    </AvailableToClaimText>
                </RowBetween>
                <InputContainer>
                    <input
                        className="input"
                        type="text"
                        pattern="^[0-9]*[.,]?[0-9]*$"
                        value={claim}
                        onChange={(event) =>
                            enforcer(event.target.value.replace(/,/g, '.'))
                        }
                        placeholder="enter amount"
                    />
                </InputContainer>
                <MaxBlock imgUrl={LoyaltyClaimBg} onClick={handleClaim}>
                    <span className="mono" style={{ marginLeft: '12px' }}>
                        Claim USDT
                    </span>
                </MaxBlock>
                <MinBlock imgUrl={LoyaltyConvertBg} onClick={handleConvert}>
                    <StyledSpan className="mono">CONVERT TO LAIKA</StyledSpan>
                </MinBlock>
            </Wrapper>

            <InnerBlockWrapper>
                <InnerBlockMobile imgUrl={InnerBlockMobileBg}>
                    <RowBetween style={{ alignItems: 'flex-start' }}>
                        <BonusesTextMobile>
                            <span className="astro">Your bonuses</span>
                        </BonusesTextMobile>
                        <AutoColumn
                            gap="4px"
                            onClick={handleMax}
                            style={{ cursor: 'pointer' }}
                        >
                            <AvailableTextMobile>
                                <span className="mono">Available:</span>
                            </AvailableTextMobile>
                            <AvailableTextMobile>
                                <span className="mono">
                                    {toFixedAmount(rewards, 2)} usdt
                                </span>
                            </AvailableTextMobile>
                        </AutoColumn>
                    </RowBetween>
                    <InputContainerMobile>
                        <input
                            className="input input-mobile"
                            type="text"
                            pattern="^[0-9]*[.,]?[0-9]*$"
                            value={claim}
                            onChange={(event) =>
                                enforcer(event.target.value.replace(/,/g, '.'))
                            }
                            placeholder="enter amount"
                        />
                    </InputContainerMobile>
                </InnerBlockMobile>
                <RowBetweenMobile>
                    <MaxBlockMobile
                        imgUrl={ClaimBgMobile}
                        onClick={handleClaim}
                    >
                        <span className="mono">Claim USDT</span>
                    </MaxBlockMobile>
                    <MinBlockMobile
                        imgUrl={ConvertBgMobile}
                        onClick={handleConvert}
                    >
                        <StyledSpan className="mono">
                            CONVERT TO LAIKA
                        </StyledSpan>
                    </MinBlockMobile>
                </RowBetweenMobile>
            </InnerBlockWrapper>
        </>
    )
}
