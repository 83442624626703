export const toFixedAmount = (value, precision) => {
    if (!value) {
        return '0'
    }
    value = Number(value)
    const fixed = value.toFixed(precision + 2)
    const length = fixed.length
    const splittedByDot = fixed.substring(0, length - 2).split('.')

    if (+splittedByDot[1] === 0) {
        return splittedByDot[0]
    }
    return removeZeroFromEnd(fixed.substring(0, length - 2))
}

const removeZeroFromEnd = (value) => {
    if (!value.endsWith('0')) return value

    const res = value.slice(0, -1)
    return removeZeroFromEnd(res)
}
