import React from 'react'
import styled, { keyframes } from 'styled-components'
import ErrorBg from '../assets/notifications/error-bg.svg'
import ErrorBgMobile from '../assets/notifications/mobile-error-bg.svg'
import PendingBg from '../assets/notifications/pending-bg.svg'
import PendingMobileBg from '../assets/notifications/mobile-pending-bg.svg'
import SuccessBg from '../assets/notifications/success-bg.svg'
import SuccessMobileBg from '../assets/notifications/mobile-success-bg.svg'
import ErrorIcon from '../assets/notifications/error-icon.svg'
import PendingIcon from '../assets/notifications/pending-icon.svg'
import SuccessIcon from '../assets/notifications/success-icon.svg'
import { Centered } from './styled'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const NotificationWrapper = styled(Centered)`
    position: fixed;
    right: 10px;
    top: 15px;
    width: 304px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    z-index: 100;
    height: 79px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const NotificationWrapperMobile = styled(Centered)`
    display: none;
    position: absolute;
    right: 40px;
    top: 0px;
    width: 254px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    z-index: 100;
    height: 50px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const ErrorWrapper = styled(NotificationWrapper)`
    width: 325px;
`

const ErrorWrapperMobile = styled(NotificationWrapperMobile)`
    width: 270px;
`
const IconWrapper = styled.div`
    width: 26px;
    height: 26px;
    img {
        height: 100%;
        width: 100%;
    }
`

const IconWrapperMobile = styled(IconWrapper)`
    width: 18px;
    height: 18px;
`

const PendingIconWrapper = styled.div`
    width: 26px;
    height: 26px;
    animation: 2s ${rotate} linear infinite;
    img {
        height: 100%;
        width: 100%;
    }
`

const PendingIconWrapperMobile = styled(PendingIconWrapper)`
    width: 18px;
    height: 18px;
`

const NotificationTextText = styled.p`
    margin-left: 8px;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.24px;
    cursor: default;
`

const NotificationTextTextMobile = styled(NotificationTextText)`
    margin-top: 4px;
    font-size: 12px;
    line-height: 12px;
`

const ErrorText = styled(NotificationTextText)`
    color: ${({ theme }) => theme.error};
`

const ErrorTextMobile = styled(NotificationTextTextMobile)`
    color: ${({ theme }) => theme.error};
`

const PendingText = styled(NotificationTextText)`
    color: ${({ theme }) => theme.pending};
`

const PendingTextMobile = styled(NotificationTextTextMobile)`
    color: ${({ theme }) => theme.pending};
`

const SuccessText = styled(NotificationTextText)`
    color: ${({ theme }) => theme.success};
`

const SuccessTextMobile = styled(NotificationTextTextMobile)`
    color: ${({ theme }) => theme.success};
`

export const ErrorNotification = ({ text }) => {
    return (
        <>
            <ErrorWrapper
                imgUrl={ErrorBg}
                data-aos-duration="500"
                data-aos="fade-left"
            >
                <IconWrapper>
                    <img src={ErrorIcon} alt="" />
                </IconWrapper>
                <ErrorText>
                    <span className="astro">
                        {text || 'Something went wrong'}
                    </span>
                </ErrorText>
            </ErrorWrapper>

            <ErrorWrapperMobile
                imgUrl={ErrorBgMobile}
                data-aos-duration="500"
                data-aos="fade-left"
            >
                <IconWrapperMobile>
                    <img src={ErrorIcon} alt="" />
                </IconWrapperMobile>
                <ErrorTextMobile>
                    <span className="astro">
                        {text || 'Something went wrong'}
                    </span>
                </ErrorTextMobile>
            </ErrorWrapperMobile>
        </>
    )
}

export const PendingNotification = () => {
    return (
        <>
            <NotificationWrapper
                imgUrl={PendingBg}
                data-aos-duration="500"
                data-aos="fade-left"
            >
                <PendingIconWrapper>
                    <img src={PendingIcon} alt="" />
                </PendingIconWrapper>
                <PendingText>
                    <span className="astro">Transaction pending</span>
                </PendingText>
            </NotificationWrapper>

            <NotificationWrapperMobile
                imgUrl={PendingMobileBg}
                data-aos-duration="500"
                data-aos="fade-left"
            >
                <PendingIconWrapperMobile>
                    <img src={PendingIcon} alt="" />
                </PendingIconWrapperMobile>
                <PendingTextMobile>
                    <span className="astro">Transaction pending</span>
                </PendingTextMobile>
            </NotificationWrapperMobile>
        </>
    )
}

export const SuccessNotification = ({ text }) => {
    return (
        <>
            <NotificationWrapper
                imgUrl={SuccessBg}
                data-aos-duration="500"
                data-aos="fade-left"
            >
                <IconWrapper>
                    <img src={SuccessIcon} alt="" />
                </IconWrapper>
                <SuccessText>
                    <span className="astro">
                        {text || 'Deposit successful'}
                    </span>
                </SuccessText>
            </NotificationWrapper>

            <NotificationWrapperMobile
                imgUrl={SuccessMobileBg}
                data-aos-duration="500"
                data-aos="fade-left"
            >
                <IconWrapperMobile>
                    <img src={SuccessIcon} alt="" />
                </IconWrapperMobile>
                <SuccessTextMobile>
                    <span className="astro">
                        {text || 'Deposit successful'}
                    </span>
                </SuccessTextMobile>
            </NotificationWrapperMobile>
        </>
    )
}
