import React from 'react'
import styled from 'styled-components'
import Header from './Header'
import { Flex, FlexColumn } from './styled'

const Wrapper = styled(FlexColumn)`
    flex: 1;
`

const Content = styled(Flex)`
    flex: 1;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    flex-direction: column;
  `};
    @media (min-width: 2100px) {
        justify-content: center;
    }
`

export const PageWrapper = ({ children, center = true }) => {
    return (
        <Wrapper>
            <Header />
            <Content style={center ? { justifyContent: 'center' } : {}}>
                {children}
            </Content>
        </Wrapper>
    )
}
