import React from 'react'
import styled from 'styled-components'
import { Centered, Icon } from '../../../components/styled'
import SwitchButtonBg from '../../../assets/dex/dex-blocks/dex-switch-button.svg'
import SwitchIcon from '../../../assets/dex/switch.svg'

const Wrapper = styled(Centered)`
    position: absolute;
    top: 256px;
    left: 318px;
    width: 84px;
    height: 45px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    z-index: 999;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(Centered)`
    display: none;
    position: absolute;
    top: 250px;
    left: 126px;
    width: 84px;
    height: 45px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    z-index: 999;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const IconContainer = styled(Icon)`
    width: 16px;
    height: 14.5px;
    margin-left: 12px;
    margin-top: 8px;
`
export const SwitchBtn = ({ onClick }) => {
    return (
        <>
            <Wrapper imgUrl={SwitchButtonBg} onClick={onClick}>
                <IconContainer>
                    <img src={SwitchIcon} alt="" />
                </IconContainer>
            </Wrapper>

            <WrapperMobile imgUrl={SwitchButtonBg} onClick={onClick}>
                <IconContainer>
                    <img src={SwitchIcon} alt="" />
                </IconContainer>
            </WrapperMobile>
        </>
    )
}
