import { create } from 'zustand'

export const useConnectModalStore = create((set, get) => ({
    isWalletModalOpen: false,
    toggleWalletModal: () => {
        const currentValue = get().isWalletModalOpen
        set({ isWalletModalOpen: !currentValue })
    },
    closeWalletModal: () => {
        set({ isWalletModalOpen: false })
    },
}))
