import React, { useMemo } from 'react'
import {
    ConnectionProvider,
    WalletProvider,
} from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { NETWORKS, useNetworkStore } from '../store/usseNetworkStore'
import { SOLANA_RPC_HTTPS } from '../constants/solana'
import { useConnectModalStore } from '../store/useConnectModalStore'

require('@solana/wallet-adapter-react-ui/styles.css')

export const WalletAdapterContext = ({ children }) => {
    const { setNetwork } = useNetworkStore()

    const network = WalletAdapterNetwork.Mainnet

    const endpoint = SOLANA_RPC_HTTPS

    const wallets = useMemo(() => [new PhantomWalletAdapter()], [network])

    const navigate = useNavigate()

    const { closeWalletModal } = useConnectModalStore()

    const navigateToAllocation = () => navigate(ROUTES.allocations)

    const setConnection = (provider, network) => {
        sessionStorage.setItem('connected', 'true')
        sessionStorage.setItem('provider', provider)
        setNetwork(network)
        sessionStorage.setItem('network', network)
    }

    const onPhantomConnect = async () => {
        setConnection('phantom', NETWORKS.solana)
        closeWalletModal()
        navigateToAllocation()
        return true
    }

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider autoConnect={onPhantomConnect} wallets={wallets}>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}
