import { create } from 'zustand'
import { DEFAULT_SWAP_CHAIN } from '../constants/dex'
import EthIcon from '../assets/dex/eth.svg'
import BNBIcon from '../assets/svg/bnb.svg'

export const SWAP_CHAINS = {
    ethereum: {
        chainId: 1,
        zerionId: 'ethereum',
        name: 'Ethereum',
        icon: EthIcon,
    },
    binance: {
        chainId: 56,
        zerionId: DEFAULT_SWAP_CHAIN,
        name: 'BNB Chain',
        icon: BNBIcon,
    },
}

export const useZerionStore = create((set, get) => ({
    chains: [],
    tokens: [],
    userAssets: [],
    gasPrice: 0,
    swapChain: SWAP_CHAINS.binance,
    setChains: (chains) => set({ chains }),
    setTokens: (tokens) => set({ tokens }),
    setUserAssets: (userAssets) => set({ userAssets }),
    setGasPrice: (gasPrice) => set({ gasPrice }),
    setSwapChain: (swapChain) => set({ swapChain }),
}))
