import React from 'react'
import BlockBg from '../../../../assets/blocks/main-connect-block.svg'
import DividerIcon from '../../../../assets/svg/divider.svg'
import YellowBg from '../../../../assets/blocks/connect-wallet-button.svg'
import ArrowRightIcon from '../../../../assets/svg/arrow-right.svg'
import SquareIcon from '../../../../assets/svg/square-with-lines.svg'
import GIcon from '../../../../assets/svg/g.svg'
import PGIcon from '../../../../assets/svg/pg.svg'
import RecycleIcon from '../../../../assets/svg/resicle.svg'
import GlobeIcon from '../../../../assets/svg/globe.svg'
import SCodeIcon from '../../../../assets/svg/s-code.svg'
import QRCodeIcon from '../../../../assets/svg/qr-code.svg'
import {
    BottomBlocksContainer,
    CentralBlockArrowRight,
    CentralBlockConnectText,
    CentralBlockParagraph,
    CentralBlockTextContainer,
    CentralBlockTitle,
    CentralBlockWrapper,
    ConnectWalletBlock,
    DividerBlock,
    GBlock,
    GlobeBlock,
    LeftBlocksContainer,
    PGBlock,
    QRCodeBlock,
    RecycleBlock,
    SCodeBlock,
    SquareBlock,
    TitleBlock,
} from './styles'

export const DesktopCentralBlock = ({
    account,
    navigateToAllocation,
    activateMetamask,
    buttonText,
}) => {
    return (
        <CentralBlockWrapper imgUrl={BlockBg}>
            <TitleBlock>
                <CentralBlockTitle>
                    <span className="astro">Connect your Wallet</span>
                </CentralBlockTitle>
            </TitleBlock>
            <CentralBlockTextContainer gap="8px">
                <DividerBlock>
                    <img src={DividerIcon} alt="" />
                    <CentralBlockParagraph>
                        <span className="mono">
                            Laika is a small dog with a big dream. Growing up
                            hunting in the vast expanse of the Siberain
                            wilderness, Laika spent many sleepless nights
                            staring up at the night. Laika is a small dog with a
                            big dream. Growing up hunting in the vast expanse of
                            the Siberain wilderness, Laika spent many
                        </span>
                    </CentralBlockParagraph>
                </DividerBlock>
            </CentralBlockTextContainer>
            <ConnectWalletBlock
                onClick={account ? navigateToAllocation : activateMetamask}
                imgUrl={YellowBg}
            >
                <CentralBlockConnectText>
                    <span className="astro">{buttonText}</span>
                </CentralBlockConnectText>
                <CentralBlockArrowRight>
                    <img src={ArrowRightIcon} alt="" />
                </CentralBlockArrowRight>
            </ConnectWalletBlock>
            <SquareBlock src={SquareIcon} alt="" />
            <LeftBlocksContainer>
                <GBlock src={GIcon} alt="" />
                <PGBlock src={PGIcon} alt="" />
                <RecycleBlock src={RecycleIcon} alt="" />
            </LeftBlocksContainer>
            <BottomBlocksContainer>
                <GlobeBlock src={GlobeIcon} alt="" />
                <SCodeBlock src={SCodeIcon} alt="" />
                <QRCodeBlock src={QRCodeIcon} alt="" />
            </BottomBlocksContainer>
        </CentralBlockWrapper>
    )
}
