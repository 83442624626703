import axios from 'axios'
import $client from './client'

class ZerionService {
    async getChains() {
        const url = '/v1/chains/'

        try {
            const { data } = await $client.get(url)
            return data.data
        } catch (err) {
            console.error(err)
        }
    }

    async getGasPrice(chain) {
        const url = `/v1/gas-prices/?filter[chain_ids]=${chain}`

        try {
            const { data } = await $client.get(url)
            return data.data
        } catch (err) {
            console.error(err)
        }
    }

    async getTokens(chain) {
        const url = `/v1/fungibles/?currency=usd&page[size]=100&filter[implementation_chain_id]=${chain}&sort=-market_data.market_cap`

        try {
            const { data } = await $client.get(url)
            return data.data
        } catch (err) {
            console.error(err)
        }
    }

    async getWalletAssets(wallet, chain) {
        const url = `/v1/wallets/${wallet}/positions/?currency=usd&filter[chain_ids]=${chain}&filter[trash]=only_non_trash&sort=value`

        try {
            const { data } = await $client.get(url)
            return data.data
        } catch (err) {
            console.error(err)
        }
    }

    async getSwapData(params) {
        const url = 'https://transactions.zerion.io/swap/quote/stream'

        try {
            const { data } = await axios.get(url, {
                params,
            })
            return data
        } catch (err) {
            console.error(err)
        }
    }

    async getChainById(chainId) {
        const url = `/v1/chains/${chainId}`

        try {
            const { data } = await $client.get(url)
            return data.data
        } catch (err) {
            console.error(err)
        }
    }

    async getAvailableFungibles(inputChainId, outputChainId, direction) {
        const url = `/v1/swap/fungibles/?input[chain_id]=${inputChainId}&output[chain_id]=${outputChainId}&direction=${direction}`

        try {
            const { data } = await $client.get(url)
            return data.data
        } catch (err) {
            console.error(err)
        }
    }

    async getAvailableSwapOffers(inputParams, outputParams) {
        const url = '/v1/swap/offers/'

        try {
            const { data } = await $client.get(url, {
                params: {
                    input: inputParams,
                    output: outputParams,
                },
            })
            return data.data
        } catch (err) {
            console.error(err)
        }
    }
}

export const zerionService = new ZerionService()
