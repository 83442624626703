import axios from 'axios'
import { BASE_URL } from '../constants/solana'

class UserServiceInstance {
    base_url = '/user'
    constructor(client) {
        this.client = client
    }

    async login(address) {
        await this.client.get(`${this.base_url}/me/${address}`)
    }
}

export const userService = new UserServiceInstance(
    axios.create({
        baseURL: BASE_URL,
    }),
)
