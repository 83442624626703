import React from 'react'
import styled from 'styled-components'
import ButtonBg from '../../../assets/dex/dex-blocks/dex-button.svg'
import ButtonDisabledBg from '../../../assets/dex/dex-blocks/dex-disabled-button.svg'
import { RowBetween } from '../../../components/styled'

const Wrapper = styled(RowBetween)`
    position: absolute;
    bottom: 46px;
    right: 40px;
    width: 100%;
    max-width: 292px;
    height: 63px;
    padding-left: 40px;
    padding-right: 32px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    z-index: 2;

    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 796px) {
        background-size: contain;
        background-repeat: no-repeat;
        height: 43px;
        bottom: 0;
        top: 150px;
        right: 50%;
        transform: translateX(65%);
    }
`

const Text = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 23.04px;
    @media (max-width: 796px) {
        font-size: 12px;
        line-height: 14px;
    }
`

export const ClaimButton = ({ disabled, loading, text, onClick }) => {
    return (
        <Wrapper
            imgUrl={disabled ? ButtonDisabledBg : ButtonBg}
            onClick={onClick}
            style={disabled ? { pointerEvents: 'none' } : {}}
        >
            {!loading && (
                <Text>
                    <span className="astro">{text}</span>
                </Text>
            )}

            {loading && (
                <div className="loader" style={{ margin: ' 0 auto' }}></div>
            )}
        </Wrapper>
    )
}
