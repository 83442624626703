import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const Tooltip = ({
    children,
    dataFor,
    dataTip,
    place,
    disable,
    marginLeft,
}) => {
    return (
        <div
            data-tooltip-content={dataTip}
            data-tooltip-id={dataFor}
            data-event="mouseover"
            data-event-off="mouseleave"
            data-offset="{'right': 2}"
            style={{ marginLeft }}
        >
            {children}
            <ReactTooltip
                disable={disable}
                place={place}
                id={dataFor}
                effect="solid"
                arrowColor="transparent"
            />
        </div>
    )
}
