import React from 'react'
import BlockBg from '../../../../assets/mobile/blocks/mobile-central-block-bg.svg'
import DividerIcon from '../../../../assets/mobile/blocks/divider.svg'
import ButtonBg from '../../../../assets/mobile/blocks/button-bg.svg'
import ArrowRightIcon from '../../../../assets/svg/arrow-right.svg'
import SquareIcon from '../../../../assets/svg/square-with-lines.svg'
import GIcon from '../../../../assets/svg/g.svg'
import PGIcon from '../../../../assets/svg/pg.svg'
import RecycleIcon from '../../../../assets/svg/resicle.svg'
import GlobeIcon from '../../../../assets/svg/globe.svg'
import SCodeIcon from '../../../../assets/svg/s-code.svg'
import QRCodeIcon from '../../../../assets/svg/qr-code.svg'
import {
    BottomBlocksContainerMobile,
    CentralBlockArrowRightMobile,
    CentralBlockConnectTextMobile,
    CentralBlockMobileParagraph,
    CentralBlockMobileTitle,
    CentralBlockMobileWrapper,
    ConnectWalletBlockMobile,
    DividerBlockMobile,
    GBlockMobile,
    GlobeBlockMobile,
    LeftBlocksContainerMobile,
    PGBlockMobile,
    QRCodeBlockMobile,
    RecycleBlockMobile,
    SCodeBlockMobile,
    SquareBlockMobile,
    TitleBlockMobile,
} from './styles'

export const MobileCentralBlock = ({
    account,
    navigateToAllocation,
    activateMetamask,
    buttonText,
}) => {
    return (
        <CentralBlockMobileWrapper imgUrl={BlockBg}>
            <TitleBlockMobile>
                <CentralBlockMobileTitle>
                    <span className="astro">Connect your Wallet</span>
                </CentralBlockMobileTitle>
            </TitleBlockMobile>

            <DividerBlockMobile>
                <img src={DividerIcon} alt="" />
                <CentralBlockMobileParagraph>
                    <span className="mono">
                        Laika is a small dog with a big dream. Growing up
                        hunting in the vast expanse of the Siberain wilderness,
                        Laika spent many sleepless nights staring up at the
                        night. Laika is a small dog with a big dream. Growing up
                        hunting in the vast expanse of the Siberain wilderness,
                        Laika spent many
                    </span>
                </CentralBlockMobileParagraph>
            </DividerBlockMobile>
            <ConnectWalletBlockMobile
                onClick={account ? navigateToAllocation : activateMetamask}
                imgUrl={ButtonBg}
            >
                <CentralBlockConnectTextMobile>
                    <span className="astro">{buttonText}</span>
                </CentralBlockConnectTextMobile>
                <CentralBlockArrowRightMobile>
                    <img src={ArrowRightIcon} alt="" />
                </CentralBlockArrowRightMobile>
            </ConnectWalletBlockMobile>
            <SquareBlockMobile src={SquareIcon} alt="" />
            <LeftBlocksContainerMobile>
                <GBlockMobile src={GIcon} alt="" />
                <PGBlockMobile src={PGIcon} alt="" />
                <RecycleBlockMobile src={RecycleIcon} alt="" />
            </LeftBlocksContainerMobile>
            <BottomBlocksContainerMobile>
                <GlobeBlockMobile src={GlobeIcon} alt="" />
                <SCodeBlockMobile src={SCodeIcon} alt="" />
                <QRCodeBlockMobile src={QRCodeIcon} alt="" />
            </BottomBlocksContainerMobile>
        </CentralBlockMobileWrapper>
    )
}
