import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import { phantomWallet } from '@rainbow-me/rainbowkit/wallets'

import { configureChains, createConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'

export const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet],
    [publicProvider()],
)

export const wallets = [
    phantomWallet({
        chains,
        shimDisconnect: true,
    }),
]

const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets,
    },
])

export const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
})
