import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useAccount, useConnect } from 'wagmi'
import BackgroundImg from '../../assets/img/background.svg'
import BottomIcon from '../../assets/img/bottom-right.png'
import { PageWrapper } from '../../components/PageWrapper'
import { AppWrapper, Background } from '../../components/common'
import { NETWORKS, useNetworkStore } from '../../store/usseNetworkStore'
import { ConnectWalletModal } from '../../components/Web3Status/ConnectWalletModal'
import { useModal } from '../../hooks/useModal'
import { getProvider } from '../../utils/phantom'
import { AllocationsForm } from './components/AllocationsForm'
// import { Batches } from './components/batches/Batches'

const BottomImg = styled.div`
    width: 523px;
    height: 666px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
`

export function Allocations() {
    const { account } = useWeb3React()
    const { address } = useAccount()
    const { connectors, connect } = useConnect()
    const { setNetwork } = useNetworkStore()
    const { isOpen, closeModal, openModal } = useModal()
    const phantom = getProvider()

    const setConnection = (provider, newNetwork) => {
        sessionStorage.setItem('connected', 'true')
        sessionStorage.setItem('provider', provider)
        setNetwork(newNetwork)
        sessionStorage.setItem('network', newNetwork)
    }

    const currentNetwork = sessionStorage.getItem('network')
    const currentProvider = sessionStorage.getItem('provider')

    const handleSwitchNetworks = useCallback(async () => {
        if (
            currentNetwork === NETWORKS.bnb &&
            currentProvider === 'phantom' &&
            !account
        ) {
            openModal()
        } else if (currentNetwork === NETWORKS.solana && !address && phantom) {
            await connect({ connector: connectors[0] })
            setConnection('phantom', NETWORKS.solana)
        }
    }, [currentNetwork, address, account, currentProvider])

    useEffect(() => {
        handleSwitchNetworks()
    }, [handleSwitchNetworks])

    return (
        <AppWrapper>
            <PageWrapper>
                <AllocationsForm />
                {/* <Batches />*/}
            </PageWrapper>
            <Background imgUrl={BackgroundImg}>
                <BottomImg>
                    <img src={BottomIcon} alt="" />
                </BottomImg>
            </Background>
            {isOpen && (
                <ConnectWalletModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    isDex={true}
                />
            )}
        </AppWrapper>
    )
}
