import $solanaClient from './solanaClient'

class SolanaService {
    async sendTxHash(hash) {
        const url = '/deposits/solana/addTransaction'

        try {
            await $solanaClient.post(url, {
                hash,
            })
        } catch (err) {
            console.error(err)
        }
    }

    async getRewards(walletAddress, chain = 'solana') {
        const url = `/deposits/${chain}/${walletAddress}`

        try {
            const { data } = await $solanaClient.get(url)
            return data
        } catch (err) {
            console.error(err)
        }
    }
}

export const solanaService = new SolanaService()
