import { useCallback } from 'react'
import { ethers } from 'ethers'
import { MULTICALL_ABI } from '../constants/abi/multicallAbi'
import {
    CONTRACT_ADDRESS,
    MULTICALL_CONTRACT_ADDRESS,
    NETWORK_RPC,
} from '../constants'
import { CONTRACT_ABI } from '../constants/abi/contract-abi'

export function useMulticall() {
    const provider = new ethers.providers.JsonRpcProvider(NETWORK_RPC)

    const buyContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        CONTRACT_ABI,
        provider,
    )

    const multicall = new ethers.Contract(
        MULTICALL_CONTRACT_ADDRESS,
        MULTICALL_ABI,
        provider,
    )

    const getInvites = useCallback(
        async (account, refLength) => {
            const callData = []
            for (let i = 0; i < refLength; i++) {
                const callDataItem = [
                    CONTRACT_ADDRESS,
                    buyContract.interface.encodeFunctionData(
                        'referrerToReferrals',
                        [account, i],
                    ),
                ]
                callData.push(callDataItem)
            }
            try {
                // eslint-disable-next-line no-unused-vars
                const [_, returnData] = await multicall.aggregate(callData)

                const reducedData = returnData.reduce(
                    (response, aggregateItemResult, i) => {
                        const data = buyContract.interface.decodeFunctionResult(
                            'referrerToReferrals',
                            aggregateItemResult,
                        )
                        response[i] = data[0]
                        return response
                    },
                    [],
                )

                const callDataSecondary = reducedData.map((address) => {
                    return [
                        CONTRACT_ADDRESS,
                        buyContract.interface.encodeFunctionData(
                            'getReferralsLength',
                            [address],
                        ),
                    ]
                })
                // eslint-disable-next-line no-unused-vars
                const [__, returnDataSecondary] =
                    await multicall.aggregate(callDataSecondary)

                const reducedDataSecondary = returnDataSecondary.reduce(
                    (response, aggregateItemResult, i) => {
                        const data = buyContract.interface.decodeFunctionResult(
                            'getReferralsLength',
                            aggregateItemResult,
                        )
                        response[i] = parseInt(data[0]?.toString())
                        return response
                    },
                    [],
                )

                let result = refLength
                reducedDataSecondary.forEach((item) => {
                    result += item
                })
                return result
            } catch (err) {
                console.error(err)
            }
        },
        [buyContract, multicall],
    )

    return { getInvites }
}
