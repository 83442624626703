import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useTokenContract } from './useContract'

export function useToken(address, refetch) {
    const { account } = useWeb3React()

    const [balance, setBalance] = useState(0)

    const token = useTokenContract(address, true)

    const getTokenBalance = async () => {
        return await token.balanceOf(account)
    }

    const fetchData = useCallback(() => {
        getTokenBalance()
            .then((data) => {
                const rawValue = ethers.utils.formatEther(data)
                const value = Number(parseFloat(rawValue).toFixed(2))
                setBalance(value)
            })
            .catch(console.error)
    }, [token, account])

    useEffect(() => {
        if (!account) {
            setBalance(0)
        }
        if (!token) return
        fetchData()
    }, [account, token, fetchData, refetch])

    return { balance }
}
