import axios from 'axios'
import { BASE_URL } from '../constants/solana'
import { tasksStore } from '../pages/Tasks/tasksStore'

class TasksServiceInstance {
    base_url = '/rewards'
    constructor(client) {
        this.client = client
    }

    async getRewards(address) {
        const response = await this.client.get(`${this.base_url}/${address}`)
        tasksStore.setState({
            totalRewards: response.data.totalRewards,
            claimedToday: response.data.claimedToday,
            timeToNextReward: response.data.timeToNextReward,
            history: response.data.history,
            streak: response.data.streak,
        })
        return
    }

    async claimReward(address) {
        const response = await this.client.post(
            `${this.base_url}/claim-daily`,
            {
                address,
            },
        )

        if (response.data.id) {
            await this.getRewards(address)
        } else {
            tasksStore.getState().reset()
        }
        return
    }
}

export const tasksService = new TasksServiceInstance(
    axios.create({
        baseURL: BASE_URL,
    }),
)
