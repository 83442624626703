import React from 'react'
import Loader from '../../../components/Loader'
import { Centered } from '../../../components/styled'

export const DexLoader = () => {
    return (
        <Centered style={{ height: '100%' }}>
            <Loader size="60px" />
        </Centered>
    )
}
