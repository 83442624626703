import { create } from 'zustand'

export const useDexStore = create((set, get) => ({
    payCurrencyId: '',
    payAmount: '',
    receiveCurrencyId: '',
    receiveAmount: '',
    refetchUserAssets: false,
    setPayCurrencyId: (currencyId) => set({ payCurrencyId: currencyId }),
    setPayAmount: (amount) => set({ payAmount: amount }),
    setReceiveCurrencyId: (currencyId) =>
        set({ receiveCurrencyId: currencyId }),
    setReceiveAmount: (amount) => set({ receiveAmount: amount }),
    setRefetchUserAssets: () => {
        const currentValue = get().refetchUserAssets
        set({ refetchUserAssets: !currentValue })
    },
    switchCurrencies: () => {
        const currentPayId = get().payCurrencyId
        const currentReceiveId = get().receiveCurrencyId

        set({
            payCurrencyId: currentReceiveId,
            receiveCurrencyId: currentPayId,
        })
    },
    reset: () => {
        set({
            payCurrencyId: '',
            payAmount: '',
            receiveCurrencyId: '',
            receiveAmount: '',
            refetchUserAssets: false,
        })
    },
}))
