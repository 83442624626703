import { create } from 'zustand'

export const NETWORKS = {
    empty: 'none',
    bnb: 'bnb',
    solana: 'solana',
}

export const useNetworkStore = create((set) => ({
    network: NETWORKS.bnb,
    setNetwork: (newNetwork) => {
        sessionStorage.setItem('network', newNetwork)
        set({ network: newNetwork })
    },
}))
