import { useCallback, useState } from 'react'
import {
    Connection,
    LAMPORTS_PER_SOL,
    PublicKey,
    Keypair,
    SystemProgram,
} from '@solana/web3.js'
import {
    getAssociatedTokenAddress,
    getAccount,
    TOKEN_PROGRAM_ID,
    getOrCreateAssociatedTokenAccount,
    NATIVE_MINT,
} from '@solana/spl-token'
import bs58 from 'bs58'
import * as anchor from '@coral-xyz/anchor'
import { Program } from '@coral-xyz/anchor'
import { getPhantomAddress, getProvider } from '../utils/phantom'
import {
    FINANCE_WALLET_ADDRESS,
    MAXIMUM_TICKETS_PER_USER,
    MINIMUM_TICKETS_PER_USER,
    SOLANA_CONTRACT_ADDRESS,
    SOLANA_PRICE,
    SOLANA_RPC_HTTPS,
    SOLANA_RPC_WSS,
    SOLANA_USDT_DECIMALS,
    STATE_PUBLIC_KEY,
    TOTAL_TICKETS_THRESHOLD,
    USDC_DEV_ADDRESS,
    USDC_PRICE,
} from '../constants/solana'
import { useNotificationsContext } from '../context/Notificationscontext'
import { IDL } from '../entities'
import { useSolanaStore } from '../store/useSolanaStore'
import { solanaService } from '../api/service'

export function useSolana() {
    const {
        showPendingNotification,
        showSuccessNotification,
        showErrorNotification,
    } = useNotificationsContext()

    const { setPrices, setSolanaInfo } = useSolanaStore()

    const [isTxLoading, setIsTxLoading] = useState(false)

    const connection = new Connection(SOLANA_RPC_HTTPS, {
        commitment: 'confirmed',
        wsEndpoint: SOLANA_RPC_WSS,
    })

    async function sleep(ms) {
        await _sleep(ms)
    }

    function _sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    const getBalance = useCallback(
        async (phantomAddress) => {
            if (!phantomAddress) return

            const phantom = await getPhantomAddress()

            if (!phantom?.publicKey) {
                return {
                    solBalance: 0,
                    usdcBalance: 0,
                }
            }
            const balance = await connection.getBalance(
                phantom?.publicKey?.publicKey,
            )

            try {
                const usdcMint = new PublicKey(USDC_DEV_ADDRESS)
                const usdcAddress = await getAssociatedTokenAddress(
                    usdcMint,
                    phantom?.publicKey?.publicKey,
                )
                try {
                    const usdcDetails = await getAccount(
                        connection,
                        usdcAddress,
                    )
                    const usdcDecimals = 6
                    const usdcBalance =
                        Number(usdcDetails.amount) / Math.pow(10, usdcDecimals)

                    return {
                        solBalance: balance / LAMPORTS_PER_SOL,
                        usdcBalance: usdcBalance || 0,
                    }
                } catch {
                    return {
                        solBalance: balance / LAMPORTS_PER_SOL,
                        usdcBalance: 0,
                    }
                }
            } catch (e) {
                console.error(e)
            }

            return {
                solBalance: 0,
                usdcBalance: 0,
            }
        },
        [connection],
    )

    const activateContract = useCallback(async () => {
        try {
            const provider = getProvider()

            const PROGRAM_ID = new PublicKey(SOLANA_CONTRACT_ADDRESS)

            const createdWallet = {
                publicKey: provider.publicKey,
                signTransaction: provider.signTransaction,
                signAllTransactions: provider.signAllTransactions,
            }

            const createdProvider = new anchor.AnchorProvider(
                connection,
                createdWallet,
                anchor.AnchorProvider.defaultOptions(),
            )
            const program = new Program(IDL, PROGRAM_ID, createdProvider)

            const state = Keypair.generate()

            console.log('publik', state.publicKey.toString())
            console.log('secret', bs58.encode(state.secretKey))

            const admin = provider
            const finanseWallet = new PublicKey(FINANCE_WALLET_ADDRESS)

            const usdcPubKey = new PublicKey(USDC_DEV_ADDRESS)

            try {
                await program.methods
                    .initialize({
                        prices: [
                            {
                                token: usdcPubKey,
                                price: new anchor.BN(USDC_PRICE),
                            },
                            {
                                token: NATIVE_MINT,
                                price: new anchor.BN(SOLANA_PRICE),
                            },
                        ],
                        minAmountToBuy: new anchor.BN(MINIMUM_TICKETS_PER_USER),
                        maxTicketsPerUser: new anchor.BN(
                            MAXIMUM_TICKETS_PER_USER,
                        ),
                        totalTicketsThreshold: new anchor.BN(
                            TOTAL_TICKETS_THRESHOLD,
                        ),
                        tokenReceiver: finanseWallet,
                    })
                    .accounts({
                        state: state.publicKey,
                        admin: admin.publicKey,
                        systemProgram: SystemProgram.programId,
                    })
                    .signers([state])
                    .rpc()
            } catch (err) {
                console.log(err)
            }

            await sleep(2000)

            await program.account.state
                .fetch(state.publicKey)
                .catch((err) => console.log('state:', err))

            console.log('initialized')
        } catch (err) {
            setIsTxLoading(false)
            showErrorNotification()
        }
    }, [connection])

    const buySolanaTickets = useCallback(
        async (amount, setAmount, refetchSolanaBalance) => {
            showPendingNotification()
            setIsTxLoading(true)
            try {
                const provider = getProvider()

                const PROGRAM_ID = new PublicKey(SOLANA_CONTRACT_ADDRESS)

                const createdWallet = {
                    publicKey: provider.publicKey,
                    signTransaction: provider.signTransaction,
                    signAllTransactions: provider.signAllTransactions,
                }

                const createdProvider = new anchor.AnchorProvider(
                    connection,
                    createdWallet,
                    anchor.AnchorProvider.defaultOptions(),
                )

                const finanseWallet = new PublicKey(FINANCE_WALLET_ADDRESS)

                const program = new Program(IDL, PROGRAM_ID, createdProvider)

                const contractState = new PublicKey(STATE_PUBLIC_KEY)

                const usdcPubKey = new PublicKey(USDC_DEV_ADDRESS)

                const buyerTokenAccaunt =
                    await getOrCreateAssociatedTokenAccount(
                        connection,
                        provider,
                        usdcPubKey,
                        provider.publicKey,
                    ).catch((err) => console.log(err))

                const receiverTokenAccount =
                    await getOrCreateAssociatedTokenAccount(
                        connection,
                        provider,
                        usdcPubKey,
                        finanseWallet,
                    ).catch((err) => console.log(err))

                const [buyerStatePda] = await PublicKey.findProgramAddressSync(
                    [provider.publicKey.toBuffer()],
                    PROGRAM_ID,
                )

                try {
                    await program.account.userState.fetch(buyerStatePda)
                } catch (err) {
                    const rentExemptAmount =
                        await connection.getMinimumBalanceForRentExemption(115)

                    const transaction = new anchor.web3.Transaction().add(
                        anchor.web3.SystemProgram.transfer({
                            fromPubkey: provider.publicKey,
                            toPubkey: buyerStatePda,
                            lamports: rentExemptAmount,
                        }),
                    )

                    try {
                        await anchor.web3.sendAndConfirmTransaction(
                            connection,
                            transaction,
                            [],
                        )
                    } catch (err) {}

                    await program.methods
                        .createUser()
                        .accounts({
                            state: contractState,
                            userState: buyerStatePda,
                            user: provider.publicKey,
                            systemProgram: SystemProgram.programId,
                        })
                        .signers([])
                        .rpc()
                }

                const formattedAmount =
                    SOLANA_USDT_DECIMALS * parseFloat(amount)

                const tx = await program.methods
                    .buyTickets({
                        amount: new anchor.BN(formattedAmount),
                        refCode: '123',
                    })
                    .accounts({
                        state: contractState,
                        userState: buyerStatePda,
                        buyer: provider.publicKey,
                        tokenProgramAccount: TOKEN_PROGRAM_ID,
                        mintAccount: usdcPubKey,
                        senderAta: buyerTokenAccaunt.address,
                        adminAta: receiverTokenAccount.address,
                        systemProgram: SystemProgram.programId,
                    })
                    .signers([])
                    .rpc()

                await sleep(1000)
                await solanaService.sendTxHash(tx)

                setAmount('')
                refetchSolanaBalance()
                showSuccessNotification()
                setIsTxLoading(false)
            } catch (err) {
                setIsTxLoading(false)
                showErrorNotification()
            }
        },
        [connection],
    )

    const buyTicketsWithSolana = useCallback(
        async (amount, setAmount, refetchSolanaBalance) => {
            showPendingNotification()
            setIsTxLoading(true)
            try {
                const provider = getProvider()

                const PROGRAM_ID = new PublicKey(SOLANA_CONTRACT_ADDRESS)

                const createdWallet = {
                    publicKey: provider.publicKey,
                    signTransaction: provider.signTransaction,
                    signAllTransactions: provider.signAllTransactions,
                }

                const createdProvider = new anchor.AnchorProvider(
                    connection,
                    createdWallet,
                    anchor.AnchorProvider.defaultOptions(),
                )

                const finanseWallet = new PublicKey(FINANCE_WALLET_ADDRESS)

                const program = new Program(IDL, PROGRAM_ID, createdProvider)

                const contractState = new PublicKey(STATE_PUBLIC_KEY)

                const [buyerStatePda] = await PublicKey.findProgramAddressSync(
                    [provider.publicKey.toBuffer()],
                    PROGRAM_ID,
                )

                try {
                    await program.account.userState.fetch(buyerStatePda)
                } catch (err) {
                    const rentExemptAmount =
                        await connection.getMinimumBalanceForRentExemption(115)

                    const transaction = new anchor.web3.Transaction().add(
                        anchor.web3.SystemProgram.transfer({
                            fromPubkey: provider.publicKey,
                            toPubkey: buyerStatePda,
                            lamports: rentExemptAmount,
                        }),
                    )

                    try {
                        await anchor.web3.sendAndConfirmTransaction(
                            connection,
                            transaction,
                            [],
                        )
                    } catch (err) {}

                    await program.methods
                        .createUser()
                        .accounts({
                            state: contractState,
                            userState: buyerStatePda,
                            user: provider.publicKey,
                            systemProgram: SystemProgram.programId,
                        })
                        .signers([])
                        .rpc()
                }

                const formattedAmount = LAMPORTS_PER_SOL * parseFloat(amount)

                const tx = await program.methods
                    .buyTicketsForSol({
                        amount: new anchor.BN(formattedAmount),
                        refCode: '123',
                    })
                    .accounts({
                        state: contractState,
                        userState: buyerStatePda,
                        buyer: provider.publicKey,
                        admin: finanseWallet,
                        systemProgram: SystemProgram.programId,
                    })
                    .signers([])
                    .rpc()

                await sleep(1000)
                await solanaService.sendTxHash(tx)

                setAmount('')
                refetchSolanaBalance()
                showSuccessNotification()
                setIsTxLoading(false)
            } catch (err) {
                setIsTxLoading(false)
                showErrorNotification()
            }
        },
        [connection],
    )

    const getUserTicketsSolana = useCallback(
        async (phantomAddress) => {
            if (!phantomAddress) return

            try {
                const provider = getProvider()
                if (!provider.publicKey) {
                    return
                }
                const PROGRAM_ID = new PublicKey(SOLANA_CONTRACT_ADDRESS)
                const createdWallet = {
                    publicKey: provider.publicKey,
                    signTransaction: provider.signTransaction,
                    signAllTransactions: provider.signAllTransactions,
                }
                const createdProvider = new anchor.AnchorProvider(
                    connection,
                    createdWallet,
                    anchor.AnchorProvider.defaultOptions(),
                )
                const program = new Program(IDL, PROGRAM_ID, createdProvider)
                const [buyerStatePda] = await PublicKey.findProgramAddressSync(
                    [provider.publicKey.toBuffer()],
                    PROGRAM_ID,
                )
                const userState =
                    await program.account.userState.fetch(buyerStatePda)

                return parseFloat(userState?.userTicketsTotal?.toString())
            } catch (err) {
                return 0
            }
        },
        [connection],
    )

    const getContractStateInfo = useCallback(
        async (phantomAddress) => {
            if (!phantomAddress) return

            try {
                const provider = getProvider()
                if (!provider.publicKey) {
                    return null
                }

                const PROGRAM_ID = new PublicKey(SOLANA_CONTRACT_ADDRESS)
                const statePublic = new PublicKey(STATE_PUBLIC_KEY)

                const createdWallet = {
                    publicKey: provider.publicKey,
                    signTransaction: provider.signTransaction,
                    signAllTransactions: provider.signAllTransactions,
                }
                const createdProvider = new anchor.AnchorProvider(
                    connection,
                    createdWallet,
                    anchor.AnchorProvider.defaultOptions(),
                )

                const program = new Program(IDL, PROGRAM_ID, createdProvider)

                const state = await program.account.state.fetch(statePublic)

                const prices = {
                    usdt: 0,
                    solana: 0,
                }

                if (state && state.info && state.info.length) {
                    const totalTickets = state.totalTickets.toString()
                    const totalTicketsThreshHold =
                        state.totalTicketsThreshold.toString()
                    const maxTicketsPerUser = state.maxTicketsPerUser.toString()

                    setSolanaInfo(
                        Number(totalTickets),
                        Number(totalTicketsThreshHold),
                        Number(maxTicketsPerUser),
                    )

                    state.info.forEach((item) => {
                        if (item.token.toString() === NATIVE_MINT.toString()) {
                            prices.solana = Number(item.price.toString())
                        } else if (item.token.toString() === USDC_DEV_ADDRESS) {
                            prices.usdt = Number(item.price.toString())
                        }
                    })
                }

                setPrices(prices)
            } catch (err) {
                return null
            }
        },
        [connection],
    )

    return {
        getBalance,
        buySolanaTickets,
        isTxLoading,
        activateContract,
        getUserTicketsSolana,
        buyTicketsWithSolana,
        getContractStateInfo,
    }
}
