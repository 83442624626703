import React from 'react'
import styled from 'styled-components'
import CommingSoonImg from '../../../assets/tasks/coming_soon.png'
import LeftBg from '../../../assets/tasks/left_bg.png'
import MobileBg from '../../../assets/mobile/dex/dex-bg.svg'
import { useTasksStore } from '../tasksStore'
import useWindowSize from '../../../hooks/useWindowSize'

const LeftWrapper = styled.div`
    width: 100%;
    height: 456px;
    max-width: 745px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    background-size: contain;
    position: relative;
    padding-top: 35px;
    background-repeat: no-repeat;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    background-size: cover;
    max-width: 320px;
    width: 100%;
    height: 540px;
  `}
`

const CommingSoon = styled.img`
    max-width: 90%;
    position: absolute;
    bottom: 40px;
    left: 40px;
    @media (max-width: 796px) {
        left: 15px;
        bottom: 100px;
    }
`

const Title = styled.h1`
    margin-left: 110px;
    font-size: 16px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 16px;
    line-height: 16px;
    margin-left: 20px;
    margin-top: 20px;
  `}
`
const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    padding-left: 50px;
    @media (max-width: 796px) {
        padding-left: 20px;
        flex-direction: column;
    }
`

const Subtitle = styled.span`
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 16px;

    @media (max-width: 796px) {
        font-size: 14px;
        line-height: 14px;
    }
`
const LeftInfo = styled.div`
    max-width: 50%;
    display: flex;
    flex-direction: column;
    @media (max-width: 796px) {
        max-width: 90%;
    }
`
const RightInfo = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 796px) {
        width: 90%;
        align-items: flex-start;
        margin-top: 20px;
    }
`
const Points = styled.p`
    font-size: 93px;
    margin-top: 40px;
    color: ${({ theme }) => theme.rangeBg};
    @media (max-width: 796px) {
        margin-top: 20px;
        font-size: 38px;
        line-height: 38px;
    }
`

export const TasksLeftInfo = () => {
    const { totalRewards } = useTasksStore()
    const width = useWindowSize().width
    return (
        <LeftWrapper imgUrl={width > 797 ? LeftBg : MobileBg}>
            <CommingSoon src={CommingSoonImg} />
            <Title className="astro">Points</Title>
            <InfoContainer>
                <LeftInfo>
                    <Subtitle className="astro">
                        Complete tasks <br /> and get LAIKA points
                    </Subtitle>
                    <p className="mono">
                        Laika points can be earned by swaps and daily tasks. You
                        can convert Laika points into Laika tokens after buying
                        at least 1 USDT in Laika.
                    </p>
                </LeftInfo>
                <RightInfo>
                    <p className="mono">Points</p>
                    <Points className="astro">{totalRewards}</Points>
                </RightInfo>
            </InfoContainer>
        </LeftWrapper>
    )
}
