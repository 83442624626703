import React from 'react'
import styled from 'styled-components'
import EnterBonusInputBg from '../../../../assets/blocks/enter-bonus-input-block.svg'
import ApplyBg from '../../../../assets/blocks/apply-block.svg'
import ApplyBgWhite from '../../../../assets/blocks/apply-block-white.svg'
import ApplyBgMobile from '../../../../assets/mobile/blocks/apply-bg.svg'
import InputBgMobile from '../../../../assets/mobile/blocks/input-bg.svg'
import {
    AutoColumn,
    Centered,
    FlexAlign,
    RowBetween,
} from '../../../../components/styled'
import { refCodeRegexp } from '../../../../constants/regExp'
import { escapeRegExp } from '../../../../utils/escapeRegExp'

const EnterBonusWrapper = styled.div`
    padding-left: 8px;
    position: relative;
    width: 100%;
    max-width: 738px;
    margin-top: -8px;
    height: 260px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const EnterBonusWrapperMobile = styled.div`
    display: none;
    padding-left: 8px;
    margin-top: 60px;
    position: relative;
    width: 100%;
    max-width: 738px;
    height: 168px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const ApplyBlock = styled(Centered)`
    position: absolute;
    bottom: 106px;
    right: 0px;
    width: 100%;
    height: 56px;
    padding-inline: 16px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    p {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: ${({ theme }) => theme.primary};
    }
`

const ApplyBlockMobile = styled(ApplyBlock)`
    position: relative;
    bottom: -5px;
    right: -4px;
    height: 48px;

    p {
        font-weight: 700;
        font-size: 13px;
        line-height: 19.5px;
    }
`

const InputBlock = styled(RowBetween)`
    margin-top: 16px;
    width: 100%;
    max-width: 526px;
    height: 56px;
    padding-inline: 22px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const InputBlockMobile = styled(RowBetween)`
    margin-top: 12px;
    width: 100%;
    max-width: 182px;
    height: 50px;
    padding-inline: 22px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const GetACodeParagraph = styled.p`
    width: 100%;
    max-width: 527px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.8px;
    color: ${({ theme }) => theme.primary};
`

const GetACodeParagraphMobile = styled(GetACodeParagraph)`
    max-width: 237px;
    font-size: 13px;
    line-height: 16.9px;
`

const GetACodeTitle = styled.p`
    font-weight: 400;
    line-height: 24px;
    font-size: 24px;
    color: ${({ theme }) => theme.primary};
`

const GetACodeTitleMobile = styled(GetACodeTitle)`
    line-height: 16px;
    font-size: 16px;
`
export const EnterCodeSection = ({
    code,
    setCode,
    isApplied,
    onApply,
    disabled,
}) => {
    const applyText = isApplied ? 'Applied' : 'Apply'

    const enforcer = (newValue) => {
        if (newValue === '' || refCodeRegexp.test(escapeRegExp(newValue))) {
            setCode(newValue)
        }
    }

    const disabledButton = !code || disabled

    return (
        <>
            <EnterBonusWrapper>
                <AutoColumn gap="16px">
                    <GetACodeTitle>
                        <span className="astro">Enter code</span>
                    </GetACodeTitle>
                    <GetACodeParagraph>
                        <span className="mono">
                            Enter the referral code and get access to the
                            lottery with the possibility of getting additional
                            allocation.
                        </span>
                    </GetACodeParagraph>
                </AutoColumn>
                <InputBlock imgUrl={EnterBonusInputBg}>
                    <input
                        className="enter-bonus-input"
                        value={code}
                        onChange={(e) => enforcer(e.target.value)}
                        type="text"
                        placeholder="H10T4JUTH10T4JUT"
                        disabled={disabled}
                    />
                </InputBlock>
                <ApplyBlock
                    imgUrl={isApplied ? ApplyBg : ApplyBgWhite}
                    onClick={onApply}
                    style={{
                        maxWidth: isApplied || disabled ? '94px' : '91px',
                        pointerEvents: disabledButton ? 'none' : 'unset',
                        opacity: disabledButton ? 0.5 : 1,
                    }}
                >
                    <p className="sans">{applyText}</p>
                </ApplyBlock>
            </EnterBonusWrapper>
            <EnterBonusWrapperMobile>
                <AutoColumn gap="8px">
                    <GetACodeTitleMobile>
                        <span className="astro">Enter code</span>
                    </GetACodeTitleMobile>
                    <GetACodeParagraphMobile>
                        <span className="mono">Enter the referral code.</span>
                    </GetACodeParagraphMobile>
                </AutoColumn>
                <FlexAlign>
                    <InputBlockMobile imgUrl={InputBgMobile}>
                        <input
                            className="enter-bonus-input enter-bonus-input-mobile"
                            value={code}
                            onChange={(e) => enforcer(e.target.value)}
                            type="text"
                            placeholder="H10T4JUTH10T4JUT"
                            disabled={disabled}
                        />
                    </InputBlockMobile>
                    <ApplyBlockMobile
                        imgUrl={isApplied ? ApplyBgMobile : ApplyBgMobile}
                        onClick={onApply}
                        style={{
                            maxWidth: isApplied || disabled ? '80px' : '80px',
                            pointerEvents: disabledButton ? 'none' : 'unset',
                            opacity: disabledButton ? 0.5 : 1,
                        }}
                    >
                        <p className="sans">{applyText}</p>
                    </ApplyBlockMobile>
                </FlexAlign>
            </EnterBonusWrapperMobile>
        </>
    )
}
