import { useWeb3React } from '@web3-react/core'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import { tasksService } from '../../api/tasksService'
import BackgroundImg from '../../assets/img/background.svg'
import BottomIcon from '../../assets/img/bottom-right.png'
import { PageWrapper } from '../../components/PageWrapper'
import { AppWrapper, Background } from '../../components/common'
import { TasksGeneralInfo } from './components/TasksInfo'

const BottomImg = styled.div`
    width: 523px;
    height: 666px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
`

export const Tasks = () => {
    const { account } = useWeb3React()
    const { address } = useAccount()

    useEffect(() => {
        if (account || address) {
            tasksService.getRewards(account || address).catch(console.warn)
        }
    }, [account, address])

    return (
        <AppWrapper>
            <PageWrapper center={false}>
                <TasksGeneralInfo />
            </PageWrapper>
            <Background imgUrl={BackgroundImg}>
                <BottomImg>
                    <img src={BottomIcon} alt="" />
                </BottomImg>
            </Background>
        </AppWrapper>
    )
}
