import styled from 'styled-components'

export const Flex = styled.div`
    display: flex;
`

export const FlexColumn = styled(Flex)`
    flex-direction: column;
`

export const FlexAlign = styled.div`
    display: flex;
    align-items: center;
`

export const JustifyEnd = styled(FlexAlign)`
    justify-content: flex-end;
`

export const Centered = styled(Flex)`
    justify-content: center;
    align-items: center;
`

export const RowBetween = styled(Flex)`
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

export const AlignTopBetween = styled(RowBetween)`
    align-items: flex-start;
`

export const AutoColumn = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: ${({ gap }) =>
        (gap === 'sm' && '8px') ||
        (gap === 'md' && '12px') ||
        (gap === 'xl' && '16px') ||
        (gap === 'lg' && '24px') ||
        gap};
    justify-items: ${({ justify }) => justify && justify};
`

export const Image = styled.img`
    cursor: pointer;
`

export const Icon = styled(Centered)`
    cursor: pointer;
    img {
        width: ${({ width }) => (width ? width : '15px')};
        height: ${({ height }) => (height ? height : '15px')};
    }
`

export const SmallBlock = styled.div`
    width: 50%;
    margin-left: ${({ margin }) => (margin ? margin : '10px')};

    &:first-child {
        margin-left: 0px;
    }
`

export const ErrorText = styled.p`
    color: ${({ theme }) => theme.error};
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    line-height: 17.5px;
`
