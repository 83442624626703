import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { useWallet } from '@solana/wallet-adapter-react'
import { ROUTES } from '../../../../constants/routes'
import { useModal } from '../../../../hooks/useModal'
import { ConnectWalletModal } from '../../../../components/Web3Status/ConnectWalletModal'
import { DesktopCentralBlock } from './DesktopCentralBlock'
import { MobileCentralBlock } from './MobileCentralBlock'

export const CentralBlock = () => {
    const { account } = useWeb3React()
    const { address } = useAccount()
    const { publicKey: adapterWalletAddress } = useWallet()
    const navigate = useNavigate()

    const { isOpen, closeModal, toggleModal } = useModal()

    const navigateToAllocation = () => navigate(ROUTES.allocations)

    const userAccount = account || address || adapterWalletAddress?.toString
    const buttonText = userAccount ? 'Allocation' : 'Connect Wallet'

    return (
        <>
            <DesktopCentralBlock
                account={userAccount}
                navigateToAllocation={navigateToAllocation}
                buttonText={buttonText}
                activateMetamask={toggleModal}
            />
            <MobileCentralBlock
                account={userAccount}
                navigateToAllocation={navigateToAllocation}
                buttonText={buttonText}
                activateMetamask={toggleModal}
            />
            <ConnectWalletModal isOpen={isOpen} closeModal={closeModal} />
        </>
    )
}
