export const formatTxData = (data) => {
    const validData = data?.split('event: update')
    if (!validData || !validData[1]) {
        return null
    }
    const transactionInfo = JSON.parse(validData[1]?.replace('data:', ''))
    if (!transactionInfo) {
        return null
    }
    transactionInfo[0]?.transaction

    return {
        tx: transactionInfo[0]?.transaction,
        info: transactionInfo[0],
    }
}
