import React, { useMemo } from 'react'
import styled from 'styled-components'
import { AutoColumn, RowBetween } from '../../../../components/styled'
import { ALLOCATION_PER_ONE_TICKET, ONE_KILO } from '../../../../constants'
import { toFixedAmount } from '../../../../utils/formatToFixes'
import { Tooltip } from '../../../../components/Tooltip'

const Wrapper = styled(RowBetween)`
    align-items: flex-end;
    margin-top: 64px;
    padding-left: 8px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`
const WrapperMobile = styled(RowBetween)`
    display: none;
    align-items: flex-end;
    margin-top: 72px;
    padding-left: 8px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const AllocationsTitle = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
`

const AllocationsTitleMobile = styled(AllocationsTitle)`
    font-size: 16px;
    line-height: 16px;
`

const AllocationsTextSmall = styled.p`
    color: ${({ theme }) => theme.primary};
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
`

const AllocationsTextSmallMobile = styled.p`
    color: ${({ theme }) => theme.primary};
    font-weight: 400;
    font-size: 13px;
    line-height: 16.9px;
`

const YourAllocationsText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
`

const YourAllocationsTextMobile = styled(YourAllocationsText)`
    font-size: 13px;
    line-height: 13px;
`

const YourAllocationsInfoText = styled(YourAllocationsText)`
    font-size: 15px;
    line-height: 15px;
`

const BottomInfoContainer = styled(AutoColumn)`
    width: 210px;
`

export const AllocationTitleComponent = ({ tickets, isSolana }) => {
    const displayedAllocation = useMemo(() => {
        if (!tickets || !Number(tickets))
            return { total: 0, allocation: 0, rawAllocation: 0 }

        let allocation
        let devidedAllocation

        if (isSolana) {
            allocation = tickets
            devidedAllocation = allocation / ONE_KILO
        } else {
            allocation = tickets * ALLOCATION_PER_ONE_TICKET
            devidedAllocation = allocation / ONE_KILO
        }

        return {
            total: tickets,
            allocation: devidedAllocation,
            rawAllocation: allocation,
        }
    }, [tickets])

    return (
        <>
            <Wrapper>
                <AutoColumn gap="4px">
                    <AllocationsTitle>
                        <span className="astro">Allocation</span>
                    </AllocationsTitle>
                    {!isSolana && (
                        <AllocationsTextSmall>
                            <span className="mono">
                                Buy $LAIKA and share your code to get additional
                                rewards.
                            </span>
                        </AllocationsTextSmall>
                    )}
                    {isSolana && (
                        <AllocationsTextSmall
                            style={{ width: '424px', height: '34px' }}
                        >
                            <span className="mono">
                                Buy $LAIKA and get additional rewards.
                            </span>
                        </AllocationsTextSmall>
                    )}
                </AutoColumn>
                <BottomInfoContainer gap="10px">
                    <YourAllocationsText>
                        <span className="mono">Your allocation</span>
                    </YourAllocationsText>
                    <YourAllocationsInfoText>
                        <Tooltip
                            dataFor="allocation"
                            dataTip={displayedAllocation.rawAllocation}
                            place="top"
                            marginLeft="8px"
                        >
                            <span className="astro">
                                {toFixedAmount(
                                    displayedAllocation.allocation,
                                    4,
                                )}
                                K $LAIKA
                            </span>
                        </Tooltip>
                    </YourAllocationsInfoText>
                </BottomInfoContainer>
            </Wrapper>
            <WrapperMobile>
                <AutoColumn gap="16px">
                    <AutoColumn gap="8px">
                        <AllocationsTitleMobile>
                            <span className="astro">Allocation</span>
                        </AllocationsTitleMobile>
                        <AllocationsTextSmallMobile>
                            <span className="mono">
                                Buy $LAIKA and share your code to get additional
                                rewards.
                            </span>
                        </AllocationsTextSmallMobile>
                    </AutoColumn>
                    <RowBetween>
                        <YourAllocationsTextMobile>
                            <span className="mono">Your allocation</span>
                        </YourAllocationsTextMobile>
                        <YourAllocationsTextMobile>
                            <Tooltip
                                dataFor="allocation"
                                dataTip={displayedAllocation.rawAllocation}
                                place="top"
                                marginLeft="8px"
                            >
                                <span className="astro">
                                    {toFixedAmount(
                                        displayedAllocation.allocation,
                                        4,
                                    )}
                                    K $LAIKA
                                </span>
                            </Tooltip>
                        </YourAllocationsTextMobile>
                    </RowBetween>
                </AutoColumn>
            </WrapperMobile>
        </>
    )
}
