import styled from 'styled-components'
import { Flex } from './styled'

export const AppWrapper = styled(Flex)`
    position: relative;
    width: 100vw;
    height: 100vh;
`

export const Background = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToMobile`
    display: none;
  `};
`

export const BackgroundMobile = styled(Background)`
    display: none;
    background-repeat: no-repeat;
    background-size: contain;
    ${({ theme }) => theme.mediaWidth.upToMobile`
    display: block;
  `};
`
