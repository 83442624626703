import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import ReceiveBg from '../../../assets/dex/dex-blocks/dex-you-receive-block.svg'
import ReceiveMobileBg from '../../../assets/mobile/dex/dex-receive-section-bg.svg'
import { AutoColumn, RowBetween } from '../../../components/styled'
import { useDexStore } from '../../../store/useDexStore'
import { toFixedAmount } from '../../../utils/formatToFixes'
import { useZerionStore } from '../../../store/useZerionStore'
import { SelectCurrency } from './SelectCurrency'

const Wrapper = styled.div`
    position: relative;
    width: 650px;
    height: 157px;
    margin-top: -30px;
    margin-left: -8px;
    padding: 16px 30px;
    background-image: ${(props) => `url(${props.imgUrl})`};

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled.div`
    display: none;
    position: relative;
    width: 286px;
    height: 164px;
    margin-top: -30px;
    margin-left: -8px;
    padding: 16px 20px;
    background-image: ${(props) => `url(${props.imgUrl})`};

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const TextSize = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const TextSizeMobile = styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 14.3px;
`

const DexAmountText = styled(TextSize)`
    color: ${({ theme }) => theme.primary};
`

const DexAmountTextMobile = styled(TextSizeMobile)`
    color: ${({ theme }) => theme.primary};
`

const DexBalanceText = styled(TextSize)`
    right: 30px;
    top: 50px;
    position: absolute;
    color: ${({ theme }) => theme.textGray};
    cursor: default;
`

const BalanceContainer = styled(AutoColumn)`
    right: 24px;
    top: 44px;
    position: absolute;
    text-align: right;
`

const DexBalanceTextMobile = styled(TextSizeMobile)`
    color: ${({ theme }) => theme.textGray};
    cursor: default;
`

const renderBalance = false

export const ReceiveSection = ({
    openModal,
    tokensList,
    oppositeTokensList,
}) => {
    const { receiveCurrencyId, receiveAmount, setReceiveAmount } = useDexStore()
    const { swapChain } = useZerionStore()

    const selectedCurrency = useMemo(() => {
        return tokensList?.find((item) => item.id === receiveCurrencyId)
    }, [receiveCurrencyId])

    const switchedCurrency = useMemo(() => {
        return oppositeTokensList?.find((item) => item.id === receiveCurrencyId)
    }, [receiveCurrencyId])

    const onOpenModal = useCallback(() => {
        if (!tokensList.length) {
            return
        }
        openModal()
    }, [tokensList])

    const fomattedReceiveAmount = useMemo(() => {
        if (!receiveAmount) return '0'
        const implementation =
            selectedCurrency?.attributes?.implementations?.find(
                (chain) => chain.chain_id === swapChain.zerionId,
            )
        if (implementation) {
            return toFixedAmount(
                ethers.utils.formatUnits(
                    receiveAmount,
                    implementation?.decimals,
                ),
                4,
            )
        } else {
            return '0'
        }
    }, [receiveAmount, selectedCurrency, swapChain])

    const usdAmount = useMemo(() => {
        if (!fomattedReceiveAmount) {
            return 0
        }
        if (!selectedCurrency?.attributes?.market_data?.price) {
            return 0
        }

        return (
            selectedCurrency?.attributes?.market_data?.price *
            parseFloat(fomattedReceiveAmount)
        )?.toFixed(2)
    }, [fomattedReceiveAmount, selectedCurrency])

    return (
        <>
            <Wrapper imgUrl={ReceiveBg}>
                {renderBalance && (
                    <>
                        <RowBetween>
                            <DexAmountText>
                                <span className="astro">You receive</span>
                            </DexAmountText>
                        </RowBetween>
                        <DexBalanceText>
                            <span className="mono">
                                Balance:{' '}
                                {
                                    selectedCurrency?.attributes?.quantity
                                        ?.numeric
                                }{' '}
                                {
                                    selectedCurrency?.attributes?.fungible_info
                                        ?.symbol
                                }
                            </span>
                        </DexBalanceText>
                    </>
                )}
                <SelectCurrency
                    isPay={true}
                    openModal={onOpenModal}
                    selectedId={receiveCurrencyId}
                    value={fomattedReceiveAmount}
                    setValue={setReceiveAmount}
                    selectedCurrency={selectedCurrency}
                    usdAmount={usdAmount}
                    switchedCurrency={switchedCurrency}
                />
            </Wrapper>

            <WrapperMobile imgUrl={ReceiveMobileBg}>
                <RowBetween>
                    <DexAmountTextMobile>
                        <span className="astro">You receive</span>
                    </DexAmountTextMobile>
                </RowBetween>
                {renderBalance && (
                    <BalanceContainer>
                        <DexBalanceTextMobile>
                            <span className="mono">Balance:</span>
                        </DexBalanceTextMobile>
                        <DexBalanceTextMobile>
                            <span className="mono">
                                {
                                    selectedCurrency?.attributes?.quantity
                                        ?.numeric
                                }{' '}
                                {
                                    selectedCurrency?.attributes?.fungible_info
                                        ?.symbol
                                }
                            </span>
                        </DexBalanceTextMobile>
                    </BalanceContainer>
                )}
                <SelectCurrency
                    isPay={true}
                    openModal={onOpenModal}
                    selectedId={receiveCurrencyId}
                    value={fomattedReceiveAmount}
                    setValue={setReceiveAmount}
                    selectedCurrency={selectedCurrency}
                    usdAmount={usdAmount}
                    switchedCurrency={switchedCurrency}
                />
            </WrapperMobile>
        </>
    )
}
