import { initializeConnector } from '@web3-react/core'
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { CHAIN_ID, PROJECT_ID_WALLET_CONNECT } from '../constants'

export const [walletConnect, walletConnectHooks] = initializeConnector(
    (actions) =>
        new WalletConnect({
            actions,
            timeout: 15000,
            options: {
                projectId: PROJECT_ID_WALLET_CONNECT,
                chains: [CHAIN_ID],
                optionalChains: [CHAIN_ID],
                showQrModal: true,
                appName: 'Laika',
            },
        }),
)
