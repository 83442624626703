export const SOLANA_RPC_HTTPS = process.env.REACT_APP_SOLANA_RPC_HTTPS
export const SOLANA_RPC_WSS = process.env.REACT_APP_SOLANA_RPC_WSS

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const USDC_DEV_ADDRESS = process.env.REACT_APP_USDC_DEV_ADDRESS

export const SOLANA_CONTRACT_ADDRESS =
    process.env.REACT_APP_SOLANA_CONTRACT_ADDRESS

export const SOLANA_USDT_DECIMALS = 1000000

export const USDC_PRICE = 16000
export const SOLANA_PRICE = 1760000

export const MINIMUM_TICKETS_PER_USER = 1
export const MAXIMUM_TICKETS_PER_USER = 100000
export const TOTAL_TICKETS_THRESHOLD = 1000000000
export const FINANCE_WALLET_ADDRESS =
    'AXPGB6tnzLQgwG66L21ZnhZFF23K4sQJukBFB3o8DT9V'

export const STATE_PUBLIC_KEY = process.env.REACT_APP_STATE_PUBLIC_KEY
