import React, { useMemo } from 'react'
import {
    ThemeProvider as StyledComponentsThemeProvider,
    createGlobalStyle,
    css,
} from 'styled-components'

const MEDIA_WIDTHS = {
    upToMobile: 480,
    upToTablet: 796,
}

// ${({ theme }) => theme.mediaWidth.upToMobile`
//     display: flex;
//   `};

const mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce(
    (accumulator, size) => {
        accumulator[size] = (a, b, c) => css`
            @media (max-width: ${MEDIA_WIDTHS[size]}px) {
                ${css(a, b, c)}
            }
        `
        return accumulator
    },
    {},
)

const white = '#FFFFFF'
const black = '#000000'

export function colors() {
    return {
        white,
        black,

        primary: '#2F353F',
        secondary: '#383838',
        textGray: '#8998A5',
        textGold: '#B7A231',
        error: '#EC3C51',
        pending: '#007DF1',
        success: '#06AC20',

        bgGray: '#E7E9F6',
        rangeBg: '#E1CC5E',
        rangeBgGray: '#E6E8F5',
    }
}

export function theme() {
    return {
        ...colors(),

        grids: {
            sm: 8,
            md: 12,
            lg: 24,
        },

        // media queries
        mediaWidth: mediaWidthTemplates,

        // css snippets
        flexColumnNoWrap: css`
            display: flex;
            flex-flow: column nowrap;
        `,
        flexRowNoWrap: css`
            display: flex;
            flex-flow: row nowrap;
        `,
    }
}

export default function ThemeProvider({ children }) {
    const themeObject = useMemo(() => theme(), [])

    return (
        <StyledComponentsThemeProvider theme={themeObject}>
            {children}
        </StyledComponentsThemeProvider>
    )
}

export const FixedGlobalStyle = createGlobalStyle`

html,
body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

button {  
  user-select: none;
}

html {
  font-size: 14px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
}
`

export const ThemedGlobalStyle = createGlobalStyle`
body {
  min-height: 100vh;
}
`
