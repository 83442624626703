import CommonImage from '../assets/nft/nft-common.png'
import RareImage from '../assets/nft/nft-rare.png'
import EpicImage from '../assets/nft/nft-epic.png'
import LegendaryImage from '../assets/nft/nft-ultimate.png'
import { investorStatus } from '../constants/perks'

export const getNFTImage = (status) => {
    if (status === investorStatus.UNCOMMON) {
        return CommonImage
    } else if (status === investorStatus.RARE) {
        return RareImage
    } else if (status === investorStatus.EPIC) {
        return EpicImage
    } else if (status === investorStatus.RARE) {
        return LegendaryImage
    }

    return ''
}
