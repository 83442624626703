import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import AllocationBg from '../../../../assets/blocks/allocations-block.svg'
import PerksBg from '../../../../assets/nft/layout-bg.svg'
import LoyaltyTabBg from '../../../../assets/blocks/loyalty-tab.svg'
import { NETWORKS, useNetworkStore } from '../../../../store/usseNetworkStore'
import { investorStatus } from '../../../../constants/perks'
import { PerksTitleComponent } from './PerksTitleComponent'
import { PerksRewards } from './PerksRewards'
import { PerksTickets } from './PerksTickets'

const PerksWrapper = styled.div`
    position: relative;
    padding: 0px 48px 0;
    margin-top: 84px;
    width: 736px;
    height: 646px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    background-size: cover;
    background-repeat: no-repeat;
    backdrop-filter: blur(10px);

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const TabText = styled.p`
    position: absolute;
    top: 30px;
    left: 380px;
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
`

const TabTextGray = styled(TabText)`
    top: 30px;
    left: 82px;
    color: ${({ theme }) => theme.textGray};
`

const TabTextGrayLoyalty = styled(TabTextGray)`
    left: 27px;
`

const AllocationTab = styled.div`
    position: absolute;
    top: 0;
    left: 0px;
    width: 212px;
    height: 49px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

const TabContainer = styled.div`
    position: absolute;
    top: 0;
    left: 212px;
    width: 190px;
    height: 49px;
    overflow: hidden;
`
const LoyaltyTab = styled.div`
    margin-left: -53px;
    width: 190px;
    height: 49px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

export const PerksComponent = ({
    openAllocation,
    disabledLoyalty,
    openLoyalty,
    rewardsInfo,
}) => {
    const { network } = useNetworkStore()

    const isSolana = useMemo(() => network === NETWORKS.solana, [network])

    const navigateToLoyalty = useCallback(() => {
        if (!disabledLoyalty) {
            openLoyalty()
        }
    }, [disabledLoyalty])

    const status = useMemo(() => {
        if (!rewardsInfo || !rewardsInfo?.status) {
            return investorStatus.UNCOMMON
        }

        return rewardsInfo.status
    }, [rewardsInfo])

    const tickets = useMemo(() => {
        if (isSolana) {
            if (!rewardsInfo) {
                return 0
            }
            const totalUsd = rewardsInfo?.totalUsd
            return Math.floor(Math.floor(totalUsd) / 10)
        } else {
            if (!rewardsInfo || !rewardsInfo?.totalAmount) {
                return 0
            }
            const totalUsd = parseInt(
                ethers.utils.formatUnits(rewardsInfo?.totalAmount, 18),
            )
            return Math.floor(Math.floor(totalUsd) / 10)
        }
    }, [rewardsInfo])

    const rewards = useMemo(() => {
        if (!rewardsInfo) {
            return {
                value: 0,
                total: 0,
            }
        }
        return {
            value: rewardsInfo?.distribution?.distributed?.toFixed(2),
            total: rewardsInfo?.distribution?.totalDistribution?.toFixed(2),
        }
    }, [rewardsInfo])

    return (
        <>
            <PerksWrapper imgUrl={PerksBg}>
                <AllocationTab imgUrl={AllocationBg} onClick={openAllocation}>
                    <TabTextGray>
                        <span className="astro">Allocation</span>
                    </TabTextGray>
                </AllocationTab>
                <TabContainer>
                    <LoyaltyTab
                        imgUrl={LoyaltyTabBg}
                        onClick={navigateToLoyalty}
                        style={{
                            opacity: disabledLoyalty || isSolana ? 0.4 : 1,
                            pointerEvents:
                                disabledLoyalty || isSolana ? 'none' : 'unset',
                        }}
                    >
                        <TabTextGrayLoyalty>
                            <span className="astro">Loyalty</span>
                        </TabTextGrayLoyalty>
                    </LoyaltyTab>
                </TabContainer>
                <TabText>
                    <span className="astro">Laika Perks</span>
                </TabText>
                <PerksTitleComponent status={status} />
                <PerksRewards rewards={rewards} />
                <PerksTickets tickets={tickets} />
            </PerksWrapper>
        </>
    )
}
