import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { WalletButton } from '@rainbow-me/rainbowkit'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { stopPropagation } from '../../utils/stopPropagation'
import MetamaskIcon from '../../assets/img/metamask.png'
import WalletConnectIcon from '../../assets/img/walletConnectIcon.svg'
import PhantomIcon from '../../assets/img/phantom.png'
import { Modal } from '../Modal'
import { Centered, FlexAlign, FlexColumn } from '../styled'
import { metaMask } from '../../connectors/metamask'
import { config } from '../../connectors/config'
import { walletConnect } from '../../connectors/walletConnect'
import { CHAIN_ID } from '../../constants'
import { useErrorStore } from '../../store/useErrorStore'
import { ROUTES } from '../../constants/routes'
import { wallets } from '../../connectors/rainbows'
import { NETWORKS, useNetworkStore } from '../../store/usseNetworkStore'
import { getProvider } from '../../utils/phantom'
import { useNotificationsContext } from '../../context/Notificationscontext'

const WalletModal = styled(FlexColumn)`
    padding: 20px;
    width: 400px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 20px;
    overflow: hidden;
`

const WalletTitle = styled.h3`
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.primary};
`

const HideFormobile = styled.div`
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const ShowForMobile = styled.div`
    display: none;
    width: 100%;
    background-color: ${({ theme }) => theme.bgGray};
    border-radius: 10px;
    margin-top: 10px;
    height: 68px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const WalletRow = styled(FlexAlign)`
    margin-top: 10px;
    width: 100%;
    padding: 20px 20px;
    background-color: ${({ theme }) => theme.bgGray};
    cursor: pointer;
    border-radius: 10px;
    p {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
        color: ${({ theme }) => theme.primary};
    }
    &:hover {
        opacity: 0.8;
    }
`

const WalletRowImage = styled(Centered)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.white};
    img {
        width: 18px;
        height: 18px;
    }
`

export const ConnectWalletModal = ({ isOpen, closeModal, isDex }) => {
    const { showErrorNotification } = useNotificationsContext()
    const { setError, unsetError } = useErrorStore()
    const { setNetwork } = useNetworkStore()
    const phantom = getProvider()

    const navigate = useNavigate()

    const setConnection = (provider, network) => {
        sessionStorage.setItem('connected', 'true')
        sessionStorage.setItem('provider', provider)
        setNetwork(network)
        sessionStorage.setItem('network', network)
    }

    const navigateToAllocation = () => navigate(ROUTES.allocations)
    const activateWalletConnect = async () => {
        closeModal()
        unsetError()
        try {
            await walletConnect
                .activate(CHAIN_ID)
                .then(() => {
                    setConnection('wallet-connect', NETWORKS.bnb)
                    if (!isDex) {
                        navigateToAllocation()
                    }
                })
                .catch(() => {
                    sessionStorage.removeItem('connected')
                    sessionStorage.removeItem('provider')
                    setError()
                })
        } catch (err) {
            console.error(err)
        }
    }

    const activateMetamask = async () => {
        closeModal()
        unsetError()
        await metaMask
            .activate(config)
            .then(() => {
                setConnection('metamask', NETWORKS.bnb)
                if (!isDex) {
                    navigateToAllocation()
                }
            })
            .catch(() => {
                setError()
                sessionStorage.removeItem('connected')
                sessionStorage.removeItem('provider')
            })
    }

    const activatePhantom = async (connect) => {
        if (phantom) {
            closeModal()
            await connect()
            setConnection('phantom', NETWORKS.solana)
            navigateToAllocation()
        } else {
            showErrorNotification('Please install phantom')
            window.open('https://phantom.app/', '_blank')
        }
    }

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} maxWidth={'400px'}>
            <WalletModal onClick={stopPropagation}>
                <WalletTitle>
                    <span className="astro">Connect Wallet</span>
                </WalletTitle>

                <WalletRow onClick={activateMetamask}>
                    <WalletRowImage>
                        <img src={MetamaskIcon} alt="" />
                    </WalletRowImage>
                    <p>
                        <span className="astro">Metamask</span>
                    </p>
                </WalletRow>
                <WalletRow onClick={activateWalletConnect}>
                    <WalletRowImage>
                        <img src={WalletConnectIcon} alt="" />
                    </WalletRowImage>
                    <p>
                        <span className="astro">WalletConnect</span>
                    </p>
                </WalletRow>
                {!isDex && (
                    <HideFormobile>
                        <WalletButton.Custom wallet={wallets[0].id}>
                            {({ connect }) => {
                                return (
                                    <WalletRow
                                        onClick={() => activatePhantom(connect)}
                                    >
                                        <WalletRowImage>
                                            <img src={PhantomIcon} alt="" />
                                        </WalletRowImage>
                                        <p>
                                            <span className="astro">
                                                Phantom
                                            </span>
                                        </p>
                                    </WalletRow>
                                )
                            }}
                        </WalletButton.Custom>
                    </HideFormobile>
                )}
                <ShowForMobile>
                    <WalletMultiButton
                        style={{
                            background: 'transparent',
                            width: '100%',
                            padding: 0,
                            height: 'fit-content',
                            minWidth: '320px',
                        }}
                    >
                        <WalletRow style={{ marginTop: 0 }}>
                            <WalletRowImage>
                                <img src={PhantomIcon} alt="" />
                            </WalletRowImage>
                            <p>
                                <span className="astro">Phantom</span>
                            </p>
                        </WalletRow>
                    </WalletMultiButton>
                </ShowForMobile>
            </WalletModal>
        </Modal>
    )
}
