import styled from 'styled-components'
import { Centered, FlexAlign, RowBetween } from '../styled'

export const WalletWrapper = styled(Centered)`
    margin-left: -14px;
    width: 100%;
    max-width: 211px;
    position: relative;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

export const AccountText = styled.p`
    color: ${({ theme }) => theme.black};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`

export const AccountTextWrapper = styled(RowBetween)`
    margin-top: -20px;
    max-width: 135px;
    cursor: pointer;
`

export const DropdownWrapper = styled.div`
    position: absolute;
    top: 74px;
    left: ${({ mobile }) => (mobile === 'true' ? 'unset' : '-140px')};
    right: ${({ mobile }) => (mobile === 'true' ? '10px' : 'unset')};
    width: 356px;
    height: 261px;
    padding-inline: 24px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    backdrop-filter: ${({ mobile }) =>
        mobile === 'true' ? 'blur(3px)' : 'blur(10px)'};
`

export const NetworkIcon = styled.div`
    width: 26px;
    height: 26px;
    img {
        width: 100%;
        height: 100%;
    }
`

export const NetworkText = styled.p`
    margin-left: 8px;
    color: ${({ theme }) => theme.primary};
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
`

export const NetworkBlock = styled(FlexAlign)`
    width: fit-content;
    margin-top: 41px;
`

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.textGray};
`

export const InfoTextTitle = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 22.24px;
`

export const InfoTextInfo = styled.p`
    text-align: right;
    color: ${({ theme }) => theme.primary};
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
`

export const AccountCopySection = styled(FlexAlign)`
    width: fit-content;
`

export const CopyBlock = styled.div`
    margin-top: -4px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
    }
`

export const DisconnectBlock = styled(RowBetween)`
    position: absolute;
    top: 197px;
    right: 7px;
    width: 246px;
    height: 56px;
    padding-left: 24px;
    padding-right: 20px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

export const DisconnectText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 15px;
    font-weight: 400;
    line-height: 19.2px;
`

export const ArrowRightBlock = styled.div`
    width: 15px;
    height: 16px;
    img {
        width: 100%;
        height: 100%;
    }
`
