import { create, useStore } from 'zustand'

export const tasksStore = create((set, get) => ({
    totalRewards: 0,
    timeToNextReward: 0,
    streak: 0,
    claimedToday: true,
    history: null,
    reset: () =>
        set({
            totalRewards: 0,
            timeToNextReward: 0,
            streak: 0,
            claimedToday: true,
            history: null,
        }),
}))

export const useTasksStore = () => useStore(tasksStore)
