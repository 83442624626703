import styled from 'styled-components'
import {
    AutoColumn,
    FlexColumn,
    RowBetween,
} from '../../../../components/styled'

export const CentralBlockWrapper = styled.div`
    margin-top: 96px;
    position: relative;
    width: 100%;
    height: 456px;
    max-width: 745px;
    margin-left: 33px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    backdrop-filter: blur(6px);

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

export const CentralBlockMobileWrapper = styled.div`
    display: none;
    margin: 119px auto 0;
    position: relative;
    width: 100%;
    height: 372px;
    max-width: 303px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    //backdrop-filter: blur(6px);

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

export const TitleBlock = styled.div`
    margin-top: 48px;
    margin-left: 92px;
    width: 100%;
    max-width: 563px;
`
export const TitleBlockMobile = styled.div`
    margin-top: 16px;
    margin-left: 54px;
    width: 100%;
    max-width: 177px;
`

export const CentralBlockTitle = styled.h3`
    color: ${({ theme }) => theme.primary};
    font-size: 70px;
    font-weight: 400;
    line-height: 75.6px;
`

export const CentralBlockMobileTitle = styled.h3`
    color: ${({ theme }) => theme.primary};

    font-size: 22px;
    font-weight: 400;
    line-height: 23.76px;
`

export const SquareBlock = styled.img`
    position: absolute;
    top: 42px;
    right: 50px;
    width: 31px;
    height: 31px;
`

export const SquareBlockMobile = styled.img`
    position: absolute;
    top: 21px;
    right: 21px;
    width: 19px;
    height: 19px;
`

export const GBlock = styled.img`
    width: 47px;
    height: 28px;
`

export const GBlockMobile = styled.img`
    width: 25px;
    height: 20px;
`
export const PGBlock = styled.img`
    margin-top: 16px;
    width: 43px;
    height: 27px;
`
export const PGBlockMobile = styled.img`
    margin-top: 11px;
    width: 31px;
    height: 19px;
`

export const RecycleBlock = styled.img`
    margin-top: 16px;
    width: 33px;
    height: 33px;
`
export const RecycleBlockMobile = styled.img`
    margin-top: 11px;
    width: 24px;
    height: 24px;
`

export const GlobeBlock = styled.img`
    width: 29px;
    height: 29px;
`

export const GlobeBlockMobile = styled.img`
    width: 21px;
    height: 21px;
`

export const SCodeBlock = styled.img`
    width: 89px;
    height: 31px;
`

export const SCodeBlockMobile = styled.img`
    width: 60px;
    height: 21px;
`

export const QRCodeBlock = styled.img`
    width: 31px;
    height: 31px;
`
export const QRCodeBlockMobile = styled.img`
    width: 21px;
    height: 21px;
`

export const LeftBlocksContainer = styled(FlexColumn)`
    position: absolute;
    top: 194px;
    left: 9px;
`

export const LeftBlocksContainerMobile = styled(FlexColumn)`
    position: absolute;
    top: 97px;
    left: 10px;
`

export const BottomBlocksContainer = styled(RowBetween)`
    position: absolute;
    height: 31px;
    width: 178px;
    bottom: 20px;
    left: 96px;
`
export const BottomBlocksContainerMobile = styled(RowBetween)`
    position: absolute;
    height: 31px;
    width: 118px;
    bottom: 16px;
    left: 54px;
`

export const CentralBlockTextContainer = styled(AutoColumn)`
    position: absolute;
    right: 52px;
    top: 246px;
    width: 100%;
    max-width: 492px;
`

export const DividerBlock = styled.div`
    width: 100%;
    max-width: 492px;
    height: 6px;
    img {
        width: 100%;
        height: 100%;
    }
`

export const DividerBlockMobile = styled.div`
    margin-top: 16px;
    margin-left: 54px;
    width: 100%;
    max-width: 217px;
    height: 6px;
    img {
        width: 100%;
        height: 100%;
    }
`

export const CentralBlockParagraph = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 14px;
    font-weight: 400;
    line-height: 19.46px;
`

export const CentralBlockMobileParagraph = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 13px;
    font-weight: 400;
    line-height: 18.07px;
`

export const ConnectWalletBlock = styled(RowBetween)`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 415px;
    height: 62px;
    padding-left: 48px;
    padding-right: 28px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

export const ConnectWalletBlockMobile = styled(RowBetween)`
    position: absolute;
    bottom: -76px;
    right: 0;
    width: 100%;
    max-width: 300px;
    height: 62px;
    padding-left: 48px;
    padding-right: 28px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

export const CentralBlockArrowRight = styled.div`
    width: 29px;
    height: 29px;
    img {
        width: 100%;
        height: 100%;
    }
`

export const CentralBlockArrowRightMobile = styled.div`
    width: 17px;
    height: 17px;
    img {
        width: 100%;
        height: 100%;
    }
`
export const CentralBlockConnectText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 21px;
    font-weight: 400;
    line-height: 23.1px;
`
export const CentralBlockConnectTextMobile = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 19.8px;
`
