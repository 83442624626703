import React from 'react'
import styled from 'styled-components'
import TimerCell from '../../../assets/tasks/timer_cell.png'
import { useTimeDifference } from '../../../hooks/useTimeDifference'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const Cell = styled.div`
    background-image: ${(props) => `url(${props.imgUrl})`};
    width: 33%;
    height: 150px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 796px) {
        height: 50px;
        background-image: none;
    }
`

const Count = styled.p`
    color: ${({ theme }) => theme.rangeBg};
    font-size: 60px;
    @media (max-width: 796px) {
        font-size: 38px;
    }
`

const Label = styled.p`
    font-size: 16px;
    @media (max-width: 796px) {
        font-size: 14px;
        line-height: 14px;
    }
`

export const TaskTimer = ({ milliseconds }) => {
    const { hours, minutes, seconds } = useTimeDifference(milliseconds, true)
    return (
        <Wrapper>
            <Cell imgUrl={TimerCell}>
                <Count className="astro">{hours}</Count>
                <Label className="astro">hours</Label>
            </Cell>
            <Cell imgUrl={TimerCell}>
                <Count className="astro">{minutes}</Count>
                <Label className="astro">minutes</Label>
            </Cell>
            <Cell imgUrl={TimerCell}>
                <Count className="astro">{seconds}</Count>
                <Label className="astro">seconds</Label>
            </Cell>
        </Wrapper>
    )
}
