import React from 'react'
import styled from 'styled-components'

import { Flex } from '../../../components/styled'
import { TasksLeftInfo } from './TasksLeftInfo'
import { TasksRightInfo } from './TasksRightInfo'

const Container = styled(Flex)`
    width: 100%;
    justify-content: center;
    @media (max-width: 1492px) {
        flex-direction: column;
        align-items: center;
    }
`

export const TasksGeneralInfo = () => {
    return (
        <Container>
            <TasksLeftInfo />
            <TasksRightInfo />
        </Container>
    )
}
