import React from 'react'
import styled from 'styled-components'
import BuyButtonBlock from '../../../../assets/blocks/buy-button.svg'
import ArrowRightIcon from '../../../../assets/svg/arrow-right.svg'
import BuyBlockMobileBg from '../../../../assets/mobile/blocks/button-bg.svg'
import { RowBetween } from '../../../../components/styled'

const BuyBlock = styled(RowBetween)`
    position: absolute;
    bottom: 56px;
    right: 40px;
    width: 100%;
    max-width: 292px;
    height: 63px;
    padding-left: 40px;
    padding-right: 32px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    z-index: 2;

    &:hover {
        opacity: 0.8;
    }

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const BuyBlockMobile = styled(BuyBlock)`
    display: none;
    position: absolute;
    bottom: -29px;
    right: 12px;
    max-width: 300px;
    height: 62px;
    padding-left: 32px;
    padding-right: 32px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const AllocationsArrowRight = styled.div`
    width: 15px;
    height: 15px;
    img {
        width: 100%;
        height: 100%;
    }
`

const AllocationsArrowRightMobile = styled(AllocationsArrowRight)`
    width: 17px;
    height: 17px;
`

const BuyText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 23.04px;
`

const BuyTextMobile = styled(BuyText)`
    line-height: 19.8px;
`

export const BuyBlockComponent = ({ onClick, disabled }) => {
    return (
        <>
            <BuyBlock
                imgUrl={BuyButtonBlock}
                onClick={disabled ? null : onClick}
                style={
                    disabled
                        ? {
                              opacity: 0.3,
                              pointerEvents: disabled ? 'none' : 'unset',
                          }
                        : {}
                }
            >
                <BuyText>
                    <span className="astro">Buy</span>
                </BuyText>
                <AllocationsArrowRight>
                    <img src={ArrowRightIcon} alt="" />
                </AllocationsArrowRight>
            </BuyBlock>
            <BuyBlockMobile
                imgUrl={BuyBlockMobileBg}
                onClick={disabled ? null : onClick}
                style={
                    disabled
                        ? {
                              opacity: 0.3,
                              pointerEvents: disabled ? 'none' : 'unset',
                          }
                        : {}
                }
            >
                <BuyTextMobile>
                    <span className="astro">Buy</span>
                </BuyTextMobile>
                <AllocationsArrowRightMobile>
                    <img src={ArrowRightIcon} alt="" />
                </AllocationsArrowRightMobile>
            </BuyBlockMobile>
        </>
    )
}
