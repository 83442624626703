import React from 'react'
import styled from 'styled-components'
import BackgroundImg from '../../assets/img/background.svg'
import Laika from '../../assets/img/laika-dog.png'
import MobileBg from '../../assets/img/mobile-bg.png'
import BottomIcon from '../../assets/img/bottom-left.png'
import { PageWrapper } from '../../components/PageWrapper'
import {
    AppWrapper,
    Background,
    BackgroundMobile,
} from '../../components/common'
import { CentralBlock } from './components/CentralBlock'

const LaikaImg = styled.div`
    width: 866px;
    height: 866px;
    position: absolute;
    right: 60px;
    bottom: 0;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
`

const BottomImg = styled.div`
    width: 523px;
    height: 666px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
`

export function Dashboard() {
    return (
        <AppWrapper>
            <PageWrapper center={false}>
                <CentralBlock />
            </PageWrapper>
            <Background imgUrl={BackgroundImg}>
                <LaikaImg>
                    <img src={Laika} alt="" />
                </LaikaImg>
                <BottomImg>
                    <img src={BottomIcon} alt="" />
                </BottomImg>
            </Background>
            <BackgroundMobile imgUrl={MobileBg} />
        </AppWrapper>
    )
}
