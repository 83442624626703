export const IS_MAINNET = process.env.REACT_APP_ENVIRONMENT === 'mainnet'
export const PROJECT_ID_WALLET_CONNECT = 'a6cc11517a10f6f12953fd67b1eb67e7'
export const NETWORK_RPC = IS_MAINNET
    ? 'https://bsc-dataseed.binance.org/'
    : 'https://bsc-testnet.publicnode.com'
export const CHAIN_NAME = IS_MAINNET
    ? 'BNB Smart Chain'
    : 'Binance Smart Chain Testnet'
export const CHAIN_ID = IS_MAINNET ? 56 : 97
export const NATIVE_CURRENCY = IS_MAINNET
    ? { name: 'BNB', symbol: 'BNB', decimals: 18 }
    : {
          name: 'tBNB',
          symbol: 'tBNB',
          decimals: 18,
      }
export const EXPLORER_URL = IS_MAINNET
    ? 'https://bscscan.com/'
    : 'https://testnet.bscscan.com/'
export const CONTRACT_ADDRESS = IS_MAINNET
    ? '0x59b3d9614269C5264d112271249478eaE20fDDBb'
    : '0x092C555f6190D947BAB1eEF986BF9F37Fc505f5b'
export const USDT_ADDRESS = IS_MAINNET
    ? '0x55d398326f99059fF775485246999027B3197955'
    : '0x2c044BF16C420803f6CCD0d490ebF9f01898D6E4'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const MULTICALL_CONTRACT_ADDRESS = IS_MAINNET
    ? '0xcA11bde05977b3631167028862bE2a173976CA11'
    : '0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042'

export const ALLOCATION_PER_ONE_TICKET = 20000
export const ONE_MILLION = 1000000
export const ONE_KILO = 1000

export const ETHEREUM_RPC = 'https://ethereum.publicnode.com'
export const ETHEREUM_CHAIN_ID = 1
export const ETHEREUM_NATIVE_CURRENCY = {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
}
export const ETHEREUM_CHAIN_NAME = 'Ethereum Mainnet'
export const ETHEREUM_EXPLORER = 'https://etherscan.io'
