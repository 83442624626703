import { Contract } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { ERC20_ABI } from '../constants/abi/erc20abi'

export function useDexToken() {
    const { provider } = useWeb3React()

    // const tokenContract = new Contract(
    //     tokenAddress,
    //     ERC20_ABI,
    //     new JsonRpcProvider(chainRpc),
    // )
    //
    // const getTokenInfo = useCallback(async () => {
    //     try {
    //         const rawBalance = await tokenContract.balanceOf(account)
    //         const decimals = await tokenContract.decimals()
    //         const symbol = await tokenContract.symbol()
    //         const balance = ethers.utils.formatUnits(rawBalance, decimals)
    //         const iconUrl = `https://token-icons.s3.amazonaws.com/${tokenAddress}.png`
    //
    //         return {
    //             balance,
    //             symbol,
    //             iconUrl,
    //         }
    //     } catch (err) {
    //         console.error(err)
    //     }
    // }, [account, tokenContract])

    const approveToken = useCallback(
        async (tokenAddress, amount, toAddress) => {
            const token = new Contract(
                tokenAddress,
                ERC20_ABI,
                provider.getSigner(),
            )

            try {
                const tx = await token.approve(toAddress, amount)
                await tx.wait()
            } catch (err) {
                throw err
            }
        },
        [provider],
    )

    return { approveToken }
}
