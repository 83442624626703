import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useAccount } from 'wagmi'
import { useWallet } from '@solana/wallet-adapter-react'
import { Flex } from '../../../components/styled'
import { useBuyContract } from '../../../hooks/useBuyContract'
import { USDT_ADDRESS } from '../../../constants'
import { useRefetchStore } from '../../../store/useRefetchStore'
import { useReferralStore } from '../../../store/useReferralStore'
import { useMulticall } from '../../../hooks/useMulticall'
import { NETWORKS, useNetworkStore } from '../../../store/usseNetworkStore'
import { getProvider } from '../../../utils/phantom'
import { solanaService } from '../../../api/service'
import { LoyaltyComponent } from './loyalty/LoyaltyComponent'
import { AllocationComponent } from './allocation/AllocationComponent'
import { PerksComponent } from './perks/PerksComponent'

const Container = styled(Flex)`
    //flex: 1;
    justify-content: center;
    ${({ theme }) => theme.mediaWidth.upToTablet`
     order: 2;
     margin-bottom: 100px;
  `};
`

const TABS = {
    allocation: 'allocation',
    loyalty: 'loyalty',
    perks: 'perks',
}

export const AllocationsForm = () => {
    const timer = useRef()
    const { account } = useWeb3React()
    const { refetchTrigger } = useRefetchStore()
    const { setDepth, setReferralLength, setInvites } = useReferralStore()
    const { network } = useNetworkStore()
    const { address: phantomAddress } = useAccount()
    const { publicKey: adapterWalletAddress } = useWallet()
    const {
        addressToRefCode,
        userRewards,
        getTicketsData,
        getDepth,
        getReferralsLength,
    } = useBuyContract()
    const { getInvites } = useMulticall()
    const [selectedTab, setSelectedTab] = useState(TABS.allocation)
    const [myRefCode, setMyRefCode] = useState('')
    const [rewards, setRewards] = useState({})
    const [ticketsInfo, setTicketsInfo] = useState(null)
    const [solanaRewards, setSolanaRewards] = useState(null)
    const [bnbRewards, setBNBRewards] = useState(null)

    const isSolana = network === NETWORKS.solana
    const openAllocation = () => setSelectedTab(TABS.allocation)

    const openLoyalty = () => setSelectedTab(TABS.loyalty)
    const openPerks = () => setSelectedTab(TABS.perks)

    const getRewards = async (address) => {
        if (!address) {
            return
        }
        const myRewards = await userRewards(address, USDT_ADDRESS)
        if (myRewards) {
            setRewards(myRewards)
        }
    }

    const getRef = async (address) => {
        const data = await addressToRefCode(address)
        if (data) {
            setMyRefCode(data)
        }
    }

    const fetchTicketsInfo = async () => {
        const data = await getTicketsData()
        if (data) {
            setTicketsInfo(data)
        }
    }

    const getRefInfo = async (address) => {
        const depthValue = await getDepth()
        const refLength = await getReferralsLength(address)
        if (depthValue) {
            setDepth(parseInt(depthValue.toString()))
        }
        if (refLength) {
            const length = parseInt(refLength.toString())
            setReferralLength(length)
            const invitesValue = await getInvites(address, length)
            if (invitesValue) {
                setInvites(invitesValue)
            }
        }
    }

    const fetchRewards = useCallback(async () => {
        if (network === NETWORKS.solana) {
            if (phantomAddress || adapterWalletAddress) {
                setTimeout(async () => {
                    const provider = getProvider()
                    const data = await solanaService.getRewards(
                        provider.publicKey.toString(),
                    )
                    setSolanaRewards(data?.investor)
                }, 500)
            }
        } else if (network === NETWORKS.bnb) {
            if (account) {
                const data = await solanaService.getRewards(account, 'bnb')
                setBNBRewards(data?.investor)
            }
        }
    }, [phantomAddress, network, account, adapterWalletAddress])

    useEffect(() => {
        if (!account) return
        getRef(account)
        getRewards(account)
        fetchTicketsInfo()
    }, [account, refetchTrigger])

    useEffect(() => {
        if (!account) {
            return
        }
        getRefInfo(account)
    }, [account])

    useEffect(() => {
        timer.current = setInterval(async () => {
            await fetchRewards()
        }, 30000)

        fetchRewards()

        return () => clearInterval(timer.current)
    }, [fetchRewards])

    const isAllocation = selectedTab === TABS.allocation
    const isLoyalty = selectedTab === TABS.loyalty
    const isPerks = selectedTab === TABS.perks

    return (
        <Container>
            {((isSolana && !isPerks) || isAllocation) && (
                <AllocationComponent
                    openLoyalty={openLoyalty}
                    disabledLoyalty={false}
                    ticketsInfo={ticketsInfo}
                    openPerks={openPerks}
                />
            )}
            {!isSolana && isLoyalty && (
                <>
                    {!isSolana && (
                        <LoyaltyComponent
                            openAllocation={openAllocation}
                            myRefCode={myRefCode}
                            rewards={rewards}
                            ticketsInfo={ticketsInfo}
                            openPerks={openPerks}
                            disabledLoyalty={false}
                        />
                    )}
                </>
            )}
            {isPerks && (
                <PerksComponent
                    openAllocation={openAllocation}
                    openLoyalty={openLoyalty}
                    disabledLoyalty={false}
                    rewardsInfo={isSolana ? solanaRewards : bnbRewards}
                />
            )}
        </Container>
    )
}
