import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { RowBetween } from '../../../components/styled'
import ButtonBg from '../../../assets/dex/dex-blocks/dex-button.svg'
import ButtonMobileBg from '../../../assets/mobile/dex/dex-button.svg'
import ButtonDisabledBg from '../../../assets/dex/dex-blocks/dex-disabled-button.svg'
import ButtonDisabledMobileBg from '../../../assets/mobile/dex/dex-button-disabled.svg'
import ArrowRightIcon from '../../../assets/svg/arrow-right.svg'
import { useModal } from '../../../hooks/useModal'
import { ConnectWalletModal } from '../../../components/Web3Status/ConnectWalletModal'

const Wrapper = styled(RowBetween)`
    position: absolute;
    bottom: 46px;
    right: 40px;
    width: 100%;
    max-width: 292px;
    height: 63px;
    padding-left: 40px;
    padding-right: 32px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    z-index: 2;

    &:hover {
        opacity: 0.8;
    }

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(RowBetween)`
    display: none;
    position: absolute;
    bottom: -42px;
    right: 10px;
    width: 300px;
    max-width: 430px;
    height: 62px;
    padding-left: 40px;
    padding-right: 32px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    z-index: 2;

    &:hover {
        opacity: 0.8;
    }

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const ButtonArrow = styled.div`
    width: 15px;
    height: 15px;
    img {
        width: 100%;
        height: 100%;
    }
`

const BuyText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 18px;
    font-weight: 400;
    line-height: 23.04px;
`

const BuyTextMobile = styled(BuyText)`
    line-height: 19.8px;
`

export const SwapButton = ({ disabled, onClick, isLoading }) => {
    const { account } = useWeb3React()

    const {
        isOpen: isWalletModalOpen,
        toggleModal: toggleWalletModal,
        closeModal: closeWalletModal,
    } = useModal()

    const handleClick = useCallback(() => {
        if (!account) {
            toggleWalletModal()
        } else {
            onClick()
        }
    }, [account, onClick])

    const buttonText = account ? 'SWAP' : 'CONNECT WALLET'

    return (
        <>
            <Wrapper
                imgUrl={account && disabled ? ButtonDisabledBg : ButtonBg}
                onClick={handleClick}
                style={
                    account
                        ? { pointerEvents: disabled ? 'none' : 'unset' }
                        : {}
                }
            >
                {!isLoading && (
                    <BuyText>
                        <span className="astro">{buttonText}</span>
                    </BuyText>
                )}
                {!isLoading && (
                    <ButtonArrow>
                        <img src={ArrowRightIcon} alt="" />
                    </ButtonArrow>
                )}
                {isLoading && (
                    <div className="loader" style={{ margin: ' 0 auto' }}></div>
                )}
            </Wrapper>

            <WrapperMobile
                imgUrl={
                    account && disabled
                        ? ButtonDisabledMobileBg
                        : ButtonMobileBg
                }
                onClick={handleClick}
                style={
                    account
                        ? { pointerEvents: disabled ? 'none' : 'unset' }
                        : {}
                }
            >
                <BuyTextMobile>
                    <span className="astro">{buttonText}</span>
                </BuyTextMobile>
                <ButtonArrow>
                    <img src={ArrowRightIcon} alt="" />
                </ButtonArrow>
            </WrapperMobile>
            {isWalletModalOpen && (
                <ConnectWalletModal
                    isOpen={isWalletModalOpen}
                    closeModal={closeWalletModal}
                    isDex={true}
                />
            )}
        </>
    )
}
