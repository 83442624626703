import { create } from 'zustand'

export const useReferralStore = create((set) => ({
    depth: 1,
    referralLength: 0,
    invites: 0,
    setDepth: (value) => set({ depth: value }),
    setReferralLength: (value) => set({ depth: value }),
    setInvites: (value) => set({ invites: value }),
}))
