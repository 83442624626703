import CommonImage from '../assets/nft/nft-common.png'
import RareImage from '../assets/nft/nft-rare.png'
import EpicImage from '../assets/nft/nft-epic.png'
import LegendaryImage from '../assets/nft/nft-ultimate.png'

export const investorStatus = {
    COMMON: 'Common',
    UNCOMMON: 'Uncommon',
    RARE: 'Rare',
    EPIC: 'Epic',
    LEGENDARY: 'Legendary',
}

export const perksNFTsList = [CommonImage, RareImage, EpicImage, LegendaryImage]
