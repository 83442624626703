import { create } from 'zustand'

export const initialState = {
    solana: 0,
    usdt: 0,
}

export const useSolanaStore = create((set) => ({
    prices: initialState,
    totalTickets: 0,
    totalTicketsThreshHold: 0,
    maxTicketsPerUser: 0,
    setPrices: (newPrices) => {
        set({ prices: newPrices })
    },
    setSolanaInfo: (
        totalTickets,
        totalTicketsThreshHold,
        maxTicketsPerUser,
    ) => {
        set({ totalTickets, totalTicketsThreshHold, maxTicketsPerUser })
    },
}))
