import React from 'react'
import styled from 'styled-components'
import {
    AutoColumn,
    Flex,
    FlexAlign,
    RowBetween,
} from '../../../components/styled'
import PayButtonBg from '../../../assets/dex/dex-blocks/dex-pay-currency.svg'
import PayButtonMobileBg from '../../../assets/mobile/dex/dex-select-button-bg.svg'
import DropdownArrow from '../../../assets/dex/open-dropdown.svg'
import { inputRegexp } from '../../../constants/regExp'
import { escapeRegExp } from '../../../utils/escapeRegExp'
import EthIcon from '../../../assets/svg/question-mark.svg'

const Wrapper = styled(Flex)`
    width: 100%;
    margin-top: ${({ mt }) => mt || '20px'};
    margin-left: -16px;
    height: fit-content;
    gap: 16px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(FlexAlign)`
    display: none;
    width: 100%;
    margin-top: ${({ mt }) => mt || '20px'};
    margin-left: -16px;
    height: fit-content;
    gap: 16px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const SelectButton = styled(RowBetween)`
    width: 100%;
    max-width: 164px;
    height: 76px;
    padding-inline: 24px;
    padding-bottom: 6px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

const SelectButtonMobile = styled(RowBetween)`
    position: relative;
    width: 100%;
    max-width: 143px;
    height: 70px;
    padding-inline: 24px;
    padding-bottom: 6px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

const ArrowImg = styled.img`
    position: absolute;
    top: 26px;
    right: 10px;
`

const CurrencyIcon = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
    }
`

const CurrencyIconMobile = styled(CurrencyIcon)`
    width: 24px;
    height: 24px;
`

const CurrencySymbol = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.secondary};
`

const CurrencySymbolMobile = styled(CurrencySymbol)`
    font-size: 13px;
    line-height: 13px;
`

const StyledFlexAlign = styled(FlexAlign)`
    gap: 8px;
`

const Container = styled(AutoColumn)`
    gap: 1px;
    margin-top: -10px;
    height: fit-content;
`

const ContainerMobile = styled(AutoColumn)`
    gap: 4px;
    width: fit-content;
    height: fit-content;
`

const InputContainer = styled.div`
    width: fit-content;
    height: fit-content;
`

const DollarAmount = styled.p`
    margin-top: 13px;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
    color: ${({ theme }) => theme.textGray};
`

const DollarAmountMobile = styled(DollarAmount)`
    margin-top: 0;
    font-size: 12px;
    line-height: 13.2px;
`

export const SelectCurrency = ({
    isPay = false,
    openModal,
    value,
    setValue,
    selectedCurrency,
    usdAmount,
    switchedCurrency,
}) => {
    const enforcer = (newValue) => {
        if (newValue === '' || inputRegexp.test(escapeRegExp(newValue))) {
            setValue(newValue)
        }
    }

    const iconUrl = isPay
        ? selectedCurrency?.attributes?.icon?.url
        : selectedCurrency?.attributes?.fungible_info?.icon?.url

    const switchedCurrencyUrl = !isPay
        ? switchedCurrency?.attributes?.icon?.url
        : switchedCurrency?.attributes?.fungible_info?.icon?.url

    const symbol = isPay
        ? selectedCurrency?.attributes?.symbol
        : selectedCurrency?.attributes?.fungible_info?.symbol

    const switchedCurrencySymbol = !isPay
        ? switchedCurrency?.attributes?.symbol
        : switchedCurrency?.attributes?.fungible_info?.symbol

    return (
        <>
            <Wrapper mt={isPay ? '36px' : '20px'}>
                <SelectButton imgUrl={PayButtonBg} onClick={openModal}>
                    <StyledFlexAlign>
                        <CurrencyIcon>
                            <img
                                src={iconUrl || switchedCurrencyUrl || EthIcon}
                                alt="icon"
                            />
                        </CurrencyIcon>
                        <CurrencySymbol>
                            <span className="mono">
                                {symbol || switchedCurrencySymbol || 'Select'}
                            </span>
                        </CurrencySymbol>
                    </StyledFlexAlign>
                    <img src={DropdownArrow} alt="" />
                </SelectButton>
                <Container>
                    <DollarAmount>
                        <span className="mono">${usdAmount || 0}</span>
                    </DollarAmount>
                    <InputContainer>
                        <input
                            disabled={isPay}
                            className="input"
                            type="text"
                            pattern="^[0-9]*[.,]?[0-9]*$"
                            value={value}
                            onChange={(event) =>
                                enforcer(event.target.value.replace(/,/g, '.'))
                            }
                            style={{
                                height: '46px',
                                fontSize: '24px',
                            }}
                            placeholder="0"
                        />
                    </InputContainer>
                </Container>
            </Wrapper>

            <WrapperMobile mt={isPay ? '56px' : '16px'}>
                <SelectButtonMobile
                    imgUrl={PayButtonMobileBg}
                    onClick={openModal}
                >
                    <StyledFlexAlign>
                        <CurrencyIconMobile>
                            <img
                                src={iconUrl || switchedCurrencyUrl || EthIcon}
                                alt="icon"
                            />
                        </CurrencyIconMobile>
                        <CurrencySymbolMobile>
                            <span className="mono">
                                {symbol || switchedCurrencySymbol || 'Select'}
                            </span>
                        </CurrencySymbolMobile>
                    </StyledFlexAlign>
                    <ArrowImg src={DropdownArrow} alt="" />
                </SelectButtonMobile>
                <ContainerMobile>
                    <input
                        className="input"
                        type="text"
                        pattern="^[0-9]*[.,]?[0-9]*$"
                        value={value}
                        onChange={(event) =>
                            enforcer(event.target.value.replace(/,/g, '.'))
                        }
                        style={{
                            height: '18px',
                            fontSize: '16px',
                            lineHeight: '17.6px',
                        }}
                        placeholder="0"
                    />
                    <DollarAmountMobile>
                        <span className="mono">${usdAmount || 0}</span>
                    </DollarAmountMobile>
                </ContainerMobile>
            </WrapperMobile>
        </>
    )
}
