import React, { useEffect } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { metaMask } from './connectors/metamask'
import { walletConnect } from './connectors/walletConnect'
import { ROUTES } from './constants/routes'
import { NotificationsContextProvider } from './context/Notificationscontext'
import { ScreenContextProvider } from './context/ScreenContext'
import { WalletAdapterContext } from './context/WalletAdapter'
import { Allocations } from './pages/Allocations'
import { Dashboard } from './pages/Dashboard'
import { Dex } from './pages/Dex'
import { Tasks } from './pages/Tasks'
import { NETWORKS, useNetworkStore } from './store/usseNetworkStore'

export function App() {
    const { setNetwork } = useNetworkStore()
    useEffect(() => {
        const isConnected = window.sessionStorage.getItem('connected')
        const currentNetwork = sessionStorage.getItem('network')

        if (!currentNetwork || currentNetwork === NETWORKS.bnb) {
            setNetwork(NETWORKS.bnb)
        } else if (currentNetwork === NETWORKS.solana) {
            setNetwork(NETWORKS.solana)
        }

        const reconnect = async () => {
            const provider = sessionStorage.getItem('provider')

            if (provider === 'metamask') {
                await metaMask.connectEagerly()
            } else if (provider === 'wallet-connect') {
                await walletConnect.connectEagerly()
            }
        }

        if (isConnected === 'true') {
            reconnect()
        }
    }, [])

    return (
        <ScreenContextProvider>
            <NotificationsContextProvider>
                <HashRouter>
                    <WalletAdapterContext>
                        <Routes>
                            <Route
                                path={ROUTES.dashboard}
                                element={<Dashboard />}
                            />
                            <Route
                                path={ROUTES.allocations}
                                element={<Allocations />}
                            />
                            <Route path={ROUTES.dex} element={<Dex />} />
                            <Route path={ROUTES.tasks} element={<Tasks />} />
                            <Route
                                path="*"
                                element={<Navigate to={ROUTES.dashboard} />}
                            />
                        </Routes>
                    </WalletAdapterContext>
                </HashRouter>
            </NotificationsContextProvider>
        </ScreenContextProvider>
    )
}
