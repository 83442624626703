import axios from 'axios'
import { BASE_ZERION_URL } from '../constants/dex'

const $client = axios.create({
    baseURL: BASE_ZERION_URL,
})

$client.interceptors.request.use((config) => {
    config.headers['accept'] = 'application/json'
    return config
})

export default $client
