import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import AllocationInnerBlock from '../../../../assets/blocks/allocations-inner-block.svg'
import {
    AutoColumn,
    Centered,
    Flex,
    FlexAlign,
    FlexColumn,
    RowBetween,
} from '../../../../components/styled'
import AllocationMinBlock from '../../../../assets/blocks/allocations-small-2.svg'
import AllocationMaxBlock from '../../../../assets/blocks/allocations-small.svg'
import InnerBlockMobileBg from '../../../../assets/mobile/blocks/inner-block.svg'
import GetBonusesBgMobile from '../../../../assets/mobile/blocks/get-bonuses-bg.svg'
import MaxBlockBgMobile from '../../../../assets/mobile/blocks/max-block-bg.svg'
import { inputRegexp } from '../../../../constants/regExp'
import { escapeRegExp } from '../../../../utils/escapeRegExp'
import { calculateButtonAmount } from '../../../../utils'
import DropdownArrow from '../../../../assets/dex/open-dropdown.svg'
import { SolanaDropdown } from './SolanaDropdown'

const AllocationsAmountText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const AllocationsAmountTextMobile = styled(AllocationsAmountText)`
    font-size: 13px;
    line-height: 14.3px;
`
const AllocationsBalanceTextMobile = styled(AllocationsAmountTextMobile)`
    color: ${({ theme }) => theme.textGray};
    text-align: right;
`

const AllocationsTextSmall = styled.p`
    color: ${({ theme }) => theme.secondary};
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
`

const AllocationsBalanceText = styled(AllocationsTextSmall)`
    display: flex;
    gap: 8px;
    position: relative;
    color: ${({ theme }) => theme.textGray};
    line-height: 17.6px;
    cursor: default;
`

const InnerBlock = styled.div`
    position: relative;
    margin-top: 16px;
    padding: 16px 30px 0;
    width: 100%;
    max-width: 642px;
    height: 154px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const InnerBlockWrapper = styled(AutoColumn)`
    display: none;
    margin-top: 12px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: grid;
  `};
`

const InnerBlockMobile = styled.div`
    position: relative;
    width: 100%;
    max-width: 285px;
    height: 114px;
    padding-inline: 20px;
    padding-top: 14px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const InputContainer = styled.div`
    margin-top: 39px;
    width: 100%;
    max-width: 232px;
`

const InputContainerMobile = styled.div`
    margin-top: 27px;
    width: 100%;
    max-width: 200px;
`

const MinBlock = styled(Centered)`
    position: absolute;
    top: 82px;
    right: 13px;
    width: 162px;
    height: 68px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    color: ${({ theme }) => theme.secondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 15.68px;
    cursor: pointer;
`

const MaxBlock = styled(MinBlock)`
    right: 154px;
    width: 190px;
`

const MinBlockMobile = styled(Centered)`
    position: absolute;
    right: -25px;
    width: 162px;
    height: 64px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    color: ${({ theme }) => theme.secondary};
    font-weight: 400;
    font-size: 13px;
    line-height: 14.56px;
    cursor: pointer;
`

const MaxBlockMobile = styled(MinBlockMobile)`
    left: -8px;
    width: 160px;
    cursor: pointer;
`

const StyledSpan = styled.span`
    color: ${({ theme }) => theme.textGold};
    margin-left: -10px;
`

const RowBetweenMobile = styled(Flex)`
    position: relative;
    margin-top: -8px;
`

export const InnerBlockComponent = ({
    value,
    setValue,
    balance,
    maxValue,
    handleMaxValue,
    handleBonuceClick = undefined,
    solanaBalance,
    isSolana,
    selectedToken,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const enforcer = useCallback(
        (newValue) => {
            if (!isSolana) {
                if (
                    newValue === '' ||
                    inputRegexp.test(escapeRegExp(newValue))
                ) {
                    const parsedValue =
                        parseFloat(newValue.replace(/,/g, '.')) || 0
                    const sanitizedValue = Math.min(parsedValue, 10000)
                    setValue(sanitizedValue.toString())
                }
            } else {
                if (
                    newValue === '' ||
                    inputRegexp.test(escapeRegExp(newValue))
                ) {
                    setValue(newValue)
                }
            }
        },
        [isSolana, selectedToken],
    )

    const onCloseDropdown = () => {
        setIsOpen(false)
    }

    const toggleDropdown = () => {
        setIsOpen((prev) => !prev)
    }

    const calculatedBalance = isSolana
        ? calculateButtonAmount(solanaBalance ? Number(solanaBalance) : 0)
        : calculateButtonAmount(balance ? Number(balance) : 0)

    return (
        <>
            <InnerBlock imgUrl={AllocationInnerBlock}>
                <RowBetween>
                    <AllocationsAmountText>
                        <span className="astro">
                            Amount in {isSolana ? selectedToken : 'USDT'}
                        </span>
                    </AllocationsAmountText>
                    {!isSolana && (
                        <AllocationsBalanceText>
                            <span className="mono">
                                Balance: {isSolana ? solanaBalance : balance}{' '}
                                USDT
                            </span>
                        </AllocationsBalanceText>
                    )}
                    {isSolana && (
                        <AllocationsBalanceText>
                            <span className="mono">
                                Balance: {isSolana ? solanaBalance : balance}
                            </span>
                            <span
                                onClick={toggleDropdown}
                                className="mono"
                                style={{ cursor: 'pointer' }}
                            >
                                {' '}
                                {selectedToken}
                                <img
                                    style={{ marginLeft: '8px' }}
                                    src={DropdownArrow}
                                    alt=""
                                />
                            </span>
                            {isOpen && (
                                <SolanaDropdown onClose={onCloseDropdown} />
                            )}
                        </AllocationsBalanceText>
                    )}
                </RowBetween>
                <InputContainer>
                    <input
                        className="input"
                        type="text"
                        pattern="^[0-9]*[.,]?[0-9]*$"
                        value={value}
                        onChange={(event) =>
                            enforcer(event.target.value.replace(/,/g, '.'))
                        }
                        placeholder="enter amount"
                    />
                </InputContainer>
                <MaxBlock imgUrl={AllocationMaxBlock} onClick={handleMaxValue}>
                    <span className="mono" style={{ marginLeft: '12px' }}>
                        Max: {maxValue} {isSolana ? selectedToken : 'USDT'}
                    </span>
                </MaxBlock>
                <MinBlock
                    imgUrl={AllocationMinBlock}
                    onClick={() =>
                        handleBonuceClick &&
                        handleBonuceClick(calculatedBalance)
                    }
                >
                    <StyledSpan className="mono">
                        {calculatedBalance} {isSolana ? selectedToken : 'USDT'}
                    </StyledSpan>
                </MinBlock>
            </InnerBlock>

            <InnerBlockWrapper>
                <InnerBlockMobile imgUrl={InnerBlockMobileBg}>
                    <RowBetween style={{ alignItems: 'flex-start' }}>
                        {!isSolana && (
                            <AllocationsAmountTextMobile>
                                <span className="astro">
                                    Amount in{' '}
                                    {isSolana ? selectedToken : 'USDT'}
                                </span>
                            </AllocationsAmountTextMobile>
                        )}
                        {isSolana && (
                            <FlexColumn>
                                <AllocationsAmountTextMobile>
                                    <span className="astro">Amount in</span>
                                </AllocationsAmountTextMobile>
                                <AllocationsAmountTextMobile>
                                    <span className="astro">
                                        {isSolana ? selectedToken : 'USDT'}
                                    </span>
                                </AllocationsAmountTextMobile>
                            </FlexColumn>
                        )}
                        <AutoColumn gap="4px">
                            {!isSolana && (
                                <AllocationsBalanceTextMobile>
                                    <span className="mono">Balance</span>
                                </AllocationsBalanceTextMobile>
                            )}
                            {!isSolana && (
                                <AllocationsBalanceTextMobile>
                                    <span className="mono">{balance} USDT</span>
                                </AllocationsBalanceTextMobile>
                            )}
                            {isSolana && (
                                <AllocationsBalanceTextMobile>
                                    <FlexColumn>
                                        <span className="mono">
                                            Balance:{' '}
                                            {isSolana ? solanaBalance : balance}
                                        </span>
                                        <FlexAlign>
                                            <span
                                                onClick={toggleDropdown}
                                                className="mono"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {' '}
                                                {selectedToken}
                                                <img
                                                    style={{
                                                        marginLeft: '8px',
                                                    }}
                                                    src={DropdownArrow}
                                                    alt=""
                                                />
                                            </span>
                                            {isOpen && (
                                                <SolanaDropdown
                                                    onClose={onCloseDropdown}
                                                />
                                            )}
                                        </FlexAlign>
                                    </FlexColumn>
                                </AllocationsBalanceTextMobile>
                            )}
                        </AutoColumn>
                    </RowBetween>
                    <InputContainerMobile>
                        <input
                            className="input input-mobile"
                            type="text"
                            pattern="^[0-9]*[.,]?[0-9]*$"
                            value={value}
                            onChange={(event) =>
                                enforcer(event.target.value.replace(/,/g, '.'))
                            }
                            placeholder="enter amount"
                        />
                    </InputContainerMobile>
                </InnerBlockMobile>
                <RowBetweenMobile>
                    <MaxBlockMobile
                        imgUrl={MaxBlockBgMobile}
                        onClick={handleMaxValue}
                    >
                        <span className="mono" style={{ fontSize: 12 }}>
                            Max: {maxValue} {isSolana ? selectedToken : 'USDT'}
                        </span>
                    </MaxBlockMobile>
                    <MinBlockMobile
                        imgUrl={GetBonusesBgMobile}
                        onClick={() =>
                            handleBonuceClick &&
                            handleBonuceClick(
                                calculateButtonAmount(
                                    balance ? Number(balance) : 0,
                                ),
                            )
                        }
                    >
                        <StyledSpan className="mono">
                            {calculatedBalance}{' '}
                            {isSolana ? selectedToken : 'USDT'}
                        </StyledSpan>
                    </MinBlockMobile>
                </RowBetweenMobile>
            </InnerBlockWrapper>
        </>
    )
}
