import { AddressZero } from '@ethersproject/constants'
import { Contract, ethers } from 'ethers'

export function isAddress(value) {
    try {
        return ethers.utils.getAddress(value)
    } catch {
        return false
    }
}
export function getSigner(library, account) {
    return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library, account) {
    return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address, ABI, library, account) {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }

    return new Contract(address, ABI, getProviderOrSigner(library, account))
}

export const isZero = (value) => value === 0

export function calculateButtonAmount(balance) {
    if (balance < 1000) {
        return 100
    } else if (balance >= 1000 && balance < 1500) {
        return 500
    } else if (balance >= 1500 && balance < 2500) {
        return 750
    } else {
        return 1000
    }
}
