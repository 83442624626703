import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import { AutoColumn, FlexAlign } from '../../../../components/styled'

const WrapperMobile = styled(AutoColumn)`
    display: none;
    padding-left: 8px;
    position: relative;
    width: 100%;
    max-width: 500px;
    margin-top: 8px;
    height: 50px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: grid;
  `};
`

const GetACodeTitleMobile = styled.p`
    font-weight: 400;
    line-height: 12px;
    font-size: 12px;
    color: ${({ theme }) => theme.primary};
`

export const RewardsInfoBNB = ({ rewardsInfo }) => {
    const tickets = useMemo(() => {
        if (!rewardsInfo || !rewardsInfo?.totalAmount) {
            return 0
        }
        const totalUsd = parseInt(
            ethers.utils.formatUnits(rewardsInfo?.totalAmount, 18),
        )
        return Math.floor(Math.floor(totalUsd) / 10)
    }, [rewardsInfo])

    const rewards = useMemo(() => {
        if (!rewardsInfo) {
            return {
                value: 0,
                total: 0,
            }
        }
        return {
            value: rewardsInfo?.distribution?.distributed?.toFixed(2),
            total: rewardsInfo?.distribution?.totalDistribution?.toFixed(2),
        }
    }, [rewardsInfo])
    return (
        <>
            <WrapperMobile gap="4px">
                <FlexAlign style={{ gap: '10px' }}>
                    <GetACodeTitleMobile>
                        <span className="astro">Rewards:</span>
                    </GetACodeTitleMobile>
                    <GetACodeTitleMobile>
                        <span className="astro">
                            {rewards.value} / {rewards.total}
                        </span>
                    </GetACodeTitleMobile>
                </FlexAlign>
                <FlexAlign style={{ gap: '10px' }}>
                    <GetACodeTitleMobile>
                        <span className="astro">Tickets:</span>
                    </GetACodeTitleMobile>
                    <GetACodeTitleMobile>
                        <span className="astro">{tickets}</span>
                    </GetACodeTitleMobile>
                </FlexAlign>
            </WrapperMobile>
        </>
    )
}
