import React, { useRef } from 'react'
import styled from 'styled-components'
import PayButtonBg from '../../../assets/dex/dex-blocks/dex-pay-currency.svg'
import DropdownArrow from '../../../assets/dex/open-dropdown.svg'
import { AutoColumn, FlexAlign, RowBetween } from '../../../components/styled'
import { useModal } from '../../../hooks/useModal'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { useDexStore } from '../../../store/useDexStore'
import { SWAP_CHAINS, useZerionStore } from '../../../store/useZerionStore'
import { stopPropagation } from '../../../utils/stopPropagation'

const Wrapper = styled.div`
    display: block;
    position: absolute;
    top: 50px;
    right: 42px;
    z-index: 99;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const SelectChainButton = styled(RowBetween)`
    width: 164px;
    height: 76px;
    padding-inline: 20px;
    padding-bottom: 6px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

const StyledFlexAlign = styled(FlexAlign)`
    gap: 8px;
`

const CurrencyIcon = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
    }
`

const CurrencySymbol = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.secondary};
`

const ChainsDropdown = styled(AutoColumn)`
    position: absolute;
    padding: 4px;
    top: 62px;
    right: 16px;
    width: 136px;
    height: fit-content;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    overflow: hidden;
`

const DropdownItem = styled(RowBetween)`
    width: 100%;
    padding: 10px 4px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background: rgba(230, 230, 230, 1);
    }
`

export const ChainSelector = () => {
    const ref = useRef()
    const { isOpen, toggleModal, closeModal } = useModal()
    const { swapChain, setSwapChain } = useZerionStore()
    const { reset } = useDexStore()

    const changeChain = (chain) => {
        setSwapChain(chain)
        closeModal()
        reset()
    }

    useOnClickOutside(ref, isOpen ? toggleModal : undefined)

    return (
        <Wrapper ref={ref}>
            <SelectChainButton imgUrl={PayButtonBg} onClick={toggleModal}>
                <ChainInfo chain={swapChain} />
                <img src={DropdownArrow} alt="" />
            </SelectChainButton>
            {isOpen && (
                <ChainsDropdown onClick={stopPropagation}>
                    <DropdownItem
                        onClick={() => changeChain(SWAP_CHAINS.ethereum)}
                    >
                        <ChainInfo chain={SWAP_CHAINS.ethereum} />
                    </DropdownItem>

                    <DropdownItem
                        onClick={() => changeChain(SWAP_CHAINS.binance)}
                    >
                        <ChainInfo chain={SWAP_CHAINS.binance} />
                    </DropdownItem>
                </ChainsDropdown>
            )}
        </Wrapper>
    )
}

const ChainInfo = ({ chain }) => {
    return (
        <StyledFlexAlign>
            <CurrencyIcon>
                <img src={chain.icon} alt="icon" />
            </CurrencyIcon>
            <CurrencySymbol>
                <span className="mono">{chain.name}</span>
            </CurrencySymbol>
        </StyledFlexAlign>
    )
}
