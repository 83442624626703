import { useWallet } from '@solana/wallet-adapter-react'
import { useWeb3React } from '@web3-react/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import { userService } from '../../api/userService'
import ConnectWalletBg from '../../assets/blocks/connect-wallet-yelllow.svg'
import HeaderSmall from '../../assets/blocks/header-small.svg'
import WrongNetworkBg from '../../assets/blocks/wrong-network-error.svg'
import ArrowDownIcon from '../../assets/svg/arrow-down.svg'
import ErrorIcon from '../../assets/svg/error.svg'
import { config } from '../../connectors/config'
import { metaMask } from '../../connectors/metamask'
import { walletConnect } from '../../connectors/walletConnect'
import { CHAIN_ID } from '../../constants'
import { useModal } from '../../hooks/useModal'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useErrorStore } from '../../store/useErrorStore'
import { NETWORKS, useNetworkStore } from '../../store/usseNetworkStore'
import { getPhantomAddress } from '../../utils/phantom'
import { shortId } from '../../utils/shortId'
import { Centered, RowBetween } from '../styled'
import { ConnectWalletModal } from './ConnectWalletModal'
import { DropdownComponent } from './DropdownComponent'
import { AccountText, AccountTextWrapper, WalletWrapper } from './styles'

const ConnectWalletBlock = styled.div`
    position: relative;
    width: 211px;
    height: 80px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

const ConnectWalletText = styled.p`
    margin-top: 21px;
    color: ${({ theme }) => theme.black};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const ArrowWrapper = styled.div`
    margin-left: 8px;
    margin-top: -2px;
    width: 12px;
    height: 12px;

    img {
        width: 100%;
        height: 100%;
    }
`

const WrongNetworkBlock = styled(RowBetween)`
    position: absolute;
    top: 64px;
    left: -50px;
    width: 271px;
    height: 84px;
    padding-left: 37px;
    padding-right: 33px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const WrongNetworkText = styled.p`
    color: ${({ theme }) => theme.error};
    font-size: 16px;
    font-weight: 400;
    line-height: 22.24px;
`

const ErrorImg = styled.div`
    width: 26px;
    height: 26px;
    img {
        width: 100%;
        height: 100%;
    }
`

export const Web3Status = ({ isDex }) => {
    const { account, chainId, provider } = useWeb3React()
    const { address } = useAccount()
    const { publicKey: adapterWalletAddress } = useWallet()
    const { network } = useNetworkStore()
    const node = useRef()
    const node2 = useRef()
    const {
        isOpen: isDropdownOpen,
        toggleModal: toggleDropdown,
        closeModal: closeDropdown,
    } = useModal()
    const {
        isOpen: isWalletModalOpen,
        openModal: openWalletModal,
        closeModal: closeWalletModal,
    } = useModal()
    const { error, setError, unsetError } = useErrorStore()

    const [phantomAddress, setPhantomAddress] = useState('')

    const getPhantomWalletAddress = async () => {
        setTimeout(async () => {
            const { walletAddress } = await getPhantomAddress()
            if (walletAddress) {
                setPhantomAddress(walletAddress)
            }
        }, 1000)
    }

    const handleNetworkSwitch = useCallback(async () => {
        if (isDex) {
            return
        }

        closeDropdown()
        setError()
        const provider = sessionStorage.getItem('provider')
        if (provider === 'metamask') {
            await metaMask
                .activate(config)
                .then(() => {
                    sessionStorage.setItem('connected', 'true')
                    unsetError()
                })
                .catch(() => {
                    handleNetworkSwitch()
                })
        } else if (provider === 'wallet-connect') {
            await walletConnect
                .activate(CHAIN_ID)
                .then(() => {
                    sessionStorage.setItem('connected', 'true')
                    unsetError()
                    window.location.reload()
                })
                .catch(() => {
                    handleNetworkSwitch()
                })
        }
    }, [provider, isDex])

    useEffect(() => {
        if (network === NETWORKS.solana) {
            return
        }

        const invalidNetwork =
            account && chainId !== undefined && chainId !== config.chainId

        if (invalidNetwork) {
            handleNetworkSwitch()
        }
    }, [chainId, account, network])

    const handleOpenDropdown = () => {
        unsetError()
        toggleDropdown()
    }

    useEffect(() => {
        if (
            network !== NETWORKS.solana ||
            (!address && !adapterWalletAddress)
        ) {
            return
        }
        getPhantomWalletAddress()
    }, [address, network, adapterWalletAddress])

    useOnClickOutside(node, isDropdownOpen ? toggleDropdown : undefined)

    useOnClickOutside(node2, error ? unsetError : undefined)

    const isSolana = network === NETWORKS.solana

    const displayedAddress = isSolana
        ? shortId(phantomAddress || '', 7)
        : shortId(account || '', 7)

    useEffect(() => {
        if (account || address) {
            userService.login(account || address)
        }
    }, [account, address])

    return (
        <>
            {account || address || adapterWalletAddress ? (
                <WalletWrapper imgUrl={HeaderSmall} ref={node}>
                    <>
                        <AccountTextWrapper onClick={handleOpenDropdown}>
                            <AccountText>
                                <span className="rubic">
                                    {displayedAddress}
                                </span>
                            </AccountText>
                            <ArrowWrapper>
                                <img src={ArrowDownIcon} alt="" />
                            </ArrowWrapper>
                        </AccountTextWrapper>
                        {isDropdownOpen && <DropdownComponent />}
                        {error && (
                            <WrongNetworkBlock imgUrl={WrongNetworkBg}>
                                <ErrorImg>
                                    <img src={ErrorIcon} alt="" />
                                </ErrorImg>
                                <WrongNetworkText>
                                    <span className="astro">Wrong network</span>
                                </WrongNetworkText>
                            </WrongNetworkBlock>
                        )}
                    </>
                </WalletWrapper>
            ) : (
                <>
                    <ConnectWalletBlock
                        ref={node2}
                        onClick={openWalletModal}
                        imgUrl={ConnectWalletBg}
                    >
                        <Centered>
                            <ConnectWalletText>
                                <span className="rubic">Connect Wallet</span>
                            </ConnectWalletText>
                        </Centered>
                        {error && (
                            <WrongNetworkBlock imgUrl={WrongNetworkBg}>
                                <ErrorImg>
                                    <img src={ErrorIcon} alt="" />
                                </ErrorImg>
                                <WrongNetworkText>
                                    <span className="astro">Wrong network</span>
                                </WrongNetworkText>
                            </WrongNetworkBlock>
                        )}
                    </ConnectWalletBlock>
                    {isWalletModalOpen && (
                        <ConnectWalletModal
                            isOpen={isWalletModalOpen}
                            closeModal={closeWalletModal}
                            isDex={isDex}
                        />
                    )}
                </>
            )}
        </>
    )
}
