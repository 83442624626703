import { useWeb3React } from '@web3-react/core'
import { useCallback, useState } from 'react'
import { Contract, ethers, BigNumber } from 'ethers'
import { CONTRACT_ADDRESS, USDT_ADDRESS } from '../constants'
import { ERC20_ABI } from '../constants/abi/erc20abi'
import { useNotificationsContext } from '../context/Notificationscontext'
import { toFixedAmount } from '../utils/formatToFixes'
import { useCreateBuyContract } from './useContract'

export function useBuyContract() {
    const { account, provider } = useWeb3React()
    const {
        showPendingNotification,
        showSuccessNotification,
        showErrorNotification,
    } = useNotificationsContext()

    const [isTxLoading, setIsTxLoading] = useState(false)

    const contract = useCreateBuyContract(CONTRACT_ADDRESS)

    const approveTokens = async (amount) => {
        const token = new Contract(
            USDT_ADDRESS,
            ERC20_ABI,
            provider?.getSigner(),
        )
        const allowance = await token.allowance(account, CONTRACT_ADDRESS)

        const shouldApprove =
            parseFloat(ethers.utils.formatEther(allowance)) < +amount

        if (shouldApprove) {
            try {
                const tx = await token.approve(
                    CONTRACT_ADDRESS,
                    ethers.utils.parseEther(amount),
                )
                await tx.wait()
            } catch (err) {
                throw err
            }
        }
    }

    const buyTickets = useCallback(
        async (amount, refCode, refetch, setAmount) => {
            showPendingNotification()
            setIsTxLoading(true)
            try {
                await approveTokens(amount)
            } catch (err) {
                showErrorNotification('')
                setIsTxLoading(false)
                return
            }
            const parsedAmount = ethers.utils.parseEther(amount)
            try {
                const tx = await contract.buyTickets(
                    USDT_ADDRESS,
                    parsedAmount,
                    refCode,
                )
                await tx.wait()
                showSuccessNotification()
                refetch()
                setAmount('')
                setIsTxLoading(false)
            } catch (err) {
                showErrorNotification('')
                setIsTxLoading(false)
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    const getTicketsData = useCallback(async () => {
        try {
            const maxTicketsPerUser = await contract.maxTicketsPerUser()
            const userTickets = await contract.userTickets(account)
            const totalTicketsThreshold = await contract.totalTicketsThreshold()
            const totalTickets = await contract.totalTickets()

            return {
                maxTicketsPerUserRaw: BigNumber.from(maxTicketsPerUser),
                totalTicketsThresholdRaw: BigNumber.from(totalTicketsThreshold),
                totalTicketsRaw: BigNumber.from(totalTickets),
                userTicketsRaw: {
                    totalRaw: BigNumber.from(userTickets[0]),
                    directRaw: BigNumber.from(userTickets[1]),
                },
                userTickets: {
                    total: toFixedAmount(
                        ethers.utils.formatUnits(userTickets[0], 16),
                        2,
                    ),
                    direct: toFixedAmount(
                        ethers.utils.formatUnits(userTickets[1], 16),
                        2,
                    ),
                },
                totalTicketsThreshold: toFixedAmount(
                    ethers.utils.formatUnits(totalTicketsThreshold, 16),
                    2,
                ),
                totalTickets: toFixedAmount(
                    ethers.utils.formatUnits(totalTickets, 16),
                    2,
                ),
            }
        } catch (err) {
            console.error(err)
        }
    }, [contract, account, provider])

    const userRewards = useCallback(
        async (address, token) => {
            try {
                const response = await contract.userRewards(address, token)
                if (response) {
                    return {
                        total: Number(ethers.utils.formatEther(response[0])),
                        claimed: Number(ethers.utils.formatEther(response[1])),
                    }
                }
            } catch (err) {
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    const claimReward = useCallback(
        async (amount, buyback, refetch, setAmount) => {
            showPendingNotification()
            setIsTxLoading(true)
            const formattedAmount = ethers.utils.parseEther(String(amount))
            try {
                const tx = await contract.claimReward(
                    USDT_ADDRESS,
                    formattedAmount,
                    buyback,
                )
                await tx.wait()
                showSuccessNotification()
                refetch()
                setAmount('')
                setIsTxLoading(false)
            } catch (err) {
                showErrorNotification('')
                setIsTxLoading(false)
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    const addressToRefCode = useCallback(
        async (address) => {
            try {
                return await contract.addressToRefCode(address)
            } catch (err) {
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    const referralToReferrer = useCallback(
        async (address) => {
            try {
                return await contract.referralToReferrer(address)
            } catch (err) {
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    const refCodeToAddress = useCallback(
        async (refCode) => {
            try {
                return await contract.refCodeToAddress(refCode)
            } catch (err) {
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    const getReferralsLength = useCallback(
        async (address) => {
            try {
                return await contract.getReferralsLength(address)
            } catch (err) {
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    const getDepth = useCallback(async () => {
        try {
            return await contract.depth()
        } catch (err) {
            console.error(err)
        }
    }, [contract, account, provider])

    const referrerToReferrals = useCallback(
        async (address, index) => {
            try {
                return await contract.referrerToReferrals(address, index)
            } catch (err) {
                console.error(err)
            }
        },
        [contract, account, provider],
    )

    return {
        buyTickets,
        getTicketsData,
        addressToRefCode,
        refCodeToAddress,
        referralToReferrer,
        userRewards,
        claimReward,
        isTxLoading,
        getReferralsLength,
        getDepth,
        referrerToReferrals,
    }
}
