import React from 'react'
import styled from 'styled-components'
import EnterBonusInputBg from '../../../../assets/blocks/enter-bonus-input-block.svg'
import InputBgMobile from '../../../../assets/mobile/blocks/input-bg.svg'
import { AutoColumn, RowBetween } from '../../../../components/styled'

const EnterBonusWrapper = styled.div`
    padding-left: 8px;
    position: relative;
    width: 100%;
    max-width: 738px;
    height: 100px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const EnterBonusWrapperMobile = styled.div`
    display: none;
    margin-top: 60px;
    padding-left: 8px;
    position: relative;
    width: 100%;
    max-width: 738px;
    height: 100px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const InputBlock = styled(RowBetween)`
    margin-top: 16px;
    width: 100%;
    max-width: 526px;
    height: 56px;
    padding-inline: 22px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`
const InputBlockMobile = styled(RowBetween)`
    margin-top: 8px;
    width: 100%;
    max-width: 182px;
    height: 50px;
    padding-inline: 22px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const GetACodeTitle = styled.p`
    font-weight: 400;
    line-height: 20px;
    font-size: 20px;
    color: ${({ theme }) => theme.primary};
`

const GetACodeTitleMobile = styled(GetACodeTitle)`
    font-size: 14px;
    line-height: 14px;
`

export const SolanaTicketsComponent = ({ value }) => {
    return (
        <>
            <EnterBonusWrapper>
                <AutoColumn gap="16px">
                    <GetACodeTitle>
                        <span className="astro">$LAIKA you will get</span>
                    </GetACodeTitle>
                </AutoColumn>
                <InputBlock imgUrl={EnterBonusInputBg}>
                    <input
                        className="enter-bonus-input"
                        value={value}
                        onChange={(e) => enforcer(e.target.value)}
                        type="text"
                        placeholder="0"
                        disabled={true}
                        style={{ fontWeight: 700 }}
                    />
                </InputBlock>
            </EnterBonusWrapper>

            <EnterBonusWrapperMobile>
                <AutoColumn gap="16px">
                    <GetACodeTitleMobile>
                        <span className="astro">$LAIKA you will get</span>
                    </GetACodeTitleMobile>
                </AutoColumn>
                <InputBlockMobile imgUrl={InputBgMobile}>
                    <input
                        className="enter-bonus-input"
                        value={value}
                        onChange={(e) => enforcer(e.target.value)}
                        type="text"
                        placeholder="0"
                        disabled={true}
                        style={{ fontWeight: 700, fontSize: '16px' }}
                    />
                </InputBlockMobile>
            </EnterBonusWrapperMobile>
        </>
    )
}
