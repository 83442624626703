import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { getContract } from '../utils'
import { CONTRACT_ABI } from '../constants/abi/contract-abi'
import { ERC20_ABI } from '../constants/abi/erc20abi'

export function useContract(address, ABI, withSignerIfPossible = true) {
    const { account, provider } = useWeb3React()

    return useMemo(() => {
        if (!address || !ABI || !provider) return null
        try {
            return getContract(
                address,
                ABI,
                provider,
                withSignerIfPossible && account ? account : undefined,
            )
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, provider, withSignerIfPossible, account])
}

export function useCreateBuyContract(address) {
    return useContract(address, CONTRACT_ABI, true)
}

export function useTokenContract(tokenAddress, withSignerIfPossible) {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
