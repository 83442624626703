import axios from 'axios'
import { BASE_URL } from '../constants/solana'

const $solanaClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'ngrok-skip-browser-warning': 'true',
    },
})

export default $solanaClient
