import React from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import { Centered } from './styled'

const Wrapper = styled(Centered)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ bglight }) =>
        bglight === 'true' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.5)'};
    z-index: 999;
    padding: 0 10px;
`
export const Modal = ({ isOpen, closeModal, children, bgLight = true }) => {
    const container = document.getElementById('portal')

    if (!isOpen) return null

    return container
        ? createPortal(
              <Wrapper onClick={closeModal} bglight={String(bgLight)}>
                  {children}
              </Wrapper>,
              container,
          )
        : null
}
