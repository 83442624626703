import React from 'react'
import styled from 'styled-components'
import TicketsBg from '../../../../assets/nft/tickets-bg.svg'
// import IphoneIcon from '../../../../assets/nft/iphone.png'
import {
    // AutoColumn,
    // FlexAlign,
    FlexColumn,
    RowBetween,
} from '../../../../components/styled'

const Wrapper = styled(FlexColumn)`
    position: relative;
    width: 650px;
    height: 186px;
    padding: 20px 32px 26px;
    background-image: ${(props) => `url(${props.imgUrl})`};
`

const RewardsTitle = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const TicketsText = styled(RewardsTitle)`
    margin-top: 44px;
    color: ${({ theme }) => theme.success};
`

const RewardsTextSmall = styled.p`
    color: ${({ theme }) => theme.textGray};
    font-weight: 400;
    font-size: 16px;
    line-height: 17.6px;
`

// const RangeSection = styled(AutoColumn)`
//     position: absolute;
//     bottom: 30px;
//     right: 30px;
//     width: 236px;
// `
//
// const IphoneImg = styled.img`
//     width: 23px;
//     height: 24px;
//     margin-left: 8px;
//     margin-top: -4px;
// `
//
// const InputRangeContainer = styled.div`
//     width: 236px;
//     height: 4px;
//     background-color: ${({ theme }) => theme.white};
//     border-radius: 34px;
// `
//
// const InputRangeLine = styled.div`
//     width: ${({ percents }) => percents}%;
//     height: 4px;
//     background-color: ${({ theme }) => theme.rangeBg};
//     border-radius: 34px;
// `

export const PerksTickets = ({ tickets }) => {
    return (
        <Wrapper imgUrl={TicketsBg}>
            <RowBetween>
                <RewardsTitle>
                    <span className="astro">Your Tickets</span>
                </RewardsTitle>
                <RewardsTextSmall>
                    <span className="mono">$10 = 1 Ticket</span>
                </RewardsTextSmall>
            </RowBetween>
            <TicketsText>
                <span className="astro">{tickets}</span>
            </TicketsText>
            {/* <RangeSection gap="6px">*/}
            {/*    <RowBetween>*/}
            {/*        <RewardsTitle>*/}
            {/*            <span className="astro">12%</span>*/}
            {/*        </RewardsTitle>*/}
            {/*        <FlexAlign>*/}
            {/*            <RewardsTitle>*/}
            {/*                <span className="astro">chance to win</span>*/}
            {/*            </RewardsTitle>*/}
            {/*            <IphoneImg src={IphoneIcon} />*/}
            {/*        </FlexAlign>*/}
            {/*    </RowBetween>*/}
            {/*    <InputRangeContainer>*/}
            {/*        <InputRangeLine percents="12" />*/}
            {/*    </InputRangeContainer>*/}
            {/* </RangeSection>*/}
        </Wrapper>
    )
}
