import React from 'react'
import styled from 'styled-components'
import { AutoColumn, RowBetween } from '../../../../components/styled'
import { useReferralStore } from '../../../../store/useReferralStore'

const Wrapper = styled(RowBetween)`
    align-items: flex-end;
    margin-top: 72px;
    padding-left: 8px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(RowBetween)`
    display: none;
    align-items: flex-end;
    margin-top: 72px;
    padding-left: 8px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const LoyaltyTitle = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
`

const LoyaltyTitleMobile = styled(LoyaltyTitle)`
    font-size: 16px;
    line-height: 16px;
`

const LoyaltyTextSmall = styled.p`
    width: 100%;
    max-width: 546px;
    color: ${({ theme }) => theme.primary};
    font-weight: 400;
    font-size: 16px;
    line-height: 20.8px;
`

const LoyaltyTextSmallMobile = styled.p`
    color: ${({ theme }) => theme.primary};
    font-weight: 400;
    font-size: 13px;
    line-height: 16.9px;
`

const LevelText = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
`

const InvitesText = styled(LevelText)`
    font-size: 15px;
    line-height: 15px;
`

const BottomInfoContainer = styled(AutoColumn)`
    width: 100%;
    max-width: 180px;
    text-align: right;
`

const LoyaltyLevelTextMobile = styled(LevelText)`
    font-size: 13px;
    line-height: 13px;
`

export const LoyaltyTitleComponent = () => {
    const { invites } = useReferralStore()
    return (
        <>
            <Wrapper>
                <AutoColumn gap="16px">
                    <LoyaltyTitle>
                        <span className="astro">REFERRALS</span>
                    </LoyaltyTitle>
                    <LoyaltyTextSmall>
                        <span className="mono">
                            Share your referral code and get rewards from 3
                            levels of depth of your invitees (up to 8% from each
                            purchase)
                        </span>
                    </LoyaltyTextSmall>
                </AutoColumn>
                <BottomInfoContainer gap="10px">
                    <InvitesText>
                        <span className="astro">{invites} invitees</span>
                    </InvitesText>
                </BottomInfoContainer>
            </Wrapper>

            <WrapperMobile>
                <AutoColumn gap="16px">
                    <AutoColumn gap="8px">
                        <LoyaltyTitleMobile>
                            <span className="astro">REFERRALS</span>
                        </LoyaltyTitleMobile>
                        <LoyaltyTextSmallMobile>
                            <span className="mono">
                                Share your referral code and get rewards from 3
                                levels of depth of your invitees (up to 8% from
                                each purchase)
                            </span>
                        </LoyaltyTextSmallMobile>
                    </AutoColumn>
                    <RowBetween>
                        <div />
                        <LoyaltyLevelTextMobile>
                            <span className="astro">{invites} invitees</span>
                        </LoyaltyLevelTextMobile>
                    </RowBetween>
                </AutoColumn>
            </WrapperMobile>
        </>
    )
}
