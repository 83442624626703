import React, { useCallback, useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'
import { useWeb3React } from '@web3-react/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAccount, useDisconnect } from 'wagmi'
import { useWallet } from '@solana/wallet-adapter-react'
import DropdownBg from '../../assets/blocks/dropdown-block.svg'
import DropdownMobileBg from '../../assets/blocks/dropdown-block-mobile.svg'
import { AutoColumn, Centered, RowBetween } from '../styled'
import BNBIcon from '../../assets/svg/bnb.svg'
import SolanaIcon from '../../assets/svg/sol.svg'
import { shortId } from '../../utils/shortId'
import CopyIcon from '../../assets/svg/copy.svg'
import DisconnectBg from '../../assets/blocks/disconnact-block.svg'
import ArrowRightIcon from '../../assets/svg/arrow-right.svg'
import { ROUTES } from '../../constants/routes'
import { useToken } from '../../hooks/useToken'
import { USDT_ADDRESS } from '../../constants'
import { useRefetchStore } from '../../store/useRefetchStore'
import { walletConnect } from '../../connectors/walletConnect'
import { metaMask } from '../../connectors/metamask'
import { NETWORKS, useNetworkStore } from '../../store/usseNetworkStore'
import { getPhantomAddress } from '../../utils/phantom'
import { useSolanaUsdtBalanceStore } from '../../store/useSolanaUsdtBalanceStore'
import { useSolanaDropdownStore } from '../../store/useSolanaDropdownStore'
import {
    AccountCopySection,
    ArrowRightBlock,
    CopyBlock,
    DisconnectBlock,
    DisconnectText,
    Divider,
    DropdownWrapper,
    InfoTextInfo,
    InfoTextTitle,
    NetworkBlock,
    NetworkIcon,
    NetworkText,
} from './styles'

export const DropdownComponent = ({ mobile = false }) => {
    const location = useLocation()
    const { account } = useWeb3React()
    const { address } = useAccount()
    const {
        publicKey: adapterWalletAddress,
        disconnect: disconnectMobilePhantom,
    } = useWallet()
    const { network, setNetwork } = useNetworkStore()
    const navigate = useNavigate()
    const { refetchTrigger } = useRefetchStore()
    const { solanaUsdtBalance } = useSolanaUsdtBalanceStore()
    const { selectedToken } = useSolanaDropdownStore()
    const { disconnect: disconnectPhantom } = useDisconnect()
    const { balance: USDTBalance } = useToken(USDT_ADDRESS, refetchTrigger)

    const [phantomAddress, setPhantomAddress] = useState('')

    const disconnect = async () => {
        const shouldNavigate = location.pathname !== ROUTES.dex
        setNetwork(NETWORKS.empty)
        await metaMask.resetState()
        await walletConnect.resetState()
        await walletConnect.deactivate()
        await disconnectPhantom()
        await disconnectMobilePhantom()
        sessionStorage.removeItem('connected')
        sessionStorage.removeItem('provider')

        if (shouldNavigate) {
            navigate(ROUTES.dashboard)
        }
    }

    const getPhantomWalletAddress = async () => {
        setTimeout(async () => {
            const { walletAddress } = await getPhantomAddress()
            if (walletAddress) {
                setPhantomAddress(walletAddress)
            }
        }, 500)
    }

    const isSolana = network === NETWORKS.solana

    const onCopy = useCallback(() => {
        if (isSolana) {
            copy(phantomAddress)
        } else {
            copy(account)
        }
    }, [isSolana, phantomAddress])

    useEffect(() => {
        if (
            network !== NETWORKS.solana ||
            (!address && !adapterWalletAddress)
        ) {
            return
        }
        getPhantomWalletAddress()
    }, [address, network, adapterWalletAddress])

    const displayedAddress = isSolana
        ? shortId(
              phantomAddress
                  ? phantomAddress
                  : adapterWalletAddress?.toString() || '',
              7,
              6,
          )
        : shortId(account || '', 7, 6)

    const displayedBalance = isSolana ? solanaUsdtBalance : USDTBalance

    return (
        <DropdownWrapper
            imgUrl={mobile ? DropdownMobileBg : DropdownBg}
            mobile={String(mobile)}
        >
            <AutoColumn gap="29px">
                <AutoColumn gap="16px">
                    <Centered>
                        <NetworkBlock>
                            <NetworkIcon>
                                {!isSolana && <img src={BNBIcon} alt="" />}
                                {isSolana && <img src={SolanaIcon} alt="" />}
                            </NetworkIcon>
                            <NetworkText>
                                {!isSolana && (
                                    <span className="astro">BNB Chain</span>
                                )}
                                {isSolana && (
                                    <span className="astro">Solana</span>
                                )}
                            </NetworkText>
                        </NetworkBlock>
                    </Centered>
                    <Divider />
                </AutoColumn>
                <AutoColumn gap="19px">
                    <RowBetween>
                        <InfoTextTitle>
                            <span className="mono">Balance</span>
                        </InfoTextTitle>
                        <InfoTextInfo>
                            <span className="astro">
                                {displayedBalance || '0'}{' '}
                                {isSolana ? selectedToken : 'USDT'}
                            </span>
                        </InfoTextInfo>
                    </RowBetween>
                    <RowBetween>
                        <RowBetween>
                            <InfoTextTitle>
                                <span className="mono">Address</span>
                            </InfoTextTitle>
                            <AccountCopySection>
                                <InfoTextInfo>
                                    <span className="astro">
                                        {displayedAddress}
                                    </span>
                                </InfoTextInfo>
                                <CopyBlock onClick={onCopy}>
                                    <img src={CopyIcon} alt="" />
                                </CopyBlock>
                            </AccountCopySection>
                        </RowBetween>
                    </RowBetween>
                </AutoColumn>
            </AutoColumn>
            <DisconnectBlock onClick={disconnect} imgUrl={DisconnectBg}>
                <DisconnectText>
                    <span className="astro">Disconnect</span>
                </DisconnectText>
                <ArrowRightBlock>
                    <img src={ArrowRightIcon} alt="" />
                </ArrowRightBlock>
            </DisconnectBlock>
        </DropdownWrapper>
    )
}
