import React from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../../../../components/styled'
import {
    SOLANA_TOKENS,
    useSolanaDropdownStore,
} from '../../../../store/useSolanaDropdownStore'

const Wrapper = styled(AutoColumn)`
    position: absolute;
    top: 20px;
    right: -12px;
    width: 50px;
    height: 40px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(AutoColumn)`
    display: none;
    position: absolute;
    top: 48px;
    right: 51px;
    width: 50px;
    height: 40px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: grid;
  `};
`

const Item = styled.p`
    color: ${({ isactive, theme }) =>
        isactive === 'true' ? theme.black : theme.textGray};
    cursor: pointer;
`

export const SolanaDropdown = ({ onClose }) => {
    const { selectedToken, setSelectedToken } = useSolanaDropdownStore()

    const onChange = (token) => {
        setSelectedToken(token)
        onClose()
    }

    return (
        <>
            <Wrapper gap="2px">
                <Item
                    isactive={String(selectedToken === SOLANA_TOKENS.solana)}
                    onClick={() => onChange(SOLANA_TOKENS.solana)}
                >
                    <span className="mono">SOLANA</span>
                </Item>
                <Item
                    isactive={String(selectedToken === SOLANA_TOKENS.usdt)}
                    onClick={() => onChange(SOLANA_TOKENS.usdt)}
                >
                    <span className="mono">USDC</span>
                </Item>
            </Wrapper>
            <WrapperMobile>
                <Item
                    isactive={String(selectedToken === SOLANA_TOKENS.solana)}
                    onClick={() => onChange(SOLANA_TOKENS.solana)}
                >
                    <span className="mono">SOLANA</span>
                </Item>
                <Item
                    isactive={String(selectedToken === SOLANA_TOKENS.usdt)}
                    onClick={() => onChange(SOLANA_TOKENS.usdt)}
                >
                    <span className="mono">USDC</span>
                </Item>
            </WrapperMobile>
        </>
    )
}
