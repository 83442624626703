import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import DexBg from '../../../assets/dex/dex-blocks/dex-block.svg'
import DexErrorBg from '../../../assets/dex/dex-blocks/dex-error-bg.svg'
import DexMobileBg from '../../../assets/mobile/dex/dex-bg.svg'
import DexErrorMobileBg from '../../../assets/mobile/dex/dex-with-error-bg.svg'
import { Flex } from '../../../components/styled'
import { config, configEthereum } from '../../../connectors/config'
import { metaMask } from '../../../connectors/metamask'
import { useNotificationsContext } from '../../../context/Notificationscontext'
import { useDexToken } from '../../../hooks/useDexToken'
import { useModal } from '../../../hooks/useModal'
import { zerionService } from '../../../service/zerionService'
import { useDexStore } from '../../../store/useDexStore'
import { SWAP_CHAINS, useZerionStore } from '../../../store/useZerionStore'
import { formatTxData } from '../../../utils/formatTxData'
import { SwapButton } from './BuyBlockComponent'
import { ChainSelector } from './ChainSelector'
import { DexLoader } from './DexLoader'
import { DexTitleRow } from './DexTitle'
import { ErrorSection } from './ErrorSection'
import { InfoSection } from './InfoSection'
import { PaySection } from './PaySection'
import { ReceiveSection } from './ReceiveSection'
import { SelectCurrencyModal } from './SelectCurrencyModal'
import { SwitchBtn } from './SwitchBtn'

const Container = styled(Flex)`
    flex: 1;
    justify-content: center;
`

const Wrapper = styled.div`
    position: relative;
    padding: 0px 48px 0;
    margin-top: 84px;
    width: 100%;
    max-width: 736px;
    height: ${({ error }) => (error === 'true' ? '730px' : '578px')};
    background-image: ${(props) => `url(${props.imgUrl})`};
    backdrop-filter: blur(10px);

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const MobileWrapper = styled.div`
    display: none;
    position: relative;
    padding: 0px 24px 0;
    margin-top: 48px;
    width: 100%;
    max-width: 320px;
    height: ${({ error }) => (error === 'true' ? '640px' : '540px')};
    background-image: ${(props) => `url(${props.imgUrl})`};
    backdrop-filter: blur(10px);

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

export const DexForm = ({ isDataLoading }) => {
    const { account, provider, chainId } = useWeb3React()
    const {
        showErrorNotification,
        showPendingNotification,
        showSuccessNotification,
    } = useNotificationsContext()
    const { approveToken } = useDexToken()
    const { userAssets, tokens, swapChain, gasPrice } = useZerionStore()
    const [error] = useState(false)
    const {
        payCurrencyId,
        payAmount,
        receiveCurrencyId,
        setPayCurrencyId,
        setReceiveCurrencyId,
        switchCurrencies,
        setReceiveAmount,
        setPayAmount,
        setRefetchUserAssets,
    } = useDexStore()

    const { isOpen, closeModal, openModal } = useModal()
    const {
        isOpen: isReceiveModalOpen,
        closeModal: closeReceiveModal,
        openModal: openReceiveModal,
    } = useModal()

    const [isLoading, setIsloading] = useState(false)

    const isBnb = swapChain.chainId === SWAP_CHAINS.binance.chainId
    const isEthereum = swapChain.chainId === SWAP_CHAINS.ethereum.chainId

    const shouldSwitchNetwork =
        (isBnb && swapChain.chainId !== chainId) ||
        (isEthereum && swapChain.chainId !== chainId)

    const handleSwitchNetwork = useCallback(async () => {
        const isBnb = swapChain.chainId === SWAP_CHAINS.binance.chainId
        const isEthereum = swapChain.chainId === SWAP_CHAINS.ethereum.chainId

        if (isBnb && swapChain.chainId !== chainId) {
            await metaMask.activate(config).catch(() => {
                showErrorNotification('Wrong network')
            })
        } else if (isEthereum && swapChain.chainId !== chainId) {
            await metaMask.activate(configEthereum).catch(() => {
                showErrorNotification('Wrong network')
            })
        }
    }, [chainId, swapChain])

    useEffect(() => {
        if (chainId && shouldSwitchNetwork) {
            handleSwitchNetwork()
        }
    }, [swapChain, shouldSwitchNetwork])

    const onSwap = useCallback(async () => {
        await handleSwitchNetwork()

        const inputToken = userAssets.find(
            (token) => token.id === payCurrencyId,
        )

        const outputToken = tokens.find(
            (token) => token.id === receiveCurrencyId,
        )

        if (
            outputToken?.attributes?.symbol?.toLowerCase() ===
            inputToken?.attributes?.fungible_info?.symbol?.toLowerCase()
        ) {
            showErrorNotification('Select different tokens')
            return
        }

        if (!payAmount || !parseFloat(payAmount)) {
            showErrorNotification('Please enter amount')
            return
        }

        if (inputToken?.attributes?.quantity?.float < parseFloat(payAmount)) {
            showErrorNotification('Insufficient balance')
            return
        }

        showPendingNotification()
        setIsloading(true)

        const decimals = inputToken?.attributes?.quantity?.decimals
        const inputAmount = ethers.utils.parseUnits(payAmount, decimals)

        const params = {
            input_token: inputToken?.relationships?.fungible?.data.id,
            output_token: receiveCurrencyId,
            input_chain: swapChain.zerionId,
            input_amount: inputAmount.toString(),
            slippage: 1,
            from: account?.toLowerCase(),
            gas_price: gasPrice,
        }
        const data = await zerionService.getSwapData(params)
        const txData = formatTxData(data)
        const rawTx = txData.tx

        if (!rawTx) {
            try {
                const toAddress = txData?.info?.token_spender
                const inputTokenAddress = txData?.info?.input_token_address
                const inputAmount = txData?.info?.input_amount_estimation
                await approveToken(inputTokenAddress, inputAmount, toAddress)
            } catch (err) {
                showErrorNotification('Failed approve')
                setIsloading(false)
                return
            }

            const refetchedData = await zerionService.getSwapData(params)
            const tx = formatTxData(refetchedData).tx

            const value = tx.value
            tx.value = BigNumber.from(value)

            const createdTx = {
                to: tx.to,
                data: tx.data,
                value: tx.value,
            }

            try {
                const signer = provider.getSigner()
                const pendingTx = await signer.sendTransaction(createdTx)
                await pendingTx.wait()
                showSuccessNotification('Success')
                setReceiveAmount('')
                setPayAmount('')
                setIsloading(false)
                setRefetchUserAssets()
            } catch (err) {
                showErrorNotification('Failed transaction')
                setIsloading(false)
            }
        } else {
            const value = rawTx.value
            rawTx.value = BigNumber.from(value)

            const createdTx = {
                to: rawTx.to,
                data: rawTx.data,
                value: rawTx.value,
            }

            try {
                const signer = provider.getSigner()
                const pendingTx = await signer.sendTransaction(createdTx)
                await pendingTx.wait()
                showSuccessNotification('Success')
                setReceiveAmount('')
                setPayAmount('')
                setIsloading(false)
                setRefetchUserAssets()
            } catch (err) {
                showErrorNotification('Failed transaction')
                setIsloading(false)
            }
        }
    }, [
        account,
        payCurrencyId,
        receiveCurrencyId,
        tokens,
        payAmount,
        provider,
        swapChain,
        gasPrice,
        handleSwitchNetwork,
    ])

    const getTxData = useCallback(
        debounce(
            async (amount) => {
                if (!parseFloat(amount)) {
                    setReceiveAmount('')
                    return
                }
                if (
                    !account ||
                    !payCurrencyId ||
                    !receiveCurrencyId ||
                    payCurrencyId === receiveCurrencyId
                ) {
                    return
                }

                const inputToken = userAssets.find(
                    (token) => token.id === payCurrencyId,
                )

                if (!inputToken) {
                    return
                }

                setIsloading(true)

                const decimals = inputToken?.attributes?.quantity?.decimals
                const inputAmount = ethers.utils.parseUnits(amount, decimals)

                const params = {
                    input_token: inputToken?.relationships?.fungible?.data?.id,
                    output_token: receiveCurrencyId,
                    input_chain: swapChain.zerionId,
                    input_amount: inputAmount.toString(),
                    slippage: 1,
                    from: account?.toLowerCase(),
                    gas_price: gasPrice,
                }
                const data = await zerionService.getSwapData(params)
                if (data) {
                    const txData = formatTxData(data)
                    if (txData) {
                        const outputAmount = txData?.info?.output_amount
                        setReceiveAmount(outputAmount)
                    }
                }
                setIsloading(false)
            },
            500,
            { trailing: true },
        ),
        [account, payCurrencyId, receiveCurrencyId, swapChain, gasPrice],
    )

    const inputCurrency = useMemo(() => {
        return userAssets.find((token) => token.id === payCurrencyId)
    }, [payCurrencyId, userAssets])

    const buttonDisabled =
        !payAmount || !parseFloat(payAmount) || isLoading || !inputCurrency

    useEffect(() => {
        getTxData(payAmount)
    }, [payAmount, account, payCurrencyId, receiveCurrencyId])

    if (isDataLoading) {
        return (
            <Container>
                <Wrapper imgUrl={error ? DexErrorBg : DexBg}>
                    <DexLoader />
                </Wrapper>

                <MobileWrapper imgUrl={error ? DexErrorMobileBg : DexMobileBg}>
                    <DexLoader />
                </MobileWrapper>
            </Container>
        )
    }

    return (
        <Container>
            <Wrapper error={String(error)} imgUrl={error ? DexErrorBg : DexBg}>
                <ChainSelector />
                <DexTitleRow />
                <PaySection
                    openModal={openModal}
                    tokensList={userAssets}
                    oppositeTokensList={tokens}
                />
                <SwitchBtn onClick={switchCurrencies} />
                <ReceiveSection
                    openModal={openReceiveModal}
                    tokensList={tokens}
                    oppositeTokensList={userAssets}
                />
                <InfoSection />
                {error && <ErrorSection />}
                <SwapButton
                    disabled={buttonDisabled}
                    onClick={shouldSwitchNetwork ? handleSwitchNetwork : onSwap}
                    isLoading={isLoading}
                />
                <SelectCurrencyModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    selectedId={payCurrencyId}
                    selectCurrency={setPayCurrencyId}
                    tokensList={userAssets}
                />
                <SelectCurrencyModal
                    isOpen={isReceiveModalOpen}
                    closeModal={closeReceiveModal}
                    selectedId={receiveCurrencyId}
                    selectCurrency={setReceiveCurrencyId}
                    isPay={true}
                    tokensList={tokens}
                />
            </Wrapper>

            <MobileWrapper
                error={String(error)}
                imgUrl={error ? DexErrorMobileBg : DexMobileBg}
            >
                <DexTitleRow />
                <PaySection openModal={openModal} tokensList={userAssets} />
                <SwitchBtn onClick={switchCurrencies} />
                <ReceiveSection
                    openModal={openReceiveModal}
                    tokensList={tokens}
                />
                <InfoSection />
                {error && <ErrorSection />}
                <SwapButton
                    disabled={buttonDisabled}
                    onClick={shouldSwitchNetwork ? handleSwitchNetwork : onSwap}
                    isLoading={isLoading}
                />
            </MobileWrapper>
        </Container>
    )
}
