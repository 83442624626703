import { useWallet } from '@solana/wallet-adapter-react'
import { useWeb3React } from '@web3-react/core'
import React, { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import HeaderBig from '../assets/blocks/header-big.svg'
import BurgerIcon from '../assets/mobile/burger.svg'
import MobileLogoIcon from '../assets/mobile/logo.svg'
import Logo from '../assets/svg/logo.svg'
import { NOTIFICATION_STATES } from '../constants/notifications'
import { ROUTES } from '../constants/routes'
import { useNotificationsContext } from '../context/Notificationscontext'
import { useModal } from '../hooks/useModal'
import { useConnectModalStore } from '../store/useConnectModalStore'
import { useErrorStore } from '../store/useErrorStore'
import {
    ErrorNotification,
    PendingNotification,
    SuccessNotification,
} from './Notifications'
import { ConnectWalletModal } from './Web3Status/ConnectWalletModal'
import { DropdownComponent } from './Web3Status/DropdownComponent'
import { Web3Status } from './Web3Status/Web3Status'
import { NetworkSwitch } from './Web3Status/networkSwitch'
import { Centered, Flex, RowBetween } from './styled'

const Wrapper = styled(RowBetween)`
    padding: 24px 50px 0 30px;
    z-index: 2;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const MobileWrapper = styled(RowBetween)`
    display: none;
    position: relative;
    margin-top: 8px;
    padding: 0 11px 0 4px;
    z-index: 2;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const LogoImg = styled.div`
    width: 80px;
    height: 80px;

    img {
        width: 100%;
        height: 100%;
    }
`

const MobileLogo = styled(LogoImg)`
    width: 50px;
    height: 50px;
`

const BurgerLogo = styled(LogoImg)`
    position: absolute;
    right: 0;
    top: 7px;
    width: 58px;
    height: 53px;
    cursor: pointer;
`

const Right = styled(Flex)`
    position: relative;
    width: 100%;
    height: 83px;
    max-width: ${({ iswide }) => (iswide === 'true' ? '918px' : '640px')};
`

const MobileRight = styled(Flex)`
    position: relative;
    width: 100%;
    height: 60px;
    max-width: ${({ iswide }) => (iswide === 'true' ? '918px' : '640px')};
`

const LinksContainer = styled(Centered)`
    padding-inline: 52px;
    width: 100%;
    max-width: 460px;
    position: relative;
    background-image: ${(props) => `url(${props.imgUrl})`};
    backdrop-filter: blur(6px);
    margin-right: -30px;
`

const MobileLinksContainer = styled(Centered)`
    padding-inline: 75px;
    width: 100%;
    max-width: 285px;
    position: relative;
    background-image: ${(props) => `url(${props.imgUrl})`};
    backdrop-filter: blur(6px);
    margin-right: -30px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 5px;
`
const LinkText = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.black};
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`

const Header = () => {
    const { account } = useWeb3React()
    const { publicKey } = useWallet()
    const location = useLocation()
    const {
        isVisible,
        isPendingVisible,
        notificationState,
        notificationText,
        successText,
    } = useNotificationsContext()

    const { error } = useErrorStore()
    const { isOpen, toggleModal } = useModal()
    const { isWalletModalOpen, toggleWalletModal, closeWalletModal } =
        useConnectModalStore()

    const handleClick = useCallback(() => {
        if (!account && !publicKey) {
            toggleWalletModal()
        } else {
            toggleModal()
        }
    }, [account, publicKey])

    const isDex = location.pathname === ROUTES.dex
    const showNetworks = location.pathname === ROUTES.allocations

    return (
        <>
            <Wrapper>
                <a href="/" style={{ marginTop: '-20px' }}>
                    <LogoImg>
                        <img src={Logo} alt="" />
                    </LogoImg>
                </a>
                <Right iswide={String(showNetworks)}>
                    {isVisible &&
                        notificationState === NOTIFICATION_STATES.error && (
                            <ErrorNotification text={notificationText} />
                        )}
                    {isPendingVisible &&
                        notificationState === NOTIFICATION_STATES.pending && (
                            <PendingNotification />
                        )}
                    {isVisible &&
                        notificationState === NOTIFICATION_STATES.success && (
                            <SuccessNotification text={successText} />
                        )}
                    <LinksContainer imgUrl={HeaderBig}>
                        <RowBetween style={{ marginTop: '-20px' }}>
                            <LinkText href="https://laika.org" target="_blank">
                                <span className="rubic">About</span>
                            </LinkText>
                            {isDex && (
                                <LinkText as={Link} to={ROUTES.dashboard}>
                                    <span className="rubic">Dashboard</span>
                                </LinkText>
                            )}
                            {!isDex && (
                                <LinkText as={Link} to={ROUTES.dex}>
                                    <span className="rubic">DEX</span>
                                </LinkText>
                            )}

                            <LinkText as={Link} to={ROUTES.tasks}>
                                <span className="rubic">Points</span>
                            </LinkText>
                            {/* <LinkText as={Link} to={ROUTES.dashboard}>*/}
                            {/*    <span className="rubic">Dashboard</span>*/}
                            {/* </LinkText>*/}
                            <LinkText
                                href="https://t.me/laika_org"
                                target="_blank"
                            >
                                <span className="rubic">Telegram</span>
                            </LinkText>
                            <LinkText
                                href="https://x.com/laika_org"
                                target="_blank"
                            >
                                <span className="rubic">Twitter</span>
                            </LinkText>
                        </RowBetween>
                    </LinksContainer>
                    {showNetworks && <NetworkSwitch />}
                    <Web3Status isDex={isDex} />
                </Right>
            </Wrapper>
            <>
                <MobileWrapper>
                    <a href="/">
                        <MobileLogo>
                            <img src={MobileLogoIcon} alt="" />
                        </MobileLogo>
                    </a>
                    <MobileRight iswide={String(showNetworks)}>
                        {isVisible &&
                            notificationState === NOTIFICATION_STATES.error && (
                                <ErrorNotification text={notificationText} />
                            )}
                        {isPendingVisible &&
                            notificationState ===
                                NOTIFICATION_STATES.pending && (
                                <PendingNotification />
                            )}
                        {isVisible &&
                            notificationState ===
                                NOTIFICATION_STATES.success && (
                                <SuccessNotification text={successText} />
                            )}
                        <MobileLinksContainer imgUrl={HeaderBig}>
                            <RowBetween style={{ marginTop: '-20px' }}>
                                <LinkText as={Link} to={ROUTES.dex}>
                                    <span className="rubic">DEX</span>
                                </LinkText>

                                <LinkText as={Link} to={ROUTES.tasks}>
                                    <span className="rubic">Points</span>
                                </LinkText>
                            </RowBetween>
                        </MobileLinksContainer>
                    </MobileRight>
                    <BurgerLogo onClick={handleClick}>
                        <img src={BurgerIcon} alt="" />
                    </BurgerLogo>
                    {isVisible &&
                        notificationState === NOTIFICATION_STATES.error && (
                            <ErrorNotification text={notificationText} />
                        )}
                    {isPendingVisible &&
                        notificationState === NOTIFICATION_STATES.pending && (
                            <PendingNotification />
                        )}
                    {isVisible &&
                        notificationState === NOTIFICATION_STATES.success && (
                            <SuccessNotification text={successText} />
                        )}
                    {error && <ErrorNotification text="Wrong network" />}
                    {isOpen && (account || publicKey) && (
                        <DropdownComponent mobile={true} />
                    )}
                </MobileWrapper>
                {isWalletModalOpen && (
                    <ConnectWalletModal
                        isOpen={isWalletModalOpen}
                        closeModal={closeWalletModal}
                        isDex={isDex}
                    />
                )}
            </>
        </>
    )
}

export default Header
