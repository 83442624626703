import React from 'react'
import styled from 'styled-components'
import { AutoColumn, Flex, RowBetween } from '../../../components/styled'
import CurrencyRowBg from '../../../assets/dex/dex-blocks/dropdown-row-bg.svg'
import CurrencyRowMobileBg from '../../../assets/mobile/dex/dex-currencies-active-bg.svg'
import SuccessIcon from '../../../assets/svg/success.svg'
import QuestionMark from '../../../assets/svg/question-mark.svg'

const Wrapper = styled(Flex)`
    gap: 8px;
    width: 100%;
    max-width: 508px;
    height: fit-content;
    max-height: 475px;
    margin-top: 45px;
    flex-direction: column;
    overflow: auto;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled(Flex)`
    display: none;
    gap: 2px;
    width: 100%;
    max-width: 288px;
    height: fit-content;
    max-height: 328px;
    margin-top: 15px;
    flex-direction: column;
    overflow: auto;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: flex;
  `};
`

const CurrencyRow = styled(RowBetween)`
    width: 95%;
    max-width: 95%;
    height: 90px;
    min-height: 90px;
    padding-inline: 24px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
`

const CurrencyRowMobile = styled(RowBetween)`
    width: 270px;
    max-width: 270px;
    height: 55px;
    min-height: 55px;
    padding-inline: 6px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
    backdrop-filter: blur(24px);

    &:hover {
        opacity: 0.8;
    }
`

const CurrencyInfo = styled(Flex)`
    gap: 12px;
    height: 48px;
`

const CurrencyInfoMobile = styled(CurrencyInfo)`
    height: 36px;
`

const CurrencySymbol = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
`

const CurrencySymbolMobile = styled(CurrencySymbol)`
    font-size: 14px;
    line-height: 14px;
`

const CurrencyName = styled.p`
    color: ${({ theme }) => theme.textGray};
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
`

const CurrencyNameMobile = styled(CurrencyName)`
    font-size: 14px;
    line-height: 18.2px;
`

const CurrencyIcon = styled.img`
    width: 48px;
    height: 48px;
`

const CurrencyIconMobile = styled.img`
    width: 36px;
    height: 36px;
`

const SuccessImg = styled.img`
    width: 26px;
    height: 26px;
`

const SuccessImgMobile = styled.img`
    width: 22px;
    height: 22px;
`

export const CurrenciesList = ({ selectedId, onSelect, tokensList, isPay }) => {
    return (
        <>
            <Wrapper>
                {tokensList?.map((token) => (
                    <CurrencyRow
                        imgUrl={CurrencyRowBg}
                        key={token.id}
                        onClick={() => onSelect(token.id)}
                    >
                        <CurrencyInfo>
                            <CurrencyIcon
                                src={
                                    isPay
                                        ? token?.attributes?.icon?.url
                                        : token?.attributes?.fungible_info?.icon
                                                ?.url
                                          ? token?.attributes?.fungible_info
                                                ?.icon?.url
                                          : QuestionMark
                                }
                            />
                            <AutoColumn gap="8px">
                                <CurrencySymbol>
                                    <span className="rubic">
                                        {isPay
                                            ? token?.attributes?.symbol
                                            : token?.attributes?.fungible_info
                                                  ?.symbol}
                                    </span>
                                </CurrencySymbol>
                                <CurrencyName>
                                    <span className="mono">
                                        {isPay
                                            ? token?.attributes?.name
                                            : token.attributes?.fungible_info
                                                  ?.name}
                                    </span>
                                </CurrencyName>
                            </AutoColumn>
                        </CurrencyInfo>
                        {selectedId === token.id ? (
                            <SuccessImg src={SuccessIcon} />
                        ) : (
                            <div />
                        )}
                    </CurrencyRow>
                ))}
            </Wrapper>

            <WrapperMobile>
                {tokensList?.map((token) => (
                    <CurrencyRowMobile
                        imgUrl={
                            selectedId === token.id ? CurrencyRowMobileBg : ''
                        }
                        key={token.id}
                        onClick={() => onSelect(token.id)}
                    >
                        <CurrencyInfoMobile>
                            <CurrencyIconMobile
                                src={
                                    isPay
                                        ? token?.attributes?.icon?.url
                                        : token?.attributes?.fungible_info?.icon
                                                ?.url
                                          ? token?.attributes?.fungible_info
                                                ?.icon?.url
                                          : QuestionMark
                                }
                                alt="tokenLogo"
                            />
                            <AutoColumn gap="3px">
                                <CurrencySymbolMobile>
                                    <span className="rubic">
                                        {isPay
                                            ? token?.attributes?.symbol
                                            : token?.attributes?.fungible_info
                                                  ?.symbol}
                                    </span>
                                </CurrencySymbolMobile>
                                <CurrencyNameMobile>
                                    <span className="mono">
                                        {isPay
                                            ? token?.attributes?.name
                                            : token.attributes?.fungible_info
                                                  ?.name}
                                    </span>
                                </CurrencyNameMobile>
                            </AutoColumn>
                        </CurrencyInfoMobile>
                        {selectedId === token.id ? (
                            <SuccessImgMobile src={SuccessIcon} />
                        ) : (
                            <div />
                        )}
                    </CurrencyRowMobile>
                ))}
            </WrapperMobile>
        </>
    )
}
