import {
    CHAIN_ID,
    CHAIN_NAME,
    ETHEREUM_CHAIN_ID,
    ETHEREUM_CHAIN_NAME,
    ETHEREUM_EXPLORER,
    ETHEREUM_NATIVE_CURRENCY,
    ETHEREUM_RPC,
    EXPLORER_URL,
    NATIVE_CURRENCY,
    NETWORK_RPC,
} from '../constants'

export const config = {
    chainId: CHAIN_ID,
    chainName: CHAIN_NAME,
    nativeCurrency: NATIVE_CURRENCY,
    rpcUrls: [NETWORK_RPC],
    blockExplorerUrls: [EXPLORER_URL],
}

export const configEthereum = {
    chainId: ETHEREUM_CHAIN_ID,
    chainName: ETHEREUM_CHAIN_NAME,
    nativeCurrency: ETHEREUM_NATIVE_CURRENCY,
    rpcUrls: [ETHEREUM_RPC],
    blockExplorerUrls: [ETHEREUM_EXPLORER],
}
