import React from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../../../components/styled'

const TitleContainer = styled(AutoColumn)`
    margin-top: 44px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const TitleContainerMobile = styled(AutoColumn)`
    display: none;
    margin-top: 44px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: grid;
  `};
`

const DexTitle = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
`

const DexTitleMobile = styled(DexTitle)`
    font-size: 16px;
    line-height: 16px;
`

const DexTextSmall = styled.p`
    color: ${({ theme }) => theme.primary};
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
`

const DexTextSmallMobile = styled(DexTextSmall)`
    font-size: 13px;
    line-height: 16.9px;
`

export const DexTitleRow = () => {
    return (
        <>
            <TitleContainer gap="16px">
                <DexTitle>
                    <span className="astro">DEX</span>
                </DexTitle>
                <DexTextSmall>
                    <span className="mono">
                        Select the desired token and swap it.
                    </span>
                </DexTextSmall>
            </TitleContainer>

            <TitleContainerMobile gap="8px">
                <DexTitleMobile>
                    <span className="astro">DEX</span>
                </DexTitleMobile>
                <DexTextSmallMobile>
                    <span className="mono">
                        Select the desired token and swap it.
                    </span>
                </DexTextSmallMobile>
            </TitleContainerMobile>
        </>
    )
}
