import { useWeb3React } from '@web3-react/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import LeftBg from '../../../assets/tasks/left_bg.png'
// import RightBg from '../../../assets/tasks/right_bg.png'

import { tasksService } from '../../../api/tasksService'
import { useTasksStore } from '../tasksStore'
import { ClaimButton } from './ClaimButton'
import { TaskTimer } from './Timer'

const RightWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 745px;
    height: 456px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    background-size: cover;
    padding-top: 35px;

    @media (max-width: 796px) {
        background-size: contain;
        background-repeat: no-repeat;
        max-width: 470px;
        width: 100%;
    }
`
const Title = styled.h1`
    margin-left: 115px;
    font-size: 16px;
    ${({ theme }) => theme.mediaWidth.upToTablet`
    font-size: 16px;
    line-height: 16px;
    margin-left: 12%;
    margin-top: -5px;
  `}
`
const Subtitle = styled.span`
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 16px;
    @media (max-width: 796px) {
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        margin-bottom: 0;
    }
`
const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    padding: 0 50px;
    gap: 30px;
    @media (max-width: 796px) {
        gap: 10px;
        padding: 0 20px;
        margin-top: 10px;
    }
`

export const TasksRightInfo = () => {
    const { account } = useWeb3React()
    const { address } = useAccount()
    const { timeToNextReward, claimedToday, streak } = useTasksStore()
    const [loading, setLoading] = useState(false)

    const handleClaim = () => {
        setLoading(true)
        tasksService
            .claimReward(account || address)
            .catch(console.warn)
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <RightWrapper imgUrl={LeftBg}>
            <Title className="astro">Time</Title>
            <InfoContainer>
                <Subtitle className="astro">Until next daily reward</Subtitle>
                <TaskTimer milliseconds={timeToNextReward} />
                <ClaimButton
                    text={
                        claimedToday && streak && (account || address)
                            ? `STREAK: ${streak} DAYS`
                            : 'Claim Daily Reward'
                    }
                    onClick={handleClaim}
                    disabled={claimedToday || loading}
                    loading={loading}
                />
            </InfoContainer>
        </RightWrapper>
    )
}
