import React, { createContext, useCallback, useContext, useState } from 'react'
import { NOTIFICATION_STATES } from '../constants/notifications'

const InitialContextValues = {
    isVisible: false,
    isPendingVisible: false,
    notificationState: '',
    notificationText: '',
    successText: '',
    showNotification: () => {},
    showPendingNotification: () => {},
    hideNotification: () => {},
    setNotification: () => {},
    setNotificationText: () => {},
    showErrorNotification: () => {},
    showSuccessNotification: () => {},
}

export const NotificationsContext = createContext(InitialContextValues)

export const useNotificationsContext = () => useContext(NotificationsContext)

export const NotificationsContextProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [isPendingVisible, setIsPendingVisible] = useState(false)
    const [notificationText, setText] = useState('')
    const [successText, setSuccessText] = useState('')
    const [notificationState, setNotificationState] = useState(
        NOTIFICATION_STATES.empty,
    )

    const showNotification = useCallback(() => {
        setIsVisible(true)
        setIsPendingVisible(false)

        setTimeout(() => {
            setIsVisible(false)
            setNotificationText('')
            setSuccessText('')
        }, 4000)
    }, [])

    const showPendingNotification = useCallback(() => {
        setNotificationState(NOTIFICATION_STATES.pending)
        setIsPendingVisible(true)
    }, [])

    const hideNotification = useCallback(() => {
        setIsVisible(false)
    }, [])

    const setNotification = useCallback((state) => {
        setNotificationState(state)
    }, [])

    const setNotificationText = useCallback((message) => {
        setText(message)
    }, [])

    const showErrorNotification = useCallback((message) => {
        setNotificationText(message)
        setNotification(NOTIFICATION_STATES.error)
        showNotification()
    }, [])

    const showSuccessNotification = useCallback((text) => {
        setNotification(NOTIFICATION_STATES.success)
        setSuccessText(text)
        showNotification()
    }, [])

    const value = {
        isVisible,
        isPendingVisible,
        notificationState,
        showNotification,
        hideNotification,
        setNotification,
        showPendingNotification,
        setNotificationText,
        notificationText,
        successText,
        showErrorNotification,
        showSuccessNotification,
    }

    return (
        <NotificationsContext.Provider value={value}>
            {children}
        </NotificationsContext.Provider>
    )
}
