import { useWeb3React } from '@web3-react/core'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import BackgroundImg from '../../assets/img/background.svg'
import BottomIcon from '../../assets/img/bottom-right.png'
import { PageWrapper } from '../../components/PageWrapper'
import { AppWrapper, Background } from '../../components/common'
import { zerionService } from '../../service/zerionService'
import { useDexStore } from '../../store/useDexStore'
import { useZerionStore } from '../../store/useZerionStore'
import { validateTokensList } from '../../utils/validateTokensList'
import { DexForm } from './components/DexForm'

const BottomImg = styled.div`
    width: 523px;
    height: 666px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
    }
`

export function Dex() {
    const { account } = useWeb3React()
    const { setUserAssets, setTokens, setGasPrice, swapChain } =
        useZerionStore()
    const { setPayCurrencyId, setReceiveCurrencyId, refetchUserAssets } =
        useDexStore()
    const [isLoading, setIsLoading] = useState(false)

    const fetchUserAssets = useCallback(async () => {
        if (!account) {
            return
        }
        setIsLoading(true)

        const data = await zerionService.getWalletAssets(
            account,
            swapChain.zerionId,
        )

        if (data) {
            setUserAssets(data)
            const native = data.find((el) => !el.id.startsWith('0x'))
            setPayCurrencyId(native?.id || data[0]?.id)
        }
        setIsLoading(false)
    }, [account, refetchUserAssets, swapChain])

    const fetchTokens = useCallback(async () => {
        setIsLoading(true)
        const data = await zerionService.getTokens(swapChain.zerionId)
        if (data) {
            const validTokens = validateTokensList(data)
            const notNative = data.find((el) => el.id.startsWith('0x'))
            setTokens(validTokens)
            setReceiveCurrencyId(notNative?.id || validTokens[1].id)
        }
        setIsLoading(false)
    }, [swapChain])

    const fetchGasPrice = useCallback(async () => {
        setIsLoading(true)
        const data = await zerionService.getGasPrice(swapChain.zerionId)
        if (data) {
            const price = data[0]?.attributes?.info?.standard
            if (price) {
                setGasPrice(price)
            }
        }
        setIsLoading(false)
    }, [swapChain])

    useEffect(() => {
        fetchTokens()
    }, [fetchTokens])

    useEffect(() => {
        fetchUserAssets()
    }, [fetchUserAssets])

    useEffect(() => {
        fetchGasPrice()
    }, [fetchTokens])

    return (
        <AppWrapper>
            <PageWrapper>
                <DexForm isDataLoading={isLoading} />
            </PageWrapper>
            <Background imgUrl={BackgroundImg}>
                <BottomImg>
                    <img src={BottomIcon} alt="" />
                </BottomImg>
            </Background>
        </AppWrapper>
    )
}
