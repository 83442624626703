import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import PayBg from '../../../assets/dex/dex-blocks/dex-you-pay-block.svg'
import PercentButtonBg from '../../../assets/dex/dex-blocks/dex-percent-button.svg'
import PayMobileBg from '../../../assets/mobile/dex/dex-pay-section-bg.svg'
import MaxButtonMobileBg from '../../../assets/mobile/dex/dex-max-button-bg.svg'
import { AutoColumn, Centered, RowBetween } from '../../../components/styled'
import { useDexStore } from '../../../store/useDexStore'
import { toFixedAmount } from '../../../utils/formatToFixes'
import { SelectCurrency } from './SelectCurrency'

const Wrapper = styled.div`
    position: relative;
    width: 650px;
    height: 166px;
    margin-top: 16px;
    margin-left: -8px;
    padding: 16px 30px;
    background-image: ${(props) => `url(${props.imgUrl})`};

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled.div`
    display: none;
    position: relative;
    width: 286px;
    height: 179px;
    margin-top: 16px;
    margin-left: -8px;
    padding: 16px 20px;
    background-image: ${(props) => `url(${props.imgUrl})`};

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const TextSize = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
`

const TextSizeMobile = styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 14.3px;
`

const DexAmountText = styled(TextSize)`
    color: ${({ theme }) => theme.primary};
`

const DexAmountTextMobile = styled(TextSizeMobile)`
    color: ${({ theme }) => theme.primary};
`

const DexBalanceText = styled(TextSize)`
    color: ${({ theme }) => theme.textGray};
    cursor: default;
`

const MobileBalanceBlock = styled(AutoColumn)`
    max-width: 110px;
`

const DexBalanceTextMobile = styled(TextSizeMobile)`
    color: ${({ theme }) => theme.textGray};
    cursor: default;
    text-align: right;
`

const PercentsSection = styled(RowBetween)`
    position: absolute;
    top: 96px;
    right: 20px;
    width: 290px;
    height: 60px;
`

const PercentButton = styled(Centered)`
    width: 86px;
    height: 60px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15.68px;
    color: ${({ theme }) => theme.primary};
    background-image: ${(props) => `url(${props.imgUrl})`};
    cursor: pointer;
`

const MaxButton = styled(PercentButton)`
    color: ${({ theme }) => theme.textGold};
`

const MaxButtonMobile = styled(Centered)`
    position: absolute;
    top: 107px;
    right: 8px;
    width: 103px;
    height: 70px;
    padding-bottom: 6px;
    color: ${({ theme }) => theme.textGold};
    background-image: ${(props) => `url(${props.imgUrl})`};
    font-weight: 400;
    font-size: 14px;
    line-height: 15.68px;
    cursor: pointer;
`

export const PaySection = ({ openModal, tokensList, oppositeTokensList }) => {
    const { payCurrencyId, payAmount, setPayAmount } = useDexStore()

    const selectedCurrency = useMemo(() => {
        return tokensList?.find((item) => item.id === payCurrencyId)
    }, [payCurrencyId])

    const switchedCurrency = useMemo(() => {
        return oppositeTokensList?.find((item) => item.id === payCurrencyId)
    }, [payCurrencyId])

    const onOpenModal = useCallback(() => {
        if (!tokensList.length) {
            return
        }
        openModal()
    }, [tokensList])

    const usdAmount = useMemo(() => {
        if (!payAmount) return 0
        if (!selectedCurrency?.attributes?.price) return 0
        return (
            selectedCurrency?.attributes?.price * parseFloat(payAmount)
        )?.toFixed(2)
    }, [payAmount, selectedCurrency])

    const handleMaxButton = useCallback(
        (percents) => {
            if (!selectedCurrency?.attributes?.quantity?.float) {
                return
            }
            const userBalance = selectedCurrency?.attributes?.quantity?.float
            const result = userBalance * percents
            setPayAmount(result.toString())
        },
        [selectedCurrency],
    )

    return (
        <>
            <Wrapper imgUrl={PayBg}>
                <RowBetween>
                    <DexAmountText>
                        <span className="astro">You pay</span>
                    </DexAmountText>
                    <DexBalanceText>
                        <span className="mono">
                            Balance:{' '}
                            {toFixedAmount(
                                selectedCurrency?.attributes?.quantity?.float,
                                4,
                            )}{' '}
                            {
                                selectedCurrency?.attributes?.fungible_info
                                    ?.symbol
                            }
                        </span>
                    </DexBalanceText>
                </RowBetween>
                <SelectCurrency
                    openModal={onOpenModal}
                    selectedId={payCurrencyId}
                    value={payAmount}
                    setValue={setPayAmount}
                    selectedCurrency={selectedCurrency}
                    usdAmount={usdAmount}
                    switchedCurrency={switchedCurrency}
                />
                <PercentsSection>
                    <PercentButton
                        imgUrl={PercentButtonBg}
                        onClick={() => handleMaxButton(0.25)}
                    >
                        <span className="mono">25%</span>
                    </PercentButton>
                    <PercentButton
                        imgUrl={PercentButtonBg}
                        onClick={() => handleMaxButton(0.5)}
                    >
                        <span className="mono">50%</span>
                    </PercentButton>
                    <PercentButton
                        imgUrl={PercentButtonBg}
                        onClick={() => handleMaxButton(0.75)}
                    >
                        <span className="mono">75%</span>
                    </PercentButton>
                    <MaxButton
                        imgUrl={PercentButtonBg}
                        onClick={() => handleMaxButton(1)}
                    >
                        <span className="mono">MAX</span>
                    </MaxButton>
                </PercentsSection>
            </Wrapper>

            <WrapperMobile imgUrl={PayMobileBg}>
                <RowBetween style={{ alignItems: 'flex-start' }}>
                    <DexAmountTextMobile>
                        <span className="astro">You pay</span>
                    </DexAmountTextMobile>
                    <MobileBalanceBlock>
                        <DexBalanceTextMobile>
                            <span className="mono">Balance:</span>
                        </DexBalanceTextMobile>
                        <DexBalanceTextMobile>
                            <span className="mono">
                                {toFixedAmount(
                                    selectedCurrency?.attributes?.quantity
                                        ?.float,
                                    4,
                                )}{' '}
                                {
                                    selectedCurrency?.attributes?.fungible_info
                                        ?.symbol
                                }
                            </span>
                        </DexBalanceTextMobile>
                    </MobileBalanceBlock>
                </RowBetween>
                <SelectCurrency
                    openModal={onOpenModal}
                    selectedId={payCurrencyId}
                    value={payAmount}
                    setValue={setPayAmount}
                    selectedCurrency={selectedCurrency}
                    usdAmount={usdAmount}
                    switchedCurrency={switchedCurrency}
                />
                <MaxButtonMobile imgUrl={MaxButtonMobileBg}>
                    <span className="mono">MAX</span>
                </MaxButtonMobile>
            </WrapperMobile>
        </>
    )
}
