import React from 'react'
import styled from 'styled-components'
import { Modal } from '../../../components/Modal'
import ModalBg from '../../../assets/dex/dex-blocks/dropdown-bg.svg'
import ModalMobileBg from '../../../assets/mobile/dex/dex-dropdown-bg.svg'
import ArrowLeftIcon from '../../../assets/svg/arrow-left.svg'
import { Icon, RowBetween } from '../../../components/styled'
import { stopPropagation } from '../../../utils/stopPropagation'
import { CurrenciesList } from './CurrenciesList'

const Wrapper = styled.div`
    width: 584px;
    height: 636px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    padding: 46px 0px 46px 46px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: none;
  `};
`

const WrapperMobile = styled.div`
    margin-top: -124px;
    display: none;
    width: 320px;
    height: 464px;
    background-image: ${(props) => `url(${props.imgUrl})`};
    padding: 36px 0px 41px 30px;

    ${({ theme }) => theme.mediaWidth.upToTablet`
    display: block;
  `};
`

const EmptyBox = styled.div`
    width: 29px;
    height: 29px;
`

const Title = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
`

const TitleMobile = styled(Title)`
    font-size: 16px;
    line-height: 16px;
`

export const SelectCurrencyModal = ({
    isOpen,
    closeModal,
    selectedId,
    selectCurrency,
    tokensList,
    isPay = false,
}) => {
    const onSelect = (id) => {
        selectCurrency(id)
        closeModal()
    }

    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <>
                <Wrapper imgUrl={ModalBg} onClick={stopPropagation}>
                    <RowBetween>
                        <Icon width="29px" height="29px" onClick={closeModal}>
                            <img src={ArrowLeftIcon} alt="" />
                        </Icon>
                        <Title>
                            <span className="astro">SELECT A TOKEN</span>
                        </Title>
                        <EmptyBox />
                    </RowBetween>
                    <CurrenciesList
                        tokensList={tokensList}
                        onSelect={onSelect}
                        selectedId={selectedId}
                        isPay={isPay}
                    />
                </Wrapper>

                <WrapperMobile imgUrl={ModalMobileBg} onClick={stopPropagation}>
                    <RowBetween>
                        <Icon width="17px" height="17px" onClick={closeModal}>
                            <img src={ArrowLeftIcon} alt="" />
                        </Icon>
                        <TitleMobile>
                            <span className="astro">SELECT A TOKEN</span>
                        </TitleMobile>
                        <EmptyBox />
                    </RowBetween>
                    <CurrenciesList
                        tokensList={tokensList}
                        onSelect={onSelect}
                        selectedId={selectedId}
                        isPay={isPay}
                    />
                </WrapperMobile>
            </>
        </Modal>
    )
}
