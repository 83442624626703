import React, { createContext, useContext, useEffect, useState } from 'react'

const InitialContextValues = {
    isMobile: false,
    isTablet: false,
}

export const ScreenContext = createContext(InitialContextValues)

export const useScreenContext = () => useContext(ScreenContext)

const maxAllowedWidthForDesktopUI = 796
const mobileWidth = 480
export const ScreenContextProvider = ({ children }) => {
    const [isTablet, setIsTablet] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    function setDeviseState() {
        const screeWidth = window.innerWidth
        if (
            screeWidth < maxAllowedWidthForDesktopUI &&
            screeWidth > mobileWidth
        ) {
            setIsTablet(true)
            return
        }
        if (screeWidth < mobileWidth) {
            setIsMobile(true)
            return
        }
        setIsMobile(false)
        setIsTablet(false)
    }

    useEffect(() => {
        window.addEventListener('resize', setDeviseState)
        setDeviseState()
        return () => window.removeEventListener('resize', setDeviseState)
    }, [])

    const value = {
        isMobile,
        isTablet,
    }

    return (
        <ScreenContext.Provider value={value}>
            {children}
        </ScreenContext.Provider>
    )
}
